import React, {useState, useEffect} from 'react';
import './style.scss';
import {colors, images} from '../../../../common';
import {allCommonText} from '../../../../common/constants';
import {planCategory} from '../../policy-and-claims';
import {getInvoiceById} from '../../../../utils/rest-services';
import {path} from '../../../../common/routesNames';
import {useNavigate} from 'react-router';
import {Loader} from '../../../../components';
import {getInvoiceStatus} from '..';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import {PiInfo} from 'react-icons/pi';

function InvoicePaymentDetail() {
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [invoiceItemList, setInvoiceItemList] = useState([]);
  const [loader, setLoader] = useState(false);
  // const MONTHLY_INVOICE = 'monthly';

  useEffect(() => {
    fetchInvoiceDetails(invoiceId);
  }, []);

  const navigate = useNavigate();
  const getParamsFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  let query = getParamsFromUrl();
  const invoiceId = query.get('id');

  const fetchInvoiceDetails = async () => {
    setLoader(true);
    let response = await getInvoiceById(invoiceId);

    if (response && response?.data?.success) {
      setLoader(false);
      setInvoiceDetails(response.data.content?.invoice);
      setInvoiceItemList(response.data.content?.invoiceItems);
    }
  };

  const planSelectorIcon = id => {
    switch (id) {
      case allCommonText.serviceTypeEnums.vehicle:
        return images.vehicleV;
      case allCommonText.serviceTypeEnums.home:
        return images.homeV;
      case allCommonText.serviceTypeEnums.device:
        return images.devicesV;
      case allCommonText.serviceTypeEnums.commercial:
        return images.commercialV;
      case allCommonText.serviceTypeEnums.disaster:
        return images.disasterIcon;
    }
  };

  const getDiscountValue = () => {
    if (invoiceDetails?.discountAmount) {
      return `$${parseFloat(invoiceDetails?.discountAmount).toFixed(2)}`;
    } else if (invoiceDetails.discountPercent) {
      return `${invoiceDetails.discountPercent}%`;
    } else {
      return 'No Discount';
    }
  };

  const amountData = [
    {
      title: (
        <span>
          <Tooltip id={`Subtotal`} />
          {allCommonText.labels.subTotal}
          <PiInfo
            data-tooltip-id={`Subtotal`}
            data-tooltip-content={`Including $1 processing fee`}
          />
        </span>
      ),

      value: `$${parseFloat(invoiceDetails?.subTotal).toFixed(2)}`,
    },
    {
      title: allCommonText.labels.discount,
      value: getDiscountValue(),
    },

    {
      title: allCommonText.labels.subTotalAfterDiscount,
      value: `$${parseFloat(invoiceDetails?.subtotalAfterDiscount).toFixed(2)}`,
    },
    {
      title: (
        <span>
          <Tooltip id={`taxes-and-otherFee`} />
          {allCommonText.labels.taxesAndOtherFee}
          <PiInfo
            data-tooltip-id={`taxes-and-otherFee`}
            data-tooltip-content={`${
              invoiceDetails.taxPercent || 2.9
            }% Sales Tax`}
          />
        </span>
      ),

      value: `$${invoiceDetails.taxAmount}`,
    },
    {
      title: allCommonText.labels.totalAmount,
      value: `$${invoiceDetails?.totalAmount}`,
    },
  ];
  const InvoiceItem = ({
    name,
    deductible,
    cost,
    serviceTypeId,
    make,
    model,
    brand,
    address,
  }) => {
    return (
      <div className="invoice-item-view">
        <div className="plan-icon">
          <img src={planSelectorIcon(serviceTypeId)} alt="" />
        </div>
        <div className="invoice-detail-card">
          <div className="primary-card-container-invoice ">
            <span>{name}</span>
            {make && <span>{`Make: ${make}`} </span>}
            {brand && <span>{`Brand: ${brand}`} </span>}
            {model && <span>{`Model: ${model}`} </span>}
            {address && <span>{`Address: ${address}`} </span>}
          </div>
          <div className="secondary-card-container-invoice">
            <span>
              {`${allCommonText.popupEnums.subscriptionPlanHeadings.deductibles}: ${deductible}`}
            </span>
            <div>
              <span> {`${allCommonText.labels.cost} ${cost}`} </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const InvoicePaymentDetailsContent = ({title, Value, valueAmount}) => {
    return (
      <div className="inv-detail-content">
        <span className="inv-detail-content-title">{title}</span>
        <span className={valueAmount ? 'boldColor' : ''}>{Value}</span>
      </div>
    );
  };
  return loader ? (
    <div className="loader-container-invoice w-100">
      <Loader />
    </div>
  ) : (
    <div className="main-subscription-container inv-detail-pad">
      <div className="inv-detail-header">
        <div className="inv-detail-title">
          <img
            src={images.redirectIcon}
            alt=""
            onClick={() => navigate({pathname: path.invoicesAndPayments})}
          />
          <h3>{allCommonText.profile.invoicesPaymentsDetail}</h3>
        </div>
        <div className="inv-detail-badges">
          {getInvoiceStatus(invoiceDetails?.status)}
          <span
            className={`${planCategory(
              invoiceDetails?.subscriptionType,
            )} ms-2 badge-text-inv`}>
            {invoiceDetails?.subscriptionType}
          </span>
        </div>
      </div>
      <div className="inv-detail-content-box">
        <InvoicePaymentDetailsContent
          title={allCommonText.labels.invoiceId}
          Value={invoiceDetails?.viewInvoiceId}
        />
        <InvoicePaymentDetailsContent
          title={allCommonText.labels.subscriptionId}
          Value={invoiceDetails?.viewSubId}
        />
      </div>
      <div className="inv-detail-content-box">
        <InvoicePaymentDetailsContent
          title={allCommonText.labels.dueDate}
          Value={invoiceDetails.dueOn?.replace(/\//g, '-').split(' ')[0]}
        />
        <InvoicePaymentDetailsContent
          title={allCommonText.labels.paidDate}
          Value={invoiceDetails.datePaid?.replace(/\//g, '-').split(' ')[0]}
        />
      </div>
      <div className="inv-detail-content-box">
        <InvoicePaymentDetailsContent
          title={allCommonText.labels.billingCard}
          Value={
            <>
              <i
                className={`subscription-card-type subscription-item-card fa-brands fa-cc-${invoiceDetails?.cardType}`}
                style={{color: colors.primary}}></i>
              <span>{`**** ***** ***** ${invoiceDetails?.cardNumber}`}</span>
            </>
          }
        />
      </div>

      <div className="invoice-container-v2">
        <span className="invoice-title">
          {allCommonText.heading.paymentAndPaymentItems}
        </span>

        {invoiceItemList.length < 0 ? (
          <span className="not-availabale">
            {' '}
            {allCommonText.noInvoiceItems}
          </span>
        ) : (
          <div className="invoice-item-details">
            {invoiceItemList?.map(i => (
              <InvoiceItem
                key={i}
                make={i?.make}
                model={i?.model}
                serviceTypeId={i.serviceTypeId}
                address={i?.address}
                cost={`$${parseFloat(i.cost).toFixed(2)}`}
                deductible={`$${parseFloat(i.deductibleAmount).toFixed(2)}`}
                name={i.providerName}
              />
            ))}
          </div>
        )}
      </div>
      <div className="amount-section">
        {amountData.map((item, index) => {
          const {title, value} = item;
          return (
            <div className="inv-detail-content-box" key={index}>
              <InvoicePaymentDetailsContent
                title={title}
                valueAmount
                Value={`${value}`}
                // Value={
                //   invoiceDetails?.subscriptionType?.toLowerCase() ===
                //     MONTHLY_INVOICE && title === allCommonText.discount
                //     ? allCommonText.noDiscount
                //     : `${value}`
                // }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default InvoicePaymentDetail;
// invoiceDetails?.subscriptionType === 'Monthly'
// ? 'No Discount'
