import React, {useState, useEffect} from 'react';
import {planCategory, serviceTypeIcon} from '..';
import {allCommonText} from '../../../../common/constants';
import {path} from '../../../../common/routesNames';
import {useNavigate} from 'react-router-dom';
import {
  ActionDropDownPopUp,
  CustomizedSearch,
  GenericModal,
  ConfirmCancellationPopup,
  PolicyCard,
  Loader,
} from '../../../../components';
import {cancelPolicyByCartItem} from '../../../../utils/rest-services';
import {getPlansDetails} from './policy-sequence';
export function Policies({
  policiesList,
  policySearchHandler,
  pageLoader,
  btnLoader,
  fetchPolicies,
  selectedPlan,
}) {
  const [btnLoaderReasonPopup, setBtnLoaderReasonPopup] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [selectedId, setSelectedId] = useState(0);
  const [isPopupResponse, setIsPopupResponse] = useState(false);
  const [confirmCancellationPopUp, setConfirmCancellationPopUp] =
    useState(false);
  const [PopupContent, setPopupContent] = useState({
    type: allCommonText.popupEnums.error,
    body: allCommonText.policyClaims.cancelPolicyPopup,
    title: '',
  });

  useEffect(() => {
    if (confirmCancellationPopUp) {
      setCancelReason('');
    }
  }, [confirmCancellationPopUp]);

  const navigate = useNavigate();

  const cancelPolicy = async () => {
    setBtnLoaderReasonPopup(true);
    let payLoad = {
      cancelReason: cancelReason,
      cartItemId: selectedId,
    };
    let response = await cancelPolicyByCartItem(payLoad);
    const {
      data: {description},
    } = response;
    if (response && response.data.success) {
      setPopupContent({
        type: allCommonText.popupEnums.alert,
        body: description,
      });
      fetchPolicies();
    } else {
      setPopupContent({
        type: allCommonText.popupEnums.error,
        body: description,
      });
    }
    setIsPopupResponse(true);
    setConfirmCancellationPopUp(false);
    setBtnLoaderReasonPopup(false);
  };

  return (
    <>
      <div className="main-policy-container">
        <CustomizedSearch
          firstLabel={allCommonText.labels.policyID}
          firstPlaceholder={allCommonText.placeholders.policyIDNumber}
          firstPrefixValue={'pln_'}
          thirdLabel={allCommonText.labels.policyType}
          secondDropdownValue={selectedPlan}
          status={[
            allCommonText.policyClaims.all,
            allCommonText.policyClaims.auto,
            allCommonText.policyClaims.home,
            allCommonText.policyClaims.mobile,
            allCommonText.policyClaims.commercial,
            // allCommonText.policyClaims.disaster,
          ]}
          btnLoader={btnLoader}
          title={allCommonText.heading.searchPolicy}
          onClearFilterHandler={() => {
            policySearchHandler('', '', true);
          }}
          onSearchBtnPress={(primary, secondary, status) => {
            policySearchHandler(primary, status);
          }}
        />
        {pageLoader ? (
          <div className="no-policy-claim-container">
            <Loader size={40} />
          </div>
        ) : policiesList?.length > 0 ? (
          policiesList?.map((item, index) => {
            const {
              serviceProviderType,
              subscriptionType,
              serviceProvider,
              cost,

              deductibleAmount,
              serviceTypeId,
              isCancelable,
              isClaimable,
              claimDescription,
              cartItemId,
              serviceProviderId,
            } = item || {};
            return (
              <div key={index}>
                <PolicyCard
                  cardType={allCommonText.viewCardType.policyAndClaim}
                  planIconTitle={
                    <div className="plan-name">
                      {serviceTypeIcon(serviceTypeId)}
                      <span className="description-title">
                        {serviceProviderType}
                      </span>
                    </div>
                  }
                  badge={
                    <span class={planCategory(subscriptionType)}>
                      {subscriptionType}
                    </span>
                  }
                  cost={
                    <>
                      <span className="fw-bold">
                        {allCommonText.policyClaims.monthlyCost}
                        <span>{`$${parseFloat(cost).toFixed(2)}`}</span>
                        <span className="d-inline-block">
                          <ActionDropDownPopUp
                            cancelPolicypopup={() => {
                              if (isCancelable) {
                                setSelectedId(cartItemId);
                                setConfirmCancellationPopUp(true);
                              } else {
                                setPopupContent({
                                  type: allCommonText.popupEnums.alert,
                                  body: allCommonText.policyClaims
                                    .cancelPolicyPopup,
                                  title:
                                    allCommonText.policyClaims
                                      .cannotCancelPolicy,
                                });
                                setIsPopupResponse(true);
                              }
                            }}
                            addClaim={() => {
                              isClaimable
                                ? navigate({
                                    pathname: path.addNewPolicyScreen,
                                    search: `?serviceprovider=${serviceProvider}&serviceType=${serviceProviderType}&amount=${parseFloat(
                                      deductibleAmount,
                                    ).toFixed(
                                      2,
                                    )}&cartItemId=${cartItemId}&serviceProviderId=${serviceProviderId}`,
                                  })
                                : setPopupContent({
                                    type: allCommonText.popupEnums.alert,
                                    body: claimDescription,
                                    title:
                                      allCommonText.policyClaims.claimCannotAdd,
                                  });
                              setIsPopupResponse(true);
                            }}
                          />
                        </span>
                      </span>
                    </>
                  }
                  LeftTopDetails={getPlansDetails(item).first}
                  LeftBottomDetails={getPlansDetails(item).second}
                  rightTopDetails={getPlansDetails(item).third}
                  rigthBottomDetails={getPlansDetails(item).fourth}
                  lastLeftBottomDetails={getPlansDetails(item).fifth}
                  lastBottomDetails={getPlansDetails(item).sixth}
                />
              </div>
            );
          })
        ) : (
          !pageLoader && (
            <div className="no-subscription-container">
              <span>{allCommonText.labels.noPolicy}</span>
            </div>
          )
        )}
        <GenericModal
          show={isPopupResponse}
          btnText={allCommonText.ok}
          title={PopupContent.title}
          type={PopupContent.type}
          body={PopupContent.body}
          handleClose={() => {
            setIsPopupResponse(false);
          }}
        />
        {confirmCancellationPopUp && (
          <ConfirmCancellationPopup
            loader={btnLoaderReasonPopup}
            show={confirmCancellationPopUp}
            reason={cancelReason}
            cancleTitle={allCommonText.policyClaims.cancelPolicy}
            cancelMessage={allCommonText.policyClaims.cancelPolicyMessage}
            noteMessage={allCommonText.policyClaims.noteCancelPolicy}
            setReason={setCancelReason}
            handleClose={() => {
              setConfirmCancellationPopUp(false);
            }}
            handelSubmit={() => {
              cancelPolicy();
            }}
          />
        )}
      </div>
    </>
  );
}
