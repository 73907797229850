import React, {useState, useEffect} from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import {allCommonText, footer} from '../../../common/constants';
import {Grid} from '@mui/material';
import './styles.scss';
import {images} from '../../../common';
import {getCoverages} from '../../../utils/rest-services';
import {Loader} from '../../../components';
import {path} from '../../../common/routesNames';
import {useNavigate} from 'react-router';

const Dashboard = () => {
  const [covegeList, setCovegeList] = useState([]);
  const [isloader, setIsloader] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getTotalCoveragesList();
  }, []);

  const getTotalCoveragesList = async () => {
    let response = await getCoverages();
    const {
      content: {coverages},
    } = response;
    if (response && response.success && response?.content?.coverages) {
      setCovegeList(coverages);
    }
    setIsloader(false);
  };

  const coveragePlanIcon = id => {
    switch (id) {
      case 1:
        id === allCommonText.serviceTypeEnums.vehicle;
        return (
          <span>
            <img src={images.coverageVehicle} alt="Icon" />
          </span>
        );
      case 2:
        id === allCommonText.serviceTypeEnums.home;
        return (
          <span>
            <img src={images.coverageHome} alt="Icon" />
          </span>
        );
      case 3:
        id === allCommonText.serviceTypeEnums.device;
        return (
          <span>
            <img src={images.coverageDevice} alt="Icon" />
          </span>
        );
      case 4:
        id === allCommonText.serviceTypeEnums.commercial;
        return (
          <span>
            <img src={images.coverageCommercial} alt="Icon" />
          </span>
        );
    }
  };
  return (
    <>
      <div className="main-dashboard-container">
        {isloader ? (
          <div className="edit-popup-loader">
            <Loader />
          </div>
        ) : (
          <>
            <h3>{allCommonText.dashboard.previousCoverages}</h3>
            <Grid container spacing={3}>
              {covegeList.map((item, index) => {
                const {
                  serviceTypeId,
                  descriptionText,
                  totalCoverages,
                  totalCost,
                } = item;

                return (
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3} key={index}>
                    <div
                      onClick={() => {
                        navigate({
                          pathname: path.policyAndClaims,
                          search: `?plan=${serviceTypeId}`,
                        });
                      }}
                      className="coverages-detail-box">
                      <div className="image-coverage">
                        {coveragePlanIcon(serviceTypeId)}
                      </div>
                      <p>{descriptionText}</p>
                      <div className="coverage-box">
                        <span>{totalCoverages}</span>
                      </div>
                      <span>{totalCost}</span>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="claims-status mt-4">
                  <h3>{allCommonText.dashboard.openClaim}</h3>
                  {covegeList.map((item, index) => {
                    const {descriptionText, openClaims} = item;
                    return (
                      <div className="status-box" key={index}>
                        <span>{descriptionText}</span>
                        <span>{openClaims}</span>
                        <div className="open-claim-container">
                          <div
                            onClick={() => {
                              navigate({
                                pathname: path.policyAndClaims,
                                search: `?claim=1&status=1&serviceTypeId=${item.serviceTypeId}`,
                              });
                            }}
                            className="open-claim-button">
                            {`Open Claims: ${openClaims}`}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="claims-status mt-4">
                  <h3>{allCommonText.dashboard.closedClaim}</h3>
                  {covegeList.map((item, index) => {
                    const {
                      descriptionText,
                      closedClaims,
                      rejectedClaims,
                      settledClaims,
                    } = item;
                    return (
                      <div
                        className="status-box"
                        key={index}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={`Rejected Claims: ${rejectedClaims}, Settled Claims: ${settledClaims}`}>
                        <span>{descriptionText}</span>
                        <span>{closedClaims}</span>
                        <div className="open-claim-container">
                          <div
                            onClick={() => {
                              navigate({
                                pathname: path.policyAndClaims,
                                search: `?claim=1&status=2&serviceTypeId=${item.serviceTypeId}`,
                              });
                            }}
                            className="open-claim-button">
                            {`Settled Claims: ${settledClaims}`}
                          </div>
                          <div
                            onClick={() => {
                              navigate({
                                pathname: path.policyAndClaims,
                                search: `?claim=1&status=4&serviceTypeId=${item.serviceTypeId}`,
                              });
                            }}
                            className="open-claim-button">
                            {`Rejected Claims: ${rejectedClaims}`}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </div>
      <div className="secondry-footer">{footer.rights}</div>
    </>
  );
};
export default Dashboard;
