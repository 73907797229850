import React, {useState, useEffect} from 'react';
import Pagination from '@mui/material/Pagination';
import {allCommonText} from '../../../common/constants';
import {images} from '../../../common';
import {getClaims, getPolicy} from '../../../utils/rest-services';
import {Claims} from './claims';
import {Policies} from './policies';
import './styles.scss';
import {useLocation, useNavigate} from 'react-router';
import {path} from '../../../common/routesNames';

export const serviceTypeIcon = id => {
  switch (id) {
    case allCommonText.serviceTypeEnums.vehicle:
      return <img src={images.vehicleV} alt={allCommonText.icon} />;
    case allCommonText.serviceTypeEnums.home:
      return <img src={images.homeV} alt={allCommonText.icon} />;
    case allCommonText.serviceTypeEnums.commercial:
      return <img src={images.commercialV} alt={allCommonText.icon} />;
    case allCommonText.serviceTypeEnums.device:
      return <img src={images.devicesV} alt={allCommonText.icon} />;
    case allCommonText.serviceTypeEnums.disaster:
      return <img src={images.disasterIcon} alt={allCommonText.icon} />;
  }
};

export const planCategory = id => {
  if (
    id?.toLowerCase() === allCommonText.planCategory.month.toLowerCase() ||
    id?.toLowerCase() === allCommonText.planCategory.monthly.toLowerCase()
  ) {
    return 'rounded-pill monthly-background';
  } else if (
    id?.toLowerCase() === allCommonText.planCategory.biannual.toLowerCase() ||
    id?.toLowerCase() === allCommonText.planCategory.biannually.toLowerCase()
  ) {
    return 'rounded-pill biAnnual-background';
  } else if (
    id?.toLowerCase() === allCommonText.planCategory.annual.toLowerCase() ||
    id?.toLowerCase() === allCommonText.planCategory.annually.toLowerCase()
  ) {
    return 'rounded-pill annual-background';
  } else if (id === allCommonText.planCategory.rejected) {
    return 'rounded-pill rejected-background';
  } else if (id == allCommonText.planCategory.cancelled) {
    return 'rounded-pill cancelled-background';
  } else if (id === allCommonText.planCategory.pending) {
    return 'rounded-pill pending-background';
  } else {
    return 'rounded-pill settled-background';
  }
};
export const getFormattedDate = date => {
  const dateString = date;
  const dateObj = new Date(dateString);
  const formattedDate = `${
    dateObj.getMonth() + 1
  }-${dateObj.getDate()}-${dateObj.getFullYear()}`;
  return formattedDate;
};

const PolicyCliams = () => {
  const [policyList, setPolicyList] = useState([]);
  const [claimsList, setClaimsList] = useState([]);
  const [isButtonSelected, setIsButtonSelected] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageLoader, setPageLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const getParamsFromUrl = () => {
    return new URLSearchParams(location.search);
  };

  const plan = getParamsFromUrl().get('plan');
  const status = getParamsFromUrl().get('status');
  const serviceTypeId = getParamsFromUrl().get('serviceTypeId');
  const planTypes = [
    {all: 0},
    {Auto: 1},
    {Home: 2},
    {Device: 3},
    {Commercials: 4},
  ];
  const claimStatusEnums = [
    {all: 0},
    {Pending: 1},
    {Settled: 2},
    {Cancelled: 3},
    {Rejected: 4},
  ];

  function getKeyByValue(arr, value) {
    const foundItem = arr.find(item => Object.values(item)[0] == value);
    return foundItem ? Object.keys(foundItem)[0] : null;
  }
  // function getValueByKey(arr, value) {
  //   const foundItem = arr.find(item => Object.keys(item)[0] == value);
  //   return foundItem ? Object.values(foundItem)[0] : null;
  // }

  useEffect(() => {
    const isClaimPage = getParamsFromUrl().get('claim');
    if (isClaimPage == 1) {
      setIsButtonSelected(2);
    }
  }, []);

  useEffect(() => {
    const payLoadObj = {
      pageNo: currentPage,
      rowsPerPage: 10,
      id: 0,
      policyId: 0,
      status: 0,
      policyType: plan ?? 0,
    };
    if (isButtonSelected === 1) {
      payLoadObj.policyType = (plan ?? 0) || currentStatus;
      fetchPoliciesData(payLoadObj);
    } else {
      let status = getParamsFromUrl().get('status');
      let serviceTypeId = getParamsFromUrl().get('serviceTypeId');
      status = status ? parseInt(status) : 0;
      payLoadObj.status = status ?? currentStatus;
      payLoadObj.policyType = serviceTypeId ? parseInt(serviceTypeId) : 0;
      fetchClaimsData(payLoadObj);
    }
  }, [currentPage, isButtonSelected, location]);

  const fetchPoliciesData = async payLoadObj => {
    setPageLoader(true);
    let response = await getPolicy(payLoadObj);
    if (response) {
      const {
        data: {
          content: {list, totalPages},
        },
      } = response;
      if (list && list?.length > 0) {
        setTotalPages(totalPages);
        setPolicyList(list);
      } else {
        setTotalPages(1);
        setPolicyList([]);
      }
      setBtnLoader(false);
      setPageLoader(false);
    }
  };
  const fetchClaimsData = async payLoadObj => {
    setPageLoader(true);
    let response = await getClaims(payLoadObj);
    if (response) {
      const {
        data: {
          content: {list, totalPages},
        },
      } = response;
      if (list && list?.length > 0) {
        setTotalPages(totalPages || []);
        setClaimsList(list || []);
      } else {
        setTotalPages(0);
        setClaimsList([]);
      }
      setBtnLoader(false);
      setPageLoader(false);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getPolicyStatusByText = text => {
    if (text == allCommonText.policyClaims.all) {
      return allCommonText.policyStatusEnums.all;
    } else if (text == allCommonText.policyClaims.auto) {
      return allCommonText.policyStatusEnums.auto;
    } else if (text == allCommonText.policyClaims.home) {
      return allCommonText.policyStatusEnums.home;
    } else if (text == allCommonText.policyClaims.mobile) {
      return allCommonText.policyStatusEnums.mobile;
    } else if (text == allCommonText.policyClaims.commercial) {
      return allCommonText.policyStatusEnums.commercials;
    } else {
      return allCommonText.policyStatusEnums.disaster;
    }
  };
  const policiesPayload = (id, status, isClear) => {
    let payLoadObj;
    if (isClear) {
      navigate({
        pathname: window.location.pathname,
        search: ``,
      });
      setCurrentStatus(0);
      payLoadObj = {
        pageNo: 1,
        rowsPerPage: allCommonText.policyClaims.pageSize,
        id: 0,
        policyType: 0,
      };
    } else {
      navigate({
        pathname: window.location.pathname,
        search: `?plan=${getPolicyStatusByText(status)}`,
      });
      setCurrentStatus(getPolicyStatusByText(status));
      payLoadObj = {
        pageNo: 1,
        rowsPerPage: allCommonText.policyClaims.pageSize,
        id: id == '' ? 0 : id.replace(/\D/g, ''),
        policyType: getPolicyStatusByText(status),
      };
    }
    fetchPoliciesData(payLoadObj);
  };
  const getClaimStatusByText = text => {
    if (text == allCommonText.policyClaims.all) {
      return allCommonText.claimStatusEnums.all;
    } else if (text == allCommonText.policyClaims.pending) {
      return allCommonText.claimStatusEnums.pending;
    } else if (text == allCommonText.policyClaims.settled) {
      return allCommonText.claimStatusEnums.settled;
    } else if (text == allCommonText.policyClaims.cancelled) {
      return allCommonText.claimStatusEnums.cancelled;
    } else {
      return allCommonText.claimStatusEnums.rejected;
    }
  };
  const claimPayload = (claimNumber, policyId, status, type, isClear) => {
    let payLoadObj;
    if (isClear) {
      setCurrentStatus(0);
      payLoadObj = {
        pageNo: 1,
        rowsPerPage: allCommonText.policyClaims.pageSize,
        status: 0,
        id: 0,
        policyId: 0,
        policyType: 0,
      };
    } else {
      setCurrentStatus(getClaimStatusByText(status));
      payLoadObj = {
        pageNo: 1,
        rowsPerPage: allCommonText.policyClaims.pageSize,
        status: getClaimStatusByText(status),
        id: claimNumber == '' ? 0 : claimNumber.replace(/\D/g, ''),
        policyId: policyId == '' ? 0 : policyId.replace(/\D/g, ''),
        // policyType: getValueByKey(planTypes, type),
        policyType: getPolicyStatusByText(type),
      };
    }
    fetchClaimsData(payLoadObj);
  };

  return (
    <>
      <div className="policy-and-claim">
        <h3>{allCommonText.profile.policyClaims}</h3>
        <div className="policy-claim-action-tab">
          <button
            type="button"
            className={
              isButtonSelected == 1
                ? 'button-unselected button-selected '
                : 'button-unselected'
            }
            onClick={() => {
              setIsButtonSelected(1);
              setCurrentPage(1);
              setCurrentStatus(0);
              navigate(path.policyAndClaims);
            }}>
            {allCommonText.policyClaims.policy}
          </button>
          <button
            type="button"
            className={
              isButtonSelected == 2
                ? 'button-unselected button-selected '
                : 'button-unselected'
            }
            onClick={() => {
              setIsButtonSelected(2);
              setCurrentPage(1);
              setCurrentStatus(0);
              navigate(path.policyAndClaims);
            }}>
            {allCommonText.policyClaims.claim}
          </button>
        </div>

        {isButtonSelected == 1 ? (
          <Policies
            policiesList={policyList}
            pageLoader={pageLoader}
            btnLoader={btnLoader}
            selectedPlan={getKeyByValue(planTypes, plan)}
            fetchPolicies={() => {
              const payLoadObj = {
                pageNo: currentPage,
                rowsPerPage: 10,
                id: 0,
                policyId: 0,
                status: 0,
              };

              fetchPoliciesData(payLoadObj);
            }}
            policySearchHandler={(id, status, isClear) => {
              setBtnLoader(true);
              setCurrentPage(1);
              policiesPayload(id, status, isClear);
            }}
          />
        ) : (
          <Claims
            pageLoader={pageLoader}
            btnLoader={btnLoader}
            claimsList={claimsList}
            planType={getKeyByValue(planTypes, serviceTypeId)}
            status={getKeyByValue(claimStatusEnums, status)}
            claimSearchHandler={async (
              primary,
              secondry,
              status,
              type,
              isClear,
            ) => {
              setBtnLoader(true);
              setCurrentPage(1);
              claimPayload(primary, secondry, status, type, isClear);
            }}
          />
        )}
      </div>

      {totalPages > 1 && (
        <div className="pagination-container">
          <Pagination
            page={currentPage}
            color={'secondary'}
            onChange={handlePageChange}
            count={totalPages}
          />
        </div>
      )}
    </>
  );
};

export default PolicyCliams;
