import React, {useState, useEffect} from 'react';
import {colors} from '../../../common';
import {allCommonText} from '../../../common/constants';
import {
  AddNewBillingMethod,
  GenericButton,
  GenericModal,
  Loader,
  ShowLinkedSubscription,
} from '../../../components';
import {deleteBillingCard, getBillingCards} from '../../../utils/rest-services';
import './styles.scss';
import {BsTrashFill} from 'react-icons/bs';
import {GrLink} from 'react-icons/gr';
import {toast} from 'react-toastify';

const BillingCard = ({type, digits, onTrashPress, onLinkPress}) => {
  return (
    <div className="billing-card-container">
      <div className="billing-card-info">
        <i
          class={`fa-brands fa-cc-${type}`}
          style={{color: colors.primary}}></i>
        <span>{`**** **** **** ${digits}`}</span>
      </div>
      <div className="cards-icon-container">
        <div onClick={onTrashPress} className="icon-trash">
          <BsTrashFill size={15} color={colors.primary} />
        </div>
        <div onClick={onLinkPress} className="icon-trash">
          <GrLink size={15} color={colors.lightBlue} />
        </div>
      </div>
    </div>
  );
};

const BillingCards = () => {
  const [btnLoader, setBtnLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [cardsList, setCardsList] = useState([]);
  const [isPopupShow, setIsPopupShow] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState('');
  const [addNewCard, setAddNewCard] = useState(false);
  const [showSubscriptionModel, setShowSubscriptionModel] = useState(false);
  const [alertDetails, setAlertDetails] = useState({
    type: allCommonText.popupEnums.alert,
    title: allCommonText.heading.subscriptionInfo,
    body: allCommonText.policyClaims.cantDelCard,
    btnText1: allCommonText.cancel,
    selectedId: '',
  });
  useEffect(() => {
    fetchBillingCards();
  }, []);

  const fetchBillingCards = async () => {
    setPageLoader(true);
    let response = await getBillingCards();
    if (response && response.data.success) {
      if (response.data && response?.data?.content) {
        setCardsList(response.data.content);
      } else {
        setCardsList([]);
      }
      setPageLoader(false);
    } else {
      setCardsList([]);
      setPageLoader(false);
      toast.error(response?.data?.description || allCommonText.somethingWrong, {
        autoClose: false,
      });
    }
  };

  const deleteCardHandler = async () => {
    setBtnLoader(true);
    let response = await deleteBillingCard(alertDetails.selectedId);
    if (response && response.data.success) {
      setIsPopupShow(false);
      fetchBillingCards();
      setBtnLoader(false);
      toast.success(response.data?.description);
    } else {
      toast.error(response.data?.description);
    }
  };

  return (
    <div className="main-billing-card-container">
      <h3>{allCommonText.profile.billingCards}</h3>
      {!pageLoader && (
        <>
          <GenericButton
            id="billing-card"
            onPress={() => setAddNewCard(true)}
            buttonText={allCommonText.buttonTexts.addCard}
            disable={cardsList.length >= 5}
          />
          {cardsList.length >= 5 && (
            <div className="card-limit-exceed">
              <span>{allCommonText.note}</span>
              <span> {allCommonText.billingCardLimit} </span>
            </div>
          )}
        </>
      )}
      {pageLoader ? (
        <div className="no-subscription-container">
          <Loader size={40} />
        </div>
      ) : cardsList?.length > 0 ? (
        cardsList?.map((i, index) => (
          <BillingCard
            key={index}
            type={i.cardType}
            digits={i.cardNumber}
            onLinkPress={() => {
              if (i.subCount <= 0) {
                setAlertDetails({
                  type: allCommonText.popupEnums.alert,
                  title: allCommonText.heading.subscriptionInfo,
                  body: allCommonText.policyClaims.noLinkedSubscription,
                  btnText1: allCommonText.cancel,
                });
                setIsPopupShow(true);
              } else {
                setSubscriptionId(i.stripePaymentMethodId);
                setShowSubscriptionModel(true);
              }
            }}
            onTrashPress={() => {
              if (i.subCount <= 0) {
                setAlertDetails({
                  type: allCommonText.popupEnums.delete,
                  title: allCommonText.heading.sureToDeleteCards,
                  body: allCommonText.heading.deleteCards,
                  btnText1: allCommonText.yes,
                  selectedId: i.stripePaymentMethodId,
                });
                setIsPopupShow(true);
              } else {
                setAlertDetails({
                  type: allCommonText.popupEnums.alert,
                  title: allCommonText.heading.subscriptionInfo,
                  body: allCommonText.policyClaims.cantDelCard,
                  btnText1: allCommonText.cancel,
                });
                setIsPopupShow(true);
              }
            }}
          />
        ))
      ) : (
        !pageLoader && (
          <div className="no-subscription-container">
            <span>{allCommonText.labels.noBillingCardsFounds}</span>
          </div>
        )
      )}
      {addNewCard && (
        <AddNewBillingMethod
          show={addNewCard}
          handleClose={isNewData => {
            setAddNewCard(false);
            if (isNewData === true) {
              fetchBillingCards();
            }
          }}
        />
      )}
      <GenericModal
        grayButton={alertDetails.type !== allCommonText.popupEnums.delete}
        grayButton2
        buttonLoader={btnLoader}
        show={isPopupShow}
        btnText={alertDetails.btnText1}
        type={alertDetails.type}
        title={alertDetails.title}
        body={alertDetails.body}
        btnText2={allCommonText.no}
        button2={alertDetails.type == allCommonText.popupEnums.delete}
        handleClose={async () => {
          if (alertDetails.type == allCommonText.popupEnums.delete) {
            deleteCardHandler();
          } else {
            setIsPopupShow(false);
          }
        }}
        backDrop={() => {
          setIsPopupShow(false);
        }}
        handleAction={() => {
          setIsPopupShow(false);
        }}
      />
      {showSubscriptionModel && (
        <ShowLinkedSubscription
          handleClose={() => {
            setShowSubscriptionModel(false);
          }}
          show={showSubscriptionModel}
          subscriptionId={subscriptionId}
        />
      )}
    </div>
  );
};

export default BillingCards;
