import React from 'react';
import {AiFillCheckCircle, AiOutlineArrowDown} from 'react-icons/ai';
import {TbListSearch} from 'react-icons/tb';
import './styles.scss';

export const AttachmentsDetails = ({
  fileName,
  onPressView,
  onPressDownload,
}) => {
  return (
    <div className="attachment-details">
      <div className="attachment-file">
        <span>
          <AiFillCheckCircle color="#1FB141" size={20} />
        </span>
        <span className="attachment-file-name">{fileName}</span>
      </div>
      <div className="attachment-action">
        <span className="attachment-view" onClick={onPressView}>
          <TbListSearch />
        </span>
        <span className="attachment-download" onClick={onPressDownload}>
          <AiOutlineArrowDown />
        </span>
      </div>
    </div>
  );
};
