import React, {useState, useEffect} from 'react';
import {AttachmentsDetails, Loader} from '../../../../components';
import {images} from '../../../../common';
import {allCommonText} from '../../../../common/constants';
import {planCategory} from '../../policy-and-claims';
import './styles.scss';
import {getClaimDetailsById} from '../../../../utils/rest-services';
import {path} from '../../../../common/routesNames';
import {useNavigate} from 'react-router';
import {Grid} from '@mui/material';
import _ from 'lodash';

function PlanClaimDetails() {
  const [loader, setLoader] = useState(false);
  const [claimsData, setClaimsData] = useState(null);
  const [adminDoc, setAdminDoc] = useState([]);
  const [adminNotes, setAdminNotes] = useState('');
  const [claimDocList, setclaimDocList] = useState([]);
  const [selectedtab, setSelectedtab] = useState(1);
  const GETPLANSTATUS = 'pending';

  const getParamsFromUrl = () => {
    return new URLSearchParams(location.search);
  };

  let query = getParamsFromUrl();

  const claimId = query.get('id');

  const navigate = useNavigate();
  useEffect(() => {
    fetchClaimDetails(claimId);
  }, []);

  const fetchClaimDetails = async () => {
    setLoader(true);
    let response = await getClaimDetailsById(claimId);
    if (response && response.data.success) {
      const {
        data: {
          content: {claim, claimDocs, adminDocs, adminNote},
        },
      } = response;
      setClaimsData(claim);
      setclaimDocList(claimDocs);
      setAdminNotes(adminNote);
      setAdminDoc(adminDocs);

      const groupedArrays = _.groupBy(claimDocs, 'documentType');
      setClaimDoc({
        driverLisense: (groupedArrays[1] || []).map(item => item),
        registration: (groupedArrays[2] || []).map(item => item),
        insuranceDeclaration: (groupedArrays[3] || []).map(item => item),
        claimInfo: (groupedArrays[4] || []).map(item => item),
        incidentReport: (groupedArrays[5] || []).map(item => item),
        other: (groupedArrays[6] || []).map(item => item),
      });
    }
    setLoader(false);
  };

  const [claimDoc, setClaimDoc] = useState({
    driverLisense: [],
    registration: [],
    insuranceDeclaration: [],
    claimInfo: [],
    incidentReport: [],
    other: [],
  });
  const attachments = [
    {
      title: allCommonText.claimDetails.DRIVER_LICENSE,
      file: claimDoc.driverLisense,
    },
    {
      title: allCommonText.claimDetails.REGISTRATION_DOC,
      file: claimDoc.registration,
    },
    {
      title: allCommonText.claimDetails.INSURANCE_DECLARATION,
      file: claimDoc.insuranceDeclaration,
    },
    {
      title: allCommonText.claimDetails.CLAIM_INFO,
      file: claimDoc.claimInfo,
    },
    {
      title: allCommonText.claimDetails.INCIDENT_REPORT,
      file: claimDoc.incidentReport,
    },
    {
      title: allCommonText.claimDetails.OTHER_MISC,
      file: claimDoc.other,
    },
  ];
  const ClaimDetailsContent = ({title, Value, valueAmount}) => {
    return (
      <div className="ref-detail-content">
        <span className="ref-detail-content-title">{title}</span>
        <span className={valueAmount ? 'boldColor' : ''}>{Value}</span>
      </div>
    );
  };

  const handleDownload = i => {
    fetch(i).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = i.split('\\').pop();
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  return loader ? (
    <div className="loader-container-invoice w-100">
      <Loader />
    </div>
  ) : (
    <div className="main-subscription-container plan-claim-detail-pad">
      {claimsData === null ? (
        <div className="not-avilable">
          {allCommonText.claimDetails.claimNotAvailabale}
        </div>
      ) : (
        <div>
          <div className="plan-claim-detail-header">
            <div className="plan-claim-detail-title">
              <img
                src={images.redirectIcon}
                alt=""
                onClick={() => navigate({pathname: path.policyAndClaims})}
              />
              <h3>{allCommonText.claimDetails.claimDetails}</h3>
            </div>
            <div className="plan-claim-detail-badges">
              <span className={planCategory(claimsData?.claimStatus)}>
                {claimsData?.claimStatus}
              </span>
            </div>
          </div>
          <div className="plan-claim-detail-content-box">
            <ClaimDetailsContent
              title={allCommonText.claimDetails.claimNumber}
              Value={claimsData?.viewClaimId}
            />
            <ClaimDetailsContent
              title={allCommonText.claimDetails.claimPolicyID}
              Value={claimsData?.cartItemId}
            />
          </div>
          <div className="plan-claim-detail-content-box">
            <ClaimDetailsContent
              title={allCommonText.claimDetails.carrierName}
              Value={claimsData?.providerName}
            />
            <ClaimDetailsContent
              title={allCommonText.claimDetails.providerClaimNo}
              Value={
                claimsData?.claimNumber == '' ? 'N/A' : claimsData?.claimNumber
              }
            />
          </div>
          <div className="plan-claim-detail-content-box">
            <ClaimDetailsContent
              title={allCommonText.claimDetails.dateLoss}
              Value={claimsData?.dateLoss}
            />
            <ClaimDetailsContent
              title={allCommonText.claimDetails.dateFile}
              Value={claimsData?.dateFiled.split(' ')[0]}
            />
          </div>
          <div className="plan-claim-detail-content-box">
            <ClaimDetailsContent
              title={allCommonText.claimDetails.claimAmount}
              Value={`$${parseFloat(claimsData?.claimAmount).toFixed(2)}`}
              valueAmount={true}
            />
            {claimsData?.claimStatus.toLowerCase() === GETPLANSTATUS ? (
              ''
            ) : (
              <ClaimDetailsContent
                title={allCommonText.policyClaims.settlledDate}
                Value={
                  claimsData?.dateSettled == ''
                    ? 'N/A'
                    : claimsData?.dateSettled
                }
              />
            )}
          </div>
          <div className="sub-detail-tabs">
            <div
              onClick={() => setSelectedtab(1)}
              className={
                selectedtab === 1
                  ? 'subscrition-item-tab sub-selected-tab'
                  : 'subscrition-item-tab'
              }>
              {allCommonText.claimDetails.myInfo}
            </div>
            <div
              onClick={() => setSelectedtab(2)}
              className={
                selectedtab === 2
                  ? 'subscrition-item-tab sub-selected-tab'
                  : 'subscrition-item-tab'
              }>
              {allCommonText.claimDetails.adminInfo}
            </div>
          </div>
          {selectedtab === 1 && (
            <>
              {claimsData?.userNotes != '' && (
                <div className="claimNotes">
                  <p>{allCommonText.heading.notes}</p>
                  <div className="claim-details-note">
                    {claimsData?.userNotes}
                  </div>
                </div>
              )}
              {claimDocList && claimDocList.length > 0 && (
                <div className="">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="calim-attachments">
                      <p>{allCommonText.heading.attachments}</p>
                      <Grid container spacing={1}>
                        {attachments.map((item, index) => {
                          const {title, file} = item;
                          const hasFiles = file.length > 0;
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              xl={6}
                              key={index}
                              className={`attachment-grid ${
                                hasFiles ? '' : 'empty'
                              }`}>
                              <div className="attachment-content">
                                <div className="attachment-header">
                                  <img
                                    src={images.attachment}
                                    alt={allCommonText.icon}
                                  />
                                  <h6>{title}</h6>
                                </div>

                                {file.map((i, index) => (
                                  <AttachmentsDetails
                                    key={index}
                                    onPressDownload={() => {
                                      handleDownload(i.documentPath);
                                    }}
                                    fileName={i.documentPath.split('\\').pop()}
                                    onPressView={() => {
                                      const link = document.createElement('a');
                                      link.href = i.documentPath;
                                      link.target = '_blank';
                                      link.rel = 'noopener noreferrer';
                                      link.click();
                                    }}
                                  />
                                ))}
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  </Grid>
                </div>
              )}
            </>
          )}
          {selectedtab === 2 && (
            <>
              {adminNotes && (
                <div className="adminNotes">
                  <p>{allCommonText.heading.notes}</p>
                  <div className="claim-details-note">{adminNotes}</div>
                </div>
              )}

              {adminDoc && adminDoc.length > 0 ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="calim-attachments">
                    <p>{allCommonText.heading.attachments}</p>
                    <div className="admin-attachements-files">
                      {adminDoc.map((i, index) => (
                        <AttachmentsDetails
                          key={index}
                          onPressDownload={() => {
                            handleDownload(i.documentPath);
                          }}
                          fileName={i.documentPath.split('\\').pop()}
                          onPressView={() => {
                            const link = document.createElement('a');
                            link.href = i.documentPath;
                            link.target = '_blank';
                            link.rel = 'noopener noreferrer';
                            link.click();
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </Grid>
              ) : claimsData?.claimStatus.toLowerCase() === GETPLANSTATUS ? (
                <div className="not-availabale">
                  {allCommonText.pendingInfoAvailabaleNote}
                </div>
              ) : (
                <div className="not-availabale">
                  {allCommonText.noInfoAvailabale}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default PlanClaimDetails;
