import React from 'react';
import {allCommonText} from '../../../../common/constants';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export const getPlansDetails = item => {
  const {
    serviceProvider,
    policyId,
    make,
    model,
    deductibleAmount,
    serviceTypeId,
    brand,
    planCost,
    address,
  } = item || {};

  switch (serviceTypeId) {
    case allCommonText.serviceTypeEnums.home:
      return {
        first: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.policyID}</span>
            <span
              data-tooltip-id={`policy-id-tooltip`}
              data-tooltip-content={`Copy to Clipboard`}
              style={{cursor: 'pointer'}}
              onClick={() => {
                navigator.clipboard.writeText(policyId);
              }}>
              {policyId === ''
                ? allCommonText.policyClaims.notAvailable
                : policyId}
            </span>
            <Tooltip id={`policy-id-tooltip`} />
          </div>
        ),
        second: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.provider}</span>
            <span>
              {serviceProvider === ''
                ? allCommonText.policyClaims.notAvailable
                : serviceProvider}
            </span>
          </div>
        ),
        third: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.deductibles}</span>
            <span>
              {deductibleAmount === ''
                ? allCommonText.policyClaims.notAvailable
                : `$${parseFloat(deductibleAmount).toFixed(2)}`}
            </span>
          </div>
        ),
        fourth: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.planCost}</span>
            <span>
              {planCost === ''
                ? allCommonText.policyClaims.notAvailable
                : `$${parseFloat(planCost).toFixed(2)}`}
            </span>
          </div>
        ),
        fifth: (
          <div className="details-item address">
            <span>{allCommonText.policyClaims.address}</span>
            <span>
              {address === ''
                ? allCommonText.policyClaims.notAvailable
                : address}
            </span>
          </div>
        ),
      };
    case allCommonText.serviceTypeEnums.disaster:
      return {
        first: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.policyID}</span>
            <span
              data-tooltip-id={`policy-id-tooltip`}
              data-tooltip-content={`Copy to Clipboard`}
              style={{cursor: 'pointer'}}
              onClick={() => {
                navigator.clipboard.writeText(policyId);
              }}>
              {policyId === ''
                ? allCommonText.policyClaims.notAvailable
                : policyId}
            </span>
            <Tooltip id={`policy-id-tooltip`} />
          </div>
        ),
        second: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.provider}</span>
            <span>
              {serviceProvider === ''
                ? allCommonText.policyClaims.notAvailable
                : serviceProvider}
            </span>
          </div>
        ),
        third: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.deductibles}</span>
            <span>
              {deductibleAmount === ''
                ? allCommonText.policyClaims.notAvailable
                : `$${parseFloat(deductibleAmount).toFixed(2)}`}
            </span>
          </div>
        ),
        fourth: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.planCost}</span>
            <span>
              {planCost === ''
                ? allCommonText.policyClaims.notAvailable
                : `$${parseFloat(planCost).toFixed(2)}`}
            </span>
          </div>
        ),
        fifth: (
          <div className="details-item address">
            <span>{allCommonText.policyClaims.address}</span>
            <span>
              {address === ''
                ? allCommonText.policyClaims.notAvailable
                : address}
            </span>
          </div>
        ),
      };
    case allCommonText.serviceTypeEnums.device:
      return {
        first: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.policyID}</span>
            <span
              data-tooltip-id={`policy-id-tooltip`}
              data-tooltip-content={`Copy to Clipboard`}
              style={{cursor: 'pointer'}}
              onClick={() => {
                navigator.clipboard.writeText(policyId);
              }}>
              {policyId === ''
                ? allCommonText.policyClaims.notAvailable
                : policyId}
            </span>
            <Tooltip id={`policy-id-tooltip`} />
          </div>
        ),
        second: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.provider}</span>
            <span>
              {serviceProvider === ''
                ? allCommonText.policyClaims.notAvailable
                : serviceProvider}
            </span>
          </div>
        ),
        third: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.brandName}</span>
            <span>
              {brand === '' ? allCommonText.policyClaims.notAvailable : brand}
            </span>
          </div>
        ),
        fourth: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.model}</span>
            <span>
              {model === '' ? allCommonText.policyClaims.notAvailable : model}
            </span>
          </div>
        ),
        fifth: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.deductibles}</span>
            <span>
              {deductibleAmount === ''
                ? allCommonText.policyClaims.notAvailable
                : `$${parseFloat(deductibleAmount).toFixed(2)}`}
            </span>
          </div>
        ),
        sixth: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.planCost}</span>
            <span>
              {planCost === ''
                ? allCommonText.policyClaims.notAvailable
                : `$${parseFloat(planCost).toFixed(2)}`}
            </span>
          </div>
        ),
      };
    case allCommonText.serviceTypeEnums.commercial:
      return {
        first: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.policyID}</span>
            <span
              data-tooltip-id={`policy-id-tooltip`}
              data-tooltip-content={`Copy to Clipboard`}
              style={{cursor: 'pointer'}}
              onClick={() => {
                navigator.clipboard.writeText(policyId);
              }}>
              {policyId === ''
                ? allCommonText.policyClaims.notAvailable
                : policyId}
            </span>
            <Tooltip id={`policy-id-tooltip`} />
          </div>
        ),
        second: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.provider}</span>
            <span>
              {serviceProvider === ''
                ? allCommonText.policyClaims.notAvailable
                : serviceProvider}
            </span>
          </div>
        ),
        third: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.make}</span>
            <span>
              {make === '' ? allCommonText.policyClaims.notAvailable : make}
            </span>
          </div>
        ),
        fourth: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.model}</span>
            <span>
              {model === '' ? allCommonText.policyClaims.notAvailable : model}
            </span>
          </div>
        ),
        fifth: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.deductibles}</span>
            <span>
              {deductibleAmount === ''
                ? allCommonText.policyClaims.notAvailable
                : `$${parseFloat(deductibleAmount).toFixed(2)}`}
            </span>
          </div>
        ),
        sixth: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.planCost}</span>
            <span>
              {planCost === ''
                ? allCommonText.policyClaims.notAvailable
                : `$${parseFloat(planCost).toFixed(2)}`}
            </span>
          </div>
        ),
      };
    case allCommonText.serviceTypeEnums.vehicle:
      return {
        first: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.policyID}</span>
            <span
              data-tooltip-id={`policy-id-tooltip`}
              data-tooltip-content={`Copy to Clipboard`}
              style={{cursor: 'pointer'}}
              onClick={() => {
                navigator.clipboard.writeText(policyId);
              }}>
              {policyId === ''
                ? allCommonText.policyClaims.notAvailable
                : policyId}
            </span>
            <Tooltip id={`policy-id-tooltip`} />
          </div>
        ),
        second: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.provider}</span>
            <span>
              {serviceProvider === ''
                ? allCommonText.policyClaims.notAvailable
                : serviceProvider}
            </span>
          </div>
        ),
        third: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.make}</span>
            <span>
              {make === '' ? allCommonText.policyClaims.notAvailable : make}
            </span>
          </div>
        ),
        fourth: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.model}</span>
            <span>
              {model === '' ? allCommonText.policyClaims.notAvailable : model}
            </span>
          </div>
        ),
        fifth: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.deductibles}</span>
            <span>
              {deductibleAmount === ''
                ? allCommonText.policyClaims.notAvailable
                : `$${parseFloat(deductibleAmount).toFixed(2)}`}
            </span>
          </div>
        ),
        sixth: (
          <div className="details-item">
            <span>{allCommonText.policyClaims.planCost}</span>
            <span>
              {planCost === ''
                ? allCommonText.policyClaims.notAvailable
                : `$${parseFloat(planCost).toFixed(2)}`}
            </span>
          </div>
        ),
      };
  }
};
