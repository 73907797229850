import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import {MdOutlineClose, MdMoreVert} from 'react-icons/md';
import {MdOutlineAdd} from 'react-icons/md';
import {allCommonText} from '../../common/constants';
import './styles.scss';

export function ActionDropDownPopUp({
  cancelPolicypopup,
  addClaim,
  firstItem,
  secondItem,
  thiredOption,
  forthOption,
}) {
  return (
    <div className="action-menu">
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          <span className="action-popup-style">
            <MdMoreVert size={18} />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="action-popup">
          {firstItem && (
            <Dropdown.Item onClick={cancelPolicypopup}>
              {firstItem}
            </Dropdown.Item>
          )}
          {cancelPolicypopup && (
            <Dropdown.Item onClick={cancelPolicypopup}>
              {
                <div className="action-options mb-2">
                  <MdOutlineClose className="close-cancel-policy" />
                  <span className="text-cancel-policy px-2">
                    {allCommonText.policyClaims.cancelPolicy}
                  </span>
                </div>
              }
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={addClaim}>
            {secondItem || (
              <div className="action-options">
                <MdOutlineAdd className="icon-add-claim" />
                <span className="text-option-claim px-2">
                  {allCommonText.policyClaims.addClaim}
                </span>
              </div>
            )}
          </Dropdown.Item>
          {thiredOption && <Dropdown.Item>{thiredOption}</Dropdown.Item>}
          {forthOption && <Dropdown.Item>{forthOption}</Dropdown.Item>}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
