import React, {useContext} from 'react';
import {images} from '../../common';
import {allCommonText, cart} from '../../common/constants';
import ApplicationContext from '../../utils/context-api/context';
import './styles.scss';

export const ViewCartButton = () => {
  const {cartCount, setIsOpenPane} = useContext(ApplicationContext);
  return (
    <>
      {cartCount > 0 ? (
        <div
          className="cart-button"
          onClick={() => {
            setIsOpenPane({isPaneOpen: true});
            if (typeof window != 'undefined' && window.document) {
              document.body.style.overflow = 'hidden';
            }
          }}>
          <img src={images.viewCart} alt={allCommonText.icon} />
          <span>{cart.viewCart}</span>
        </div>
      ) : null}
    </>
  );
};
