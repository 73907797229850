import React, {useState} from 'react';
import './styles.scss';
import {BsSearch} from 'react-icons/bs';
import {colors, images} from '../../common';

export const TextInputField = ({
  value,
  setValue,
  placeHolder,
  fullWidth,
  phone,
  key,
  reff,
  disable,
  error,
  handleBlur,
  prefixerValue,
  search,
  isPassword,
  ...props
  // className,
  // touched,
}) => {
  const [isEyeOpen, setIsEyeOpen] = useState(false);
  const {eyeOpen, eyeClose} = images;

  return (
    <>
      <div
        className={
          fullWidth
            ? 'input-field-container full-width-input-generic'
            : 'input-field-container'
        }>
        {phone && (
          <div className="additonal-one">
            <span>+1</span>
          </div>
        )}
        {prefixerValue && (
          <div className="additional-prefixes">
            <span>{prefixerValue}</span>
          </div>
        )}
        <input
          type={isPassword && !isEyeOpen ? 'password' : 'text'}
          value={value}
          className="yearContainer "
          placeholder={placeHolder}
          disabled={disable}
          id={key}
          key={`random-${key}`}
          onChange={setValue}
          onBlur={handleBlur}
          ref={reff}
          {...props}
        />
        {isPassword && (
          <img
            onClick={() => {
              setIsEyeOpen(!isEyeOpen);
            }}
            className={'eye-open'}
            src={isEyeOpen ? eyeOpen : eyeClose}
            alt="eye-icon"
          />
        )}
        {search && <BsSearch size={20} color={colors.gray} />}
      </div>
      {error && (
        <div className="error-input-container">
          {error ? <p className="form-error">{error}</p> : null}
        </div>
      )}
    </>
  );
};
