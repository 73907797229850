import React, {useState, useRef, useEffect} from 'react';
import {allCommonText, plans} from '../../common/constants';
import {Grid} from '@mui/material';
import {addItemToCart} from '../../utils/rest-services';
import {Formik} from 'formik';
import {addCommercialsSchema, editcommercialSchema} from '../../common/schemas';
import '../../App.scss';
import {
  TextInputField,
  CriteriaDropdown,
  GenericButton,
  Loader,
} from '../index';
import {useNavigate} from 'react-router';
import {path} from '../../common/routesNames';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {images} from '../../common';
import {ViewCartButton} from './../view-cart-button/index';

export const AddCommercialsInputs = ({
  carrierItem,
  deductibleItem,
  setPopUpResponse,
  setSowModal,
  selectedCartItems,
  loadingData,
  onItemAddedToCart,
}) => {
  useEffect(() => {
    getSelectedItems(selectedCartItems);
  }, []);

  const [selectedOption, setSelectedOption] = useState({
    carrier: null,
    maker: null,
    model: null,
    deductible: null,
    otherDeductibles: '',
  });
  const [isOtherDeductibles, setIsOtherDeductibles] = useState(false);
  const [isOtherCarrier, setIsOtherCarrier] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const {hiddenKeys, serviceTypeEnums, error} = allCommonText;
  const carrierRef = useRef();
  const deductiblesRef = useRef();
  let navigate = useNavigate();

  function isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }

  const numberWithCommas = x => {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
    return x;
  };

  const getSelectedItems = selectedCartItems => {
    const isObjectEmpty = isEmptyObject(selectedCartItems);
    if (!isObjectEmpty) {
      // check weather it is in edit mode or add mode
      const {
        serviceProviderName,
        serviceProviderId,
        deductibleAmount,
        make,
        model,
      } = selectedCartItems || {};
      let deductible = `$${numberWithCommas(deductibleAmount)}.00`;
      let index = deductibleItem.findIndex(item => item.value == deductible);
      const carrierObj = {
        value: serviceProviderName,
        label: serviceProviderName,
        id: serviceProviderId,
      };
      const deductibleObj = {
        value: deductible,
        label: deductible,
        id: 0,
      };
      const otherDeductibleObj = {
        id: -1,
        value: allCommonText.otherDeductibles,
        label: allCommonText.otherDeductibles,
      };

      if (index === -1) {
        setIsOtherDeductibles(true);
        setSelectedOption({
          carrier: carrierObj,
          maker: make,
          model: model,
          otherDeductibles: deductibleAmount.toString(), // here is difference
          deductible: otherDeductibleObj,
        });
      } else {
        setIsOtherDeductibles(false);
        setSelectedOption({
          carrier: carrierObj,
          maker: make,
          model: model,
          deductible: deductibleObj, // here is difference
        });
      }
      setIsEditable(true); // make component ready for edit or add
    } else {
      setSelectedOption({
        carrier: null,
        maker: null,
        model: null,
        deductible: null,
        otherDeductibles: '',
      });
      setIsEditable(false);
    }
  };

  const AddCommercialsToCart = async (values, actions) => {
    let AddToCartPayload;
    if (isEditable) {
      AddToCartPayload = {
        serviceTypeId: serviceTypeEnums.commercial.toString(),
        serviceProviderId: selectedCartItems.serviceProviderId,
        otherServiceProvider: null,
        deductibleAmount:
          values.deductibles.id === hiddenKeys.notAvailableFieldId
            ? values.otherDeductibles
            : values.deductibles.id,
        make: selectedCartItems.make,
        model: selectedCartItems.model,
        cartItemId: selectedCartItems.cartItemId,
      };
    } else {
      AddToCartPayload = {
        serviceTypeId: serviceTypeEnums.commercial.toString(),
        serviceProviderId: values.carrier.id,
        otherServiceProvider: values.otherCarrier,
        deductibleAmount:
          values.deductibles.id === hiddenKeys.notAvailableFieldId
            ? values.otherDeductibles
            : values.deductibles.id,
        make: values.make,
        model: values.model,
        cartItemId: 0,
      };
    }
    let result = await addItemToCart(AddToCartPayload);
    if (result && result.success) {
      toast(
        <div className="cart-icon-toaster">
          <img src={images.cartSmall} />
          <span className="toast-text">{result.description}</span>
        </div>,
      );

      onItemAddedToCart();
      if (isEditable) {
        navigate({
          pathname: path.plans,
        });
      }
    } else {
      setPopUpResponse({
        type: allCommonText.popupEnums.error,
        title: error,
        description: result.description,
      });
      setSowModal(true);
    }
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        carrier: null,
        deductibles: selectedOption.deductible || null,
        otherDeductibles: selectedOption.otherDeductibles || '',
        otherCarrier: '',
        make: '',
        model: '',
      }}
      onSubmit={(values, formikActions) => {
        AddCommercialsToCart(values, formikActions);
      }}
      validationSchema={
        isEditable
          ? editcommercialSchema(isOtherDeductibles)
          : addCommercialsSchema(isOtherDeductibles, isOtherCarrier)
      }
      enableReinitialize={true}>
      {({
        handleChange,
        handleSubmit,
        touched,
        errors,
        values,
        setFieldValue,
        isSubmitting,
      }) => (
        <>
          {loadingData ? (
            <Loader customContainerClass="loader-container-plan" />
          ) : (
            <div className="selectCriteria">
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <label htmlFor="" className="plan-label">
                    {plans.carrier_}
                  </label>
                  <CriteriaDropdown
                    options={carrierItem}
                    isDisable={isEditable}
                    name={'carrier'}
                    selectedOption={selectedOption.carrier || values.carrier}
                    error={touched.carrier && errors.carrier}
                    dropdowntitle={plans.selectCriteria}
                    onChangeOption={e => {
                      isEditable && setSelectedOption({carrier: e});
                      setFieldValue('carrier', e);
                      handleChange('carrier');
                      if (e.id === hiddenKeys.notAvailableFieldId) {
                        setIsOtherCarrier(true);
                        setTimeout(() => {
                          carrierRef?.current?.focus();
                        }, 500);
                      } else {
                        setIsOtherCarrier(false);
                      }
                    }}
                  />
                </Grid>
                {values?.carrier?.id === hiddenKeys.notAvailableFieldId && (
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <label htmlFor="" className="plan-label">
                      {plans.deductibles_}
                    </label>
                    <TextInputField
                      value={values.otherCarrier}
                      disable={isEditable}
                      setValue={e => {
                        setFieldValue('otherCarrier', e.target.value);
                        handleChange('otherCarrier');
                      }}
                      placeHolder={plans.otherCarrierName}
                      name={allCommonText.otherCarrier}
                      error={touched.otherCarrier && errors.otherCarrier}
                      reff={carrierRef}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <label htmlFor="" className="plan-label">
                    {plans.deductibles_}
                  </label>
                  <CriteriaDropdown
                    options={deductibleItem}
                    error={touched.deductibles && errors.deductibles}
                    dropdowntitle={plans.selectDeductible}
                    name={allCommonText.deductibles}
                    selectedOption={
                      selectedOption.deductible || values.deductibles
                    }
                    onChangeOption={e => {
                      isEditable &&
                        setSelectedOption(other => ({
                          ...other,
                          deductible: e,
                        }));
                      handleChange('deductibles');
                      setFieldValue('deductibles', e);
                      if (e.id === hiddenKeys.notAvailableFieldId) {
                        setIsOtherDeductibles(true);
                        setTimeout(() => {
                          deductiblesRef?.current?.focus();
                        }, 500);
                      } else {
                        setIsOtherDeductibles(false);
                      }
                    }}
                  />
                </Grid>
                {(values?.deductibles?.id === hiddenKeys.notAvailableFieldId ||
                  isOtherDeductibles) && (
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <label htmlFor="" className="plan-label">
                      {plans.otherDeductibles_}
                    </label>
                    <TextInputField
                      value={
                        selectedOption.otherDeductibles ||
                        values.otherDeductibles
                      }
                      setValue={e => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9.]/g, ''); // remove non-numeric characters
                        const restrictedValue = numericValue.substring(0, 7); // restrict to 10 digits
                        setFieldValue('otherDeductibles', restrictedValue);
                        handleChange('otherDeductibles');
                        isEditable &&
                          setSelectedOption(other => ({
                            ...other,
                            otherDeductibles: e.target.value,
                          }));
                      }}
                      placeHolder={plans.otherDeductiblesAmount}
                      name={allCommonText.otherDeductibles}
                      error={
                        touched.otherDeductibles && errors.otherDeductibles
                      }
                      reff={deductiblesRef}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label htmlFor="" className="plan-label">
                    {plans.maker_}
                  </label>
                  <TextInputField
                    value={selectedOption.maker || values.make}
                    setValue={e => {
                      setFieldValue('make', e.target.value);
                      handleChange('make');
                    }}
                    placeHolder={plans.enterMaker}
                    disable={isEditable}
                    name={allCommonText.maker}
                    error={touched.make && errors.make}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <label htmlFor="" className="plan-label">
                    {plans.model_}
                  </label>
                  <TextInputField
                    value={selectedOption.model || values.model}
                    setValue={e => {
                      setFieldValue('model', e.target.value);
                      handleChange('model');
                    }}
                    placeHolder={plans.enterModel}
                    disable={isEditable}
                    name={allCommonText.modal}
                    error={touched.model && errors.model}
                  />
                </Grid>
              </Grid>
            </div>
          )}
          <div className="cartContainer mt-3">
            <GenericButton
              id="cart-container"
              loading={isSubmitting}
              buttonText={plans.AddToCart}
              onPress={handleSubmit}
            />
            <ViewCartButton />
          </div>
        </>
      )}
    </Formik>
  );
};
