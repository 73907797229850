import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {GrClose} from 'react-icons/gr';
import {colors, images} from '../../common';
import {allCommonText, plans} from '../../common/constants';
import {getClaimDetailsById} from '../../utils/rest-services';
import './styles.scss';
import {Loader} from '..';
import {planCategory} from '../../pages/user-profile-section/policy-and-claims';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export const ClaimDetails = ({show, handelClose, claimId}) => {
  const [loader, setLoader] = useState(true);
  const [claimsData, setClaimsData] = useState(null);
  const [claimDocList, setclaimDocList] = useState([]);
  useEffect(() => {
    fetchClaimDetails();
  }, []);

  const fetchClaimDetails = async () => {
    setLoader(true);
    let response = await getClaimDetailsById(claimId);

    if (response && response.data.success) {
      const {
        data: {
          content: {claim, claimDocs},
        },
      } = response;
      setClaimsData(claim);
      setclaimDocList(claimDocs);
    }
    setLoader(false);
  };

  const planSelectHandler = id => {
    switch (id) {
      case allCommonText.serviceTypeEnums.vehicle:
        return {
          image: images.vehicleV,
          title: plans.vehicle.split(' ')[1],
        };
      case allCommonText.serviceTypeEnums.home:
        return {image: images.homeV, title: plans.home.split(' ')[1]};
      case allCommonText.serviceTypeEnums.device:
        return {image: images.devicesV, title: plans.devices.split(' ')[1]};
      case allCommonText.serviceTypeEnums.commercial:
        return {
          image: images.commercialV,
          title: plans.commercial.split(' ')[1],
        };
      case allCommonText.serviceTypeEnums.disaster:
        return {
          image: images.disasterIcon,
          title: plans.disaster.split(' ')[1],
        };
    }
  };
  return (
    <Modal centered show={show} onHide={handelClose}>
      <Modal.Body className="modal-body-invoice">
        {loader ? (
          <div className="loader-container-invoice">
            <Loader />
          </div>
        ) : (
          <>
            <div className="claim-detail-container">
              <span>{allCommonText.claimDetails.claimDetails}</span>
              <GrClose
                className="add-payment-close-btn"
                onClick={handelClose}
                size={15}
                color={colors.primary}
              />
            </div>
            <div className="claim-details-info mt-3">
              <div className="claim-title">
                <div className="claim-icon">
                  <img
                    src={planSelectHandler(claimsData.serviceTypeId).image}
                    alt={allCommonText.icon}
                  />
                  <span>
                    {planSelectHandler(claimsData.serviceTypeId).title}
                  </span>
                </div>
                <span
                  class={`${planCategory(claimsData.claimStatus)} badge-style`}>
                  {claimsData.claimStatus}
                </span>
              </div>
              <div className="claim-content-info">
                <div className="claim-content">
                  <span>{allCommonText.claimDetails.claimNumber}</span>
                  <span
                    data-tooltip-id={`claim-id-tooltip`}
                    data-tooltip-content={`Copy to Clipboard`}
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      navigator.clipboard.writeText(claimsData.viewClaimId);
                    }}>
                    {claimsData.viewClaimId}
                  </span>
                </div>
                <div className="claim-content">
                  <span>{allCommonText.claimDetails.claimPolicyID}</span>
                  <span
                    data-tooltip-id={`claim-id-tooltip`}
                    data-tooltip-content={`Copy to Clipboard`}
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      navigator.clipboard.writeText(claimsData.cartItemId);
                    }}>
                    {claimsData.cartItemId}
                  </span>
                  <Tooltip id={`claim-id-tooltip`} />
                </div>
                <div className="claim-content">
                  <span>{allCommonText.claimDetails.provider}</span>
                  <span>{claimsData.providerName}</span>
                </div>
                {/* <div className="claim-content">
                  <span>{allCommonText.claimDetails.dateLoss}</span>
                  <span>{claimsData.dateLoss.split(' ')[0]}</span>
                </div> */}
                <div className="claim-content">
                  <span>{allCommonText.claimDetails.dateFile}</span>
                  <span>{claimsData.dateFiled.split(' ')[0]}</span>
                </div>
                {claimsData.claimStatus !=
                  allCommonText.policyClaims.pending && (
                  <>
                    <div className="claim-content">
                      <span>
                        {`Date ${claimsData.claimStatus}`}
                        {/* {claimsData.claimStatus ==
                    allCommonText.policyClaims.pending
                      ? allCommonText.policyClaims.settlledDate
                      : allCommonText.claimDetails.rejectedDate} */}
                      </span>
                      <span>
                        {/* {claimsData.claimStatus ==
                    allCommonText.policyClaims.pending
                      ? claimsData.dateSettled == ''
                        ? 'N/A'
                        : claimsData.dateSettled
                      : claimsData.dateFiled} */}
                        {claimsData.dateSettled == ''
                          ? 'N/A'
                          : claimsData.dateSettled}
                      </span>
                    </div>
                  </>
                )}
                <div className="claim-content">
                  <span class="fw-bold ">
                    {allCommonText.claimDetails.claimAmount}
                  </span>
                  <span class="fw-bold">
                    {`$${parseFloat(claimsData.claimAmount).toFixed(2)}`}
                  </span>
                </div>
              </div>
            </div>
            <div className="attachment-claim-details user-notes mt-3">
              <span>{allCommonText.claimDetails.userNotes}</span>
              <span>{claimsData.userNotes}</span>
            </div>
            <div className="attachment-claim-details mt-3">
              {/* {false && ( // for future use for claims docs list
                <>
                  <span>{allCommonText.claimDetails.attachmentImages}</span>
                  <div className="attachment-images-container">
                    {attachmentArr?.map((item, index) => {
                      return (
                        <div className="attachment-images" key={index}>
                          <img src={item.image} alt={allCommonText.image} />
                        </div>
                      );
                    })}
                  </div>
                </>
              )} */}
              {claimDocList && claimDocList.length > 0 && (
                <div className="attachment-pdf-detail mt-3">
                  <span>{allCommonText.claimDetails.attachmentPdf}</span>
                  <div className="attachment-pdf-container">
                    {claimDocList?.map((item, index) => {
                      return (
                        <a
                          href={item.documentPath}
                          target="_blank"
                          rel="noreferrer"
                          className="attachment-pdf-info"
                          key={index}>
                          <img src={images.pdfIcon} alt={allCommonText.icon} />
                          <span>{item.documentPath.split('\\')[2]}</span>
                        </a>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
