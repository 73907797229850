import React, {useState} from 'react';
import {getFormattedDate, planCategory, serviceTypeIcon} from '..';
import {allCommonText} from '../../../../common/constants';
import {useNavigate} from 'react-router';
import {
  ClaimDetails,
  CustomizedSearch,
  Loader,
  PolicyCard,
} from '../../../../components';
import {path} from '../../../../common/routesNames';

export function Claims({
  claimsList,
  claimSearchHandler,
  pageLoader,
  btnLoader,
  planType,
  status,
}) {
  const [claimDetailPopup, setClaimDetailPopup] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const navigate = useNavigate();

  const dateTitleManager = id => {
    // debugger
    switch (id) {
      case allCommonText.claimStatusId.settled:
        return allCommonText.policyClaims.settlledDate;

      case allCommonText.claimStatusId.cancelled:
        return allCommonText.policyClaims.cancelledDate;

      case allCommonText.claimStatusId.rejected:
        return allCommonText.policyClaims.rejectedDate;
    }
  };

  //  case claimStatus === "Rejected" :
  //     return allCommonText.policyClaims.rejectedDate;
  return (
    <>
      <div className="main-claim-container">
        <CustomizedSearch
          firstLabel={allCommonText.labels.claimNumber}
          firstPlaceholder={`${allCommonText.labels.enter} ${allCommonText.placeholders.claimNumber}`}
          firstPrefixValue={'clm_'}
          secondBox
          secondLabel={allCommonText.labels.claimPolicy}
          secondPlaceholder={` Claim ${allCommonText.placeholders.policyIDNumber}`}
          thirdLabel={allCommonText.labels.viewByStatus}
          firstDropdownValue={planType} //plan type
          secondDropdownValue={status} // status
          status={[
            allCommonText.policyClaims.all,
            allCommonText.policyClaims.pending,
            allCommonText.policyClaims.settled,
            allCommonText.policyClaims.cancelled,
            allCommonText.policyClaims.rejected,
          ]}
          subscriptionType
          subscriptionTypeLabel={'Plan Type'}
          subscriptionStatus={[
            allCommonText.policyClaims.all,
            allCommonText.policyClaims.auto,
            allCommonText.policyClaims.home,
            allCommonText.policyClaims.mobile,
            allCommonText.policyClaims.commercial,
          ]}
          btnLoader={btnLoader}
          title={allCommonText.heading.searchClaim}
          onClearFilterHandler={() => {
            claimSearchHandler('', '', '', '', true);
          }}
          onSearchBtnPress={(primary, secondry, type, status) => {
            claimSearchHandler(primary, secondry, type, status);
          }}
        />
        {pageLoader ? (
          <div className="no-policy-claim-container">
            <Loader size={40} />
          </div>
        ) : claimsList?.length > 0 ? (
          claimsList.map((item, index) => {
            const {
              serviceType,
              serviceTypeId,
              providerName,
              claimStatus,
              claimAmount,
              claimStatusId,
              claimId,
              dateFiled,
              dateLoss,
              dateSettled,
              cartItemId,
              viewClaimId,
            } = item || {};
            return (
              <PolicyCard
                key={index}
                cardType={allCommonText.viewCardType.invoice}
                onDetailBtnPress={() => {
                  setSelectedId(claimId);
                  // setClaimDetailPopup(true);
                  navigate({
                    pathname: path.planClaimDetails,
                    search: `?id=${claimId}`,
                  });
                }}
                planIconTitle={
                  <div className="plan-name">
                    {serviceTypeIcon(serviceTypeId)}
                    <span className="description-title">{serviceType}</span>
                  </div>
                }
                badge={
                  <span class={planCategory(claimStatus)}>{claimStatus}</span>
                }
                cost={
                  <>
                    <span className="fw-bold">
                      {allCommonText.policyClaims.amount}
                      <span>{`$${parseFloat(claimAmount).toFixed(2)}`}</span>
                    </span>
                  </>
                }
                LeftTopDetails={
                  <div className="details-item">
                    <span>{allCommonText.placeholders.claimNumber}</span>
                    <span>
                      {viewClaimId == ''
                        ? allCommonText.policyClaims.notAvailable
                        : viewClaimId}
                    </span>
                  </div>
                }
                LeftBottomDetails={
                  <div className="details-item">
                    <span>{allCommonText.policyClaims.claimID}</span>
                    <span>
                      {cartItemId === ''
                        ? allCommonText.policyClaims.notAvailable
                        : cartItemId}
                    </span>
                  </div>
                }
                rightTopDetails={
                  <div className="details-item">
                    <span>{allCommonText.policyClaims.dateLoss}</span>
                    <span>
                      {dateLoss === ''
                        ? allCommonText.policyClaims.notAvailable
                        : getFormattedDate(dateLoss)}
                    </span>
                  </div>
                }
                rigthBottomDetails={
                  <div className="details-item">
                    <span>{allCommonText.policyClaims.fileDate}</span>
                    <span>
                      {dateFiled === ''
                        ? allCommonText.policyClaims.notAvailable
                        : getFormattedDate(dateFiled)}
                    </span>
                  </div>
                }
                lastLeftBottomDetails={
                  <div className="details-item">
                    <span>{allCommonText.policyClaims.provider}</span>
                    <span>
                      {providerName === ''
                        ? allCommonText.policyClaims.notAvailable
                        : providerName}
                    </span>
                  </div>
                }
                lastBottomDetails={
                  claimStatus == allCommonText.policyClaims.pending ? (
                    <div className="details-item extra-card"></div>
                  ) : (
                    <div className="details-item">
                      <span>{dateTitleManager(claimStatusId)}</span>
                      <span>
                        {dateSettled == ''
                          ? allCommonText.policyClaims.notAvailable
                          : dateSettled}
                      </span>
                    </div>
                  )
                }
              />
            );
          })
        ) : (
          !pageLoader && (
            <div className="no-subscription-container">
              <span>{allCommonText.labels.noClaim}</span>
            </div>
          )
        )}
        {claimDetailPopup && (
          <ClaimDetails
            claimId={selectedId}
            show={claimDetailPopup}
            handelClose={() => {
              setClaimDetailPopup(false);
            }}
          />
        )}
      </div>
    </>
  );
}
