import React, {useEffect, useState} from 'react';
import {Grid} from '@mui/material';
import {
  TextInputField,
  TextAreaFeild,
  GenericModal,
} from '../../../../components';
import {allCommonText} from '../../../../common/constants';
import {ChooseFile} from '../../../../components/choose-file';
import {GenericButton} from './../../../../components/generic-button/index';
import {useNavigate} from 'react-router';
import {useLocation} from 'react-router-dom';
import {
  addClaimWithoutAutoScheme,
  claimTypeScehma,
} from '../../../../common/schemas';
import {Formik} from 'formik';
import './styles.scss';
import {addNewClaims} from '../../../../utils/rest-services';
import {path} from '../../../../common/routesNames';

const uploadFileKeys = {
  driverLicense: 'driverLicense',
  registrationDoc: 'registrationDoc',
  insuranceDeclaration: 'insuranceDeclaration',
  claimInfo: 'claimInfo',
  incidentReport: 'incidentReport',
  other: 'other',
};

const AddNewPolicyScreen = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const [claimFiles, setClaimFiles] = useState({
    [`${uploadFileKeys.driverLicense}`]: [],
    [`${uploadFileKeys.registrationDoc}`]: [],
    [`${uploadFileKeys.insuranceDeclaration}`]: [],
    [`${uploadFileKeys.claimInfo}`]: [],
    [`${uploadFileKeys.incidentReport}`]: [],
    [`${uploadFileKeys.other}`]: [],
  });
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpDetails, setPopUpDetails] = useState({
    type: allCommonText.popupEnums.success,
    title: allCommonText.success,
    body: allCommonText.popupEnums.claimSuccess,
  });
  const [totalDocumentCount, setTotalDocumentCount] = useState(0);
  useEffect(() => {
    let count = 0;
    Object.entries(claimFiles).forEach(entry => {
      let tempClaims = entry[1];
      if (tempClaims.length > 0) {
        count++;
      }
    });
    setTotalDocumentCount(count);
  }, [claimFiles]);

  // Note:Data is being Transferd through params
  const getParamsFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  let query = getParamsFromUrl();
  const serviceprovider = query.get('serviceprovider');
  const serviceType = query.get('serviceType');
  const amount = query.get('amount');
  const serviceProviderId = query.get('serviceProviderId');
  const cartItemId = query.get('cartItemId');

  const fileArrayAppendHandler = (key, file, formData) => {
    if (file.length === 0 || !file) {
      formData.append(key, null);
    } else {
      file.map(item => {
        formData.append(key, item);
      });
    }
    return formData;
  };
  const generateFormData = (values, files) => {
    let formData = new FormData();
    for (const [key, value] of Object.entries(values)) {
      formData.append(key, value);
    }
    for (const [key, value] of Object.entries(files)) {
      fileArrayAppendHandler(key, value, formData);
    }
    return formData;
  };
  const addClaimHandler = async (values, Formik) => {
    let licenseFiles =
      claimFiles[`${uploadFileKeys.driverLicense}`].length > 0 &&
      claimFiles[`${uploadFileKeys.driverLicense}`];
    let registerDocFile =
      claimFiles[`${uploadFileKeys.registrationDoc}`].length > 0 &&
      claimFiles[`${uploadFileKeys.registrationDoc}`];
    let insuranceDeclarationFile =
      claimFiles[`${uploadFileKeys.insuranceDeclaration}`].length > 0 &&
      claimFiles[`${uploadFileKeys.insuranceDeclaration}`];
    let claimInfoFile =
      claimFiles[`${uploadFileKeys.claimInfo}`].length > 0 &&
      claimFiles[`${uploadFileKeys.claimInfo}`];
    let incidentReportFile =
      claimFiles[`${uploadFileKeys.incidentReport}`].length > 0 &&
      claimFiles[`${uploadFileKeys.incidentReport}`];
    let otherFile =
      claimFiles[`${uploadFileKeys.other}`].length > 0 &&
      claimFiles[`${uploadFileKeys.other}`];
    const {notes, dateLoss, dateField} = values;
    const value = {
      DateLoss: dateLoss,
      DateFiled: dateField,
      CartItemId: cartItemId,
      UserNotes: notes,
      ClaimAmount: amount,
      ServiceProviderId: serviceProviderId,
      ServiceProviderName: serviceprovider,
    };
    const userFiles = {
      DriverLicense: licenseFiles,
      RegisterDoc: registerDocFile,
      InsuranceDoc: insuranceDeclarationFile,
      ClaimNoDoc: claimInfoFile,
      AccidentReport: incidentReportFile,
      OtherDoc: otherFile,
    };
    let payloadObj = generateFormData(value, userFiles);
    try {
      Formik.setSubmitting(true);
      let response = await addNewClaims(payloadObj);
      setPopUpDetails({
        type: response.data.success
          ? allCommonText.popupEnums.success
          : allCommonText.popupEnums.error,
        title: response.data.success
          ? allCommonText.success
          : allCommonText.error,
        body: response.data.description,
      });
      setIsPopUpVisible(true);
    } catch (error) {
      alert(error);
    } finally {
      Formik.setSubmitting(false);
    }
  };
  const onFileAdded = (file, keyName) => {
    const tempState = {...claimFiles};
    tempState[keyName].push(file);
    setClaimFiles(tempState);
  };
  const onFileRemove = (file, keyName, setFieldValue) => {
    let tempState = {...claimFiles};
    const filesList = tempState[keyName] || [];
    let index = filesList.findIndex(e => e == file);
    if (filesList.length == 1 && index == 0) {
      setFieldValue(keyName, '');
    }
    index != -1 && filesList.splice(index, 1);
    tempState[keyName] = filesList || [];
    setClaimFiles(tempState);
  };
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  return (
    <div className="container add-new-policy-container">
      <Formik
        initialValues={{
          dateLoss: date,
          dateField: date,
          notes: '',
          driverLicense: '',
          registrationDoc: '',
          insuranceDeclaration: '',
          claimInfo: '',
          incidentReport: '',
        }}
        validateOnMount={true}
        onSubmit={(values, formik) => {
          addClaimHandler(values, formik);
        }}
        validationSchema={
          serviceType === 'Auto' ? claimTypeScehma : addClaimWithoutAutoScheme
        }
        enableReinitialize>
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          errors,
          values,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <label htmlFor="/">
                  {allCommonText.policyClaims.claimType}
                </label>
                <span className="profileEmail">
                  <TextInputField disable={true} value={serviceType} />
                </span>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <label htmlFor="/">
                  {allCommonText.policyClaims.claimAmount}
                </label>
                <span className="profileEmail">
                  <TextInputField disable={true} value={amount} />
                </span>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <label htmlFor="/">{allCommonText.policyClaims.provider}</label>
                <span className="profileEmail">
                  <TextInputField disable={true} value={serviceprovider} />
                </span>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <label htmlFor="/">{allCommonText.policyClaims.dateLoss}</label>
                <TextInputField
                  type="date"
                  id="dateLoss"
                  name="dateLoss"
                  value={values.dateLoss}
                  max={
                    values.dateField || new Date().toISOString().split('T')[0]
                  }
                  setValue={handleChange('dateLoss')}
                  handleBlur={handleBlur('dateLoss')}
                  error={touched.dateLoss && errors.dateLoss}
                  onKeyDown={e => {
                    e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <label htmlFor="/">
                  {allCommonText.policyClaims.dateField}
                </label>
                <TextInputField
                  type="date"
                  id="dateField"
                  name="dateField"
                  value={values.dateField}
                  // min={new Date().toISOString().split('T')[0]}
                  max={new Date().toISOString().split('T')[0]}
                  setValue={e => {
                    const selectedDateField = new Date(e.target.value);
                    const currentDateLoss = new Date(values.dateLoss);
                    if (
                      currentDateLoss &&
                      selectedDateField < currentDateLoss
                    ) {
                      setFieldValue('dateLoss', ''); // Clear dateLoss if dateField is less
                    }
                    handleChange('dateField');
                    setFieldValue('dateField', e.target.value);
                  }}
                  handleBlur={handleBlur('dateField')}
                  error={touched.dateField && errors.dateField}
                  onKeyDown={e => {
                    e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <label htmlFor="/">{allCommonText.policyClaims.notes}</label>
                <div className="textArea-add-policy">
                  <TextAreaFeild
                    id="txtname"
                    name="txtname"
                    value={values.notes}
                    setValue={handleChange('notes')}
                    handleBlur={handleBlur}
                    error={touched.notes && errors.notes}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="attachments">
                  <p>{`${allCommonText.policyClaims.attachments} (${totalDocumentCount}/6)*`}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="choose-file-box ">
                  <ChooseFile
                    uploadTitle={'Upload Driver license'}
                    note={
                      'Note: Please upload driver license front and back picture'
                    }
                    keyName={'license'}
                    files={claimFiles[uploadFileKeys.driverLicense]}
                    onRemoveFileHandler={item => {
                      onFileRemove(
                        item,
                        uploadFileKeys.driverLicense,
                        setFieldValue,
                      );
                    }}
                    onChangeHandler={file => {
                      onFileAdded(file, uploadFileKeys.driverLicense);
                      setFieldValue(uploadFileKeys.driverLicense, file);
                    }}
                    error={touched.driverLicense && errors.driverLicense}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="choose-file-box ">
                  <ChooseFile
                    uploadTitle={'Upload Registration Doc'}
                    note={''}
                    keyName={'registration'}
                    files={claimFiles[uploadFileKeys.registrationDoc]}
                    onRemoveFileHandler={item => {
                      onFileRemove(
                        item,
                        uploadFileKeys.registrationDoc,
                        setFieldValue,
                      );
                    }}
                    onChangeHandler={file => {
                      onFileAdded(file, uploadFileKeys.registrationDoc);
                      setFieldValue(uploadFileKeys.registrationDoc, file);
                    }}
                    error={touched.registrationDoc && errors.registrationDoc}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="choose-file-box ">
                  <ChooseFile
                    uploadTitle={'Upload Insurance Declaration Pages'}
                    note={''}
                    files={claimFiles[uploadFileKeys.insuranceDeclaration]}
                    onRemoveFileHandler={item => {
                      onFileRemove(
                        item,
                        uploadFileKeys.insuranceDeclaration,
                        setFieldValue,
                      );
                    }}
                    onChangeHandler={file => {
                      onFileAdded(file, uploadFileKeys.insuranceDeclaration);
                      setFieldValue(uploadFileKeys.insuranceDeclaration, file);
                    }}
                    error={
                      touched.insuranceDeclaration &&
                      errors.insuranceDeclaration
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="choose-file-box ">
                  <ChooseFile
                    uploadTitle={'Upload Claim Info'}
                    note={''}
                    files={claimFiles[uploadFileKeys.claimInfo]}
                    onRemoveFileHandler={item => {
                      onFileRemove(
                        item,
                        uploadFileKeys.claimInfo,
                        setFieldValue,
                      );
                    }}
                    onChangeHandler={file => {
                      onFileAdded(file, uploadFileKeys.claimInfo);
                      setFieldValue(uploadFileKeys.claimInfo, file);
                    }}
                    error={touched.claimInfo && errors.claimInfo}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="choose-file-box ">
                  <ChooseFile
                    uploadTitle={'Upload Incident Report'}
                    note={''}
                    files={claimFiles[uploadFileKeys.incidentReport]}
                    onRemoveFileHandler={item => {
                      onFileRemove(
                        item,
                        uploadFileKeys.incidentReport,
                        setFieldValue,
                      );
                    }}
                    onChangeHandler={file => {
                      onFileAdded(file, uploadFileKeys.incidentReport);
                      setFieldValue(uploadFileKeys.incidentReport, file);
                    }}
                    error={touched.incidentReport && errors.incidentReport}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className="choose-file-box ">
                  <ChooseFile
                    uploadTitle={'Others / Misc'}
                    note={''}
                    files={claimFiles[uploadFileKeys.other]}
                    onRemoveFileHandler={item => {
                      onFileRemove(item, uploadFileKeys.other);
                    }}
                    onChangeHandler={file => {
                      onFileAdded(file, uploadFileKeys.other);
                    }}
                  />
                </div>
              </Grid>
              <div className="policy-save-button">
                <GenericButton
                  id="Add-policy-save-btn"
                  buttonText={allCommonText.profile.save}
                  loading={isSubmitting}
                  onPress={() => {
                    handleSubmit();
                  }}
                />
              </div>
            </Grid>
          </>
        )}
      </Formik>
      <GenericModal
        show={isPopUpVisible}
        btnText={allCommonText.ok}
        type={popUpDetails.type}
        title={popUpDetails.title}
        body={popUpDetails.body}
        handleClose={() => {
          popUpDetails.type === allCommonText.popupEnums.success &&
            navigate(path.policyAndClaims);
          setIsPopUpVisible(false);
        }}
      />
    </div>
  );
};

export default AddNewPolicyScreen;
