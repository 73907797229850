export const images = {
  noDukLogo: 'images/noDukLogo.svg',
  footerBackgroundImage: 'images/bg-footer1.png',
  footer12: 'images/bg-footer12.png',
  footer13: 'images/bg-footer13.png',
  download1: 'images/download1.jpg',
  download2: 'images/insurance.jpg',
  download3: 'images/eliminateDeductibles.jpg',
  download4: 'images/sayNoDeductibles.jpg',
  download5: 'images/flood.jpg',
  download6: 'images/mobile.jpg',
  download7: 'images/nodukFooterLogo.jpg',
  download8: 'images/restaurant.jpg',
  download9: 'images/sayNoDeductibles.jpg',
  download10: 'images/carEliminate.jpg',
  download11: 'images/carFlood.jpg',
  thanks: 'images/thankyou.png',
  errorIcon: 'images/error-icon.png',
  signUpMobile: 'images/signup-mob.jpg',
  iosDownload: 'images/ios-download.svg',
  androidDownload: 'images/android-download.svg',
  infoIcon: 'images/info-icon.svg',
  vehicle: 'images/vehicleSVG.svg',
  mobileVerification: 'images/mobile-verification.svg',
  devices: 'images/devices.svg',
  home: 'images/homeSVG.svg',
  commercial: 'images/commercialSVG.svg',
  vehicleV: 'images/vehicleIconV.svg',
  homeV: 'images/homeVIcon.svg',
  diasasterV: 'images/disaster.svg',
  diasasterR: 'images/disaster-red.svg',
  devicesV: 'images/devicesVIcon.svg',
  commercialV: 'images/commercialVIcon.svg',
  disasterIcon: 'images/DisasterIcon.svg',
  eyeOpen: 'images/eye-open.svg',
  eyeClose: 'images/eye-close.svg',
  background: 'images/login-image.png',
  error: 'images/error.svg',
  success: 'images/success.svg',
  noCardAvailable: 'images/no-card-available.png',
  alert: 'images/alert.svg',
  trash: 'images/trash.svg',
  sure: 'images/sure.svg',
  upgradeSubscription: 'images/upgrade-subs.svg',
  lockIcon: 'images/lockIcon.svg',
  emptyCartIcon: 'images/emtyCartIcon.svg',
  profileDummyImage: 'images/profileDummyImage.svg',
  visa: 'images/visa.svg',
  master: 'images/master.svg',
  cartSmall: 'images/cartIcon.svg',
  logoutIcon: 'images/logoutIcon.svg',
  profilesIcon: 'images/profilesIcon.svg',
  userIcon: 'images/userIcon.svg',
  policyClaim: 'images/policyClaimIcon.svg',
  invoicePayment: 'images/invoicePaymentIcon.svg',
  subscription: 'images/subscriptionIcon.svg',
  billingCard: 'images/billingCardIcon.svg',
  pdfIcon: 'images/pdfIcon.svg',
  licenseFront: 'images/licenseFront.svg',
  licenseBack: 'images/licenseBack.svg',
  viewCart: 'images/viewCart.svg',
  changePassword: 'images/ChangePassword.svg',
  coverageVehicle: 'images/coverageVehicle.svg',
  coverageHome: 'images/coverageHome.svg',
  coverageCommercial: 'images/coverageCommercial.svg',
  coverageDevice: 'images/coverageDevice.svg',
  dashboardIcon: 'images/dashBoardIcon.svg',
  notification: 'images/notification.svg',
  refund: 'images/refund.svg',
  headerCart: 'images/newCartIcon.svg',
  // headerCart: 'images/headerCart.svg',
  headerNotify: 'images/headerNotify.svg',
  uploadNewImage: 'images/UploadNewImage.svg',
  highRiskBadge: 'images/highRiskBadge.svg',
  lowRiskBadge: 'images/low-risk-badge.svg',
  mediumRiskBadge: 'images/medium-risk-badge.svg',
  nonRiskBadge: 'images/NonRiskBadge.svg',
  veryBad: 'images/veryBad.svg',
  veryGood: 'images/veryGood.svg',
  ok: 'images/ok.svg',
  good: 'images/good.svg',
  excellent: 'images/excelence.svg',
  redirectIcon: 'images/redirectPageIcon.svg',
  attachment: 'images/attachments.svg',
  union: 'images/union.svg',
  frame: 'images/frame.svg',
  newNodukLogo: 'images/newNodukLogo.png',
  xicon: 'images/XIcon.svg',
};
