import React, {useState} from 'react';
import ApplicationContext from './context';

const ContextProvider = ({
  children,
  notificationCount,
  setNotificationCount,
  notificationsList,
  setNotificationsList,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showModal, setSowModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [cartCount, setCartCount] = useState('');
  const [popUpResponse, setPopUpResponse] = useState({
    type: 1,
    title: '',
    description: '',
  });
  const [isAccountButton, setIsAccountButton] = useState(false);
  const [isOpenPane, setIsOpenPane] = useState({
    isPaneOpen: false,
  });
  const [selectedTab, setSelectedTab] = useState(0);
  // const [notificationCount, setNotificationCount] = useState(0);
  return (
    <ApplicationContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        showModal,
        setSowModal,
        popUpResponse,
        setPopUpResponse,
        selectedCard,
        setSelectedCard,
        cartCount,
        setCartCount,
        isAccountButton,
        setIsAccountButton,
        isOpenPane,
        setIsOpenPane,
        selectedTab,
        setSelectedTab,
        notificationCount,
        setNotificationCount,
        notificationsList,
        setNotificationsList,
      }}>
      {children}
    </ApplicationContext.Provider>
  );
};

export default ContextProvider;
