import React, {useContext, useEffect} from 'react';
import {IoIosLogOut} from 'react-icons/io';
import {CiUser} from 'react-icons/ci';
import {Grid} from '@mui/material';

import {FaUser} from 'react-icons/fa';

import {HiOutlineChartBarSquare} from 'react-icons/hi2';
import {images} from '../../common/images';
import {allCommonText, header} from '../../common/constants';
import {CartSlider} from '../cart-slider';
import ApplicationContext from '../../utils/context-api/context';
import {getCartCount, removeDeviceTokenAPI} from '../../utils/rest-services';
import {useLocation, useNavigate} from 'react-router';
import {
  pagesWithoutAccountButton,
  pagesWithoutHeader,
  path,
} from '../../common/routesNames';
import {
  clearStorage,
  getDeviceToken_,
  getToken,
} from '../../utils/localstorage';
import './style.scss';
import {publicPaths} from '../../common/routesNames';

export const Header = () => {
  const {setSelectedTab} = useContext(ApplicationContext);
  const isLoggedIn = getToken() !== '';
  let navigate = useNavigate();
  const {
    cartCount,
    setCartCount,
    notificationCount,
    isAccountButton,
    setIsAccountButton,
  } = useContext(ApplicationContext);
  const location = useLocation();
  const pagePath = location.pathname;

  useEffect(() => {
    if (cartCount == '' && pagesWithoutHeader.indexOf(pagePath) == -1) {
      fetchCartCount();
    }
  }, [isLoggedIn]);
  const performLogout = async () => {
    await removeDeviceToken();
    clearStorage();
    navigate(path.login, {replace: true});
  };

  const removeDeviceToken = async () => {
    const token = getDeviceToken_();
    await removeDeviceTokenAPI(token);
  };

  const fetchCartCount = async () => {
    let response = await getCartCount();
    if (response && response.success) {
      const {content} = response;
      setCartCount(content);
    }
  };

  return (
    <div className="headerBox">
      <Grid container spacing={0}>
        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="upper-navbar-Welcometext">
            <p className="upper-navbar">
              <span>Welcome to </span>
              <span className="upper-navbar-noduktext">NO DUK! </span>
              <span>The First Deductible Service Plan!</span>
            </p>
            <p className="upper-navbar-conatct">
              <a class="nodukmail" href={allCommonText.urls.phone}>
                <span className="conatct-number-outerspan">
                  <span className="phoneSign">
                    <FaPhoneAlt size={15} />
                  </span>
                  <span className="contact-number">1-855-663-8599</span>
                </span>
              </a>
              <a class="nodukmail" href={allCommonText.urls.newHome}>
                <span className="mailsection">
                  <span className="mailSign">
                    <TiMail size={25} />
                  </span>
                  <span>info@noduk.com</span>
                </span>
              </a>
            </p>
          </div>
          <hr width="100%" size="2" className="line" />
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
              <div className="log-box">
                <div
                  className="logo"
                  onClick={() => {
                    navigate(path.plans, {replace: true});
                  }}
                  style={{
                    backgroundImage: `url("${images.newNodukLogo}")`,
                    height: '95px',
                  }}></div>
              </div>
              <div className="sliderButton-smallScreens-cart">
                {pagesWithoutHeader.indexOf(pagePath) == -1 && (
                  <li>
                    <CartSlider />
                  </li>
                )}
              </div>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <a
                      class=""
                      href={allCommonText.deductablePlans.deductableNoduk}>
                      <span className="linkLables">
                        {header.deductiblePlans}
                      </span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="marginClass"
                      href={allCommonText.aboutUs.aboutNoduk}>
                      <span className="linkLables">{header.aboutUS}</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="" href={allCommonText.contactUs.contactNoduk}>
                      <span className="linkLables">{header.contactUs}</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="" href={allCommonText.joinTheTeam.jointeamNoduk}>
                      <span className="linkLables">{header.joinTheTeam}</span>
                    </a>
                  </li>
                  {publicPaths.indexOf(pagePath) !== -1 && (
                    <li>
                      <button
                        className="signUpbutton"
                        onClick={() => {
                          navigate(path.signup);
                        }}>
                        Sign Up
                      </button>
                    </li>
                  )}
                  {pagesWithoutAccountButton.indexOf(pagePath) == -1 && (
                    <div className="sliderButton-largeScreen-cart">
                      <li>
                        <div
                          onClick={() => {
                            navigate(path.notifications);
                          }}
                          className="notificationBtn">
                          <span>
                            <img src={images.headerNotify} />
                          </span>
                          {notificationCount && notificationCount > 0 ? (
                            <div className="notify-count-header">
                              <span>
                                {notificationCount === 0
                                  ? ''
                                  : `${
                                      notificationCount >= 100
                                        ? '99+'
                                        : `${notificationCount}`
                                    }`}
                              </span>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </li>
                    </div>
                  )}
                  <div
                    className="sliderButton-largeScreen-cart"
                    onClick={() => {
                      setIsAccountButton(false);
                    }}>
                    {pagesWithoutHeader.indexOf(pagePath) == -1 && (
                      <li>
                        <CartSlider />
                      </li>
                    )}
                  </div>
                  {
                    <div className="account-button">
                      {pagesWithoutAccountButton.indexOf(pagePath) == -1 && (
                        <button
                          type="button"
                          className="accountButton"
                          onClick={() => {
                            setIsAccountButton(!isAccountButton);
                          }}>
                          <span>
                            <FaUser />
                          </span>
                          <span>{header.myAccount}</span>
                        </button>
                      )}

                      {isAccountButton && (
                        <div class="dropdown-account">
                          <div
                            className="dropdown-account-content"
                            onClick={() => {
                              navigate({pathname: path.dashboard});
                              setIsAccountButton(false);
                              setSelectedTab(0);
                            }}>
                            <span>
                              <HiOutlineChartBarSquare size={20} />
                            </span>
                            <span>{allCommonText.dashboard.dashboard}</span>
                          </div>
                          <div
                            className="dropdown-account-content"
                            onClick={() => {
                              navigate({pathname: path.profile});
                              setIsAccountButton(false);
                              setSelectedTab(6);
                            }}>
                            <span>
                              <CiUser size={20} />
                            </span>
                            <span>{header.profile}</span>
                          </div>
                          <div
                            className="dropdown-account-content"
                            onClick={() => {
                              performLogout();
                              setIsAccountButton(false);
                            }}>
                            <span>
                              <IoIosLogOut size={20} className="iconsTester" />
                            </span>
                            <span>{header.logout}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  }
                </ul>
              </div>
            </div>
          </nav>
        </Grid>
      </Grid>
    </div>
  );
};
