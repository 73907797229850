import React, {useEffect, useState} from 'react';
import './styles.scss';
import '../../checkout/style.scss';
import {AiFillCheckCircle} from 'react-icons/ai';
import {colors, images} from '../../../common';
import {allCommonText} from '../../../common/constants';
import {Grid} from '@mui/material';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import {GenericButton, Loader, GenericModal} from '../../../components';
import {
  getPlansSubscription,
  upgradeSubscriptionplans,
} from '../../../utils/rest-services';
import {useNavigate} from 'react-router';

const {
  popupEnums: {plansType},
} = allCommonText;

export const getPlanBadgColors = key => {
  switch (key) {
    case 1:
      return {background: 'monthly-background', boder: 'monthly-border'};
    case 2:
      return {background: 'biAnnual-background', border: 'biAnnual-border'};
    case 3:
      return {background: 'annual-background ', border: 'annual-border'};
    default:
      return {background: 'annual-background ', border: 'annual-border'};
  }
};
export const PlanCard = ({
  title,
  infoOne,
  price,
  check,
  isDisable,
  totalAmount,
  onCardPress,
  planType,
  planPrice,
  balance,
  planAmount,
  isActivePlan,
  currentPlan,
  discount,
  canSubscribe,
}) => {
  const showUpgradedPrice = !isActivePlan && !isDisable;

  const getSubscriptionItemContent = () => {
    if (showUpgradedPrice) {
      return (
        <div className="plan-price-container">
          <img
            data-tooltip-id={`upgrade-subscription-tooltip`}
            data-tooltip-content={`Your current invoice is $${currentPlan} If you purchase today it will be subscribed immediately.`}
            src={images.infoIcon}
          />
          <Tooltip id={`upgrade-subscription-tooltip`} />
          <span className="title">Upgrade For: </span>
          <span className="offer-price">
            <span className="cross-price"> {`$${planAmount}`}</span>
            <span className="price"> {`$${totalAmount}`}</span>
          </span>
        </div>
      );
    } else if (!isActivePlan && isDisable) {
      return (
        <div className="plan-price-container">
          <span className="title">Plan Price: </span>
          <span className="price">{`$${planAmount}`}</span>
        </div>
      );
    } else {
      return (
        <div className="plan-price-container">
          <span className="title">Current Plan: </span>
          <span className="price">{`$${totalAmount}`}</span>
        </div>
      );
    }
  };

  return (
    <div
      onClick={onCardPress}
      className={
        check
          ? `plan-card selected-card-border ${
              getPlanBadgColors(planType).border
            }`
          : isDisable
          ? 'plan-card upgrade-plan-disable'
          : 'plan-card'
      }>
      <div className={`plan-title ${getPlanBadgColors(planType).background}`}>
        <span>{title}</span>
      </div>
      <div className="p-2 sub-card-wrap">
        <div className="card-info flex-row">
          <AiFillCheckCircle color={colors.green} size={20} />
          <span>{infoOne}</span>
        </div>
        {!isActivePlan && !isDisable && (
          <div className="card-info">
            <span>{`Plan Price: $${planPrice}`}</span>
            <span>{`Your Balance: $${balance}`}</span>
          </div>
        )}
        {isActivePlan && !canSubscribe && discount != 0 && discount != '' && (
          <div className="card-info">
            <span>{`Plan Price: $${planPrice}`}</span>
            <span>{`Discount: ${discount}`}</span>
          </div>
        )}
        {totalAmount >= 0 && (
          <div className="card-info">{getSubscriptionItemContent()}</div>
        )}
      </div>
      <div className="radio-container">
        <input
          class="form-check-input"
          type="radio"
          id="flexRadioDefault2"
          name="radio-group-2"
          value={`name-${price}`}
          checked={check}></input>
      </div>
    </div>
  );
};

function UpgradeSubscriptionPlan() {
  const [pageLoader, setPageLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [suscriptionPlans, setSuscriptionPlans] = useState({
    monthly: null,
    biannual: null,
    annual: null,
  });
  const [currentPlan, setCurrentPlan] = useState({});
  const [selectedPlan, setSelectedPlan] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [popUpResponse, setPopUpResponse] = useState(null);
  const navigate = useNavigate();

  // Location
  const getParamsFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  let query = getParamsFromUrl();
  const subscriptionId = query.get('id');
  const stripeSubscriptionId = query.get('eId');

  useEffect(() => {
    fetchCustomerSubscriptions(subscriptionId);
  }, []);

  const fetchCustomerSubscriptions = async subscriptionId => {
    setPageLoader(true);
    let response = await getPlansSubscription(subscriptionId);
    if (response && response.success) {
      setPageLoader(false);
      const plans = response?.content;
      setSuscriptionPlans({
        monthly: plans.find(i => i.planId == 1),
        biannual: plans.find(i => i.planId == 2),
        annual: plans.find(i => i.planId == 3),
      });
      setCurrentPlan(plans.find(i => i.planActive));
      setSelectedPlan(plans.find(i => i.planActive)?.planId);
    }
  };
  const upgradeSubscriptionHandler = async () => {
    setBtnLoader(true);
    let response = await upgradeSubscriptionplans({
      planId: selectedPlan,
      subscriptionId: subscriptionId,
      stripeSubId: stripeSubscriptionId,
    });
    if (response && response.success) {
      setBtnLoader(false);
      setPopUpResponse({
        type: 1,
        title: 'Success',
        details: response.description,
      });
    } else {
      setPopUpResponse({
        type: 2,
        title: 'Error',
        details: response.description,
      });
    }
    setShowModal(true);
  };

  return (
    <div className="main-subscription-container sub-detailt-pad upgrade-subpage">
      {pageLoader ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : (
        <>
          <div className="sub-detail-title">
            <h3>{'Upgrade Subscription Plan'}</h3>
            <div className="upgrade-subscription-lables">
              <div>{allCommonText.upgradeTitle}</div>
              <span>{allCommonText.upgradeDetails}</span>
            </div>
          </div>
          <div className="upgrade-detail-container">
            <div className="cards-container mt-2">
              <Grid container spacing={3}>
                <Grid item xl={4} lg={4} md={4} xs={12}>
                  <div className="card-container ">
                    <PlanCard
                      isActivePlan={currentPlan.planId === 1}
                      onCardPress={() => {
                        if (!(1 < currentPlan?.planId)) setSelectedPlan(1);
                      }}
                      check={selectedPlan === 1}
                      isDisable={1 < currentPlan?.planId}
                      title={plansType.monthly}
                      infoOne={allCommonText.labels.renews.monthly}
                      planPrice={suscriptionPlans?.monthly?.planAmount}
                      balance={suscriptionPlans?.monthly?.prorationCost}
                      totalAmount={suscriptionPlans?.monthly?.totalAmount}
                      planAmount={suscriptionPlans?.monthly?.planAmount}
                      key={1}
                      planType={1}
                      currentPlan={currentPlan?.totalAmount}
                      discount={suscriptionPlans.monthly?.discount}
                      isPlanDiscount={suscriptionPlans.monthly?.isPlanDiscount}
                      canSubscribe={suscriptionPlans.monthly?.canSubscribe}
                    />
                  </div>
                </Grid>
                <Grid item xl={4} lg={4} md={4} xs={12}>
                  <div className="card-container ">
                    <PlanCard
                      isActivePlan={currentPlan.planId === 2}
                      onCardPress={() => {
                        if (!(2 < currentPlan?.planId)) setSelectedPlan(2);
                      }}
                      check={selectedPlan === 2}
                      isDisable={2 < currentPlan?.planId}
                      title={plansType.biAnnual}
                      infoOne={allCommonText.labels.renews.biannual}
                      planPrice={suscriptionPlans.biannual?.planAmount}
                      balance={suscriptionPlans.biannual?.prorationCost}
                      totalAmount={suscriptionPlans?.biannual?.totalAmount}
                      planAmount={suscriptionPlans?.biannual?.planAmount}
                      key={1}
                      planType={2}
                      currentPlan={currentPlan?.totalAmount}
                      discount={suscriptionPlans.biannual?.discount}
                      isPlanDiscount={suscriptionPlans.biannual?.isPlanDiscount}
                      canSubscribe={suscriptionPlans.biannual?.canSubscribe}
                    />
                  </div>
                </Grid>
                <Grid item xl={4} lg={4} md={4} xs={12}>
                  <div className="card-container ">
                    <PlanCard
                      isActivePlan={currentPlan.planId === 3}
                      onCardPress={() => {
                        if (!(3 < currentPlan?.planId)) setSelectedPlan(3);
                      }}
                      check={selectedPlan === 3}
                      isDisable={3 < currentPlan?.planId}
                      title={plansType.annual}
                      infoOne={allCommonText.labels.renews.yearly}
                      planPrice={suscriptionPlans?.annual?.planAmount}
                      balance={suscriptionPlans?.annual?.prorationCost}
                      totalAmount={suscriptionPlans.annual?.totalAmount}
                      planAmount={suscriptionPlans?.annual?.planAmount}
                      key={1}
                      planType={3}
                      currentPlan={currentPlan?.totalAmount}
                      discount={suscriptionPlans.annual?.discount}
                      isPlanDiscount={suscriptionPlans.annual?.isPlanDiscount}
                      canSubscribe={suscriptionPlans.annual?.canSubscribe}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="upgrade-note">
              {currentPlan.planId === 3 ? (
                <>
                  <span className="fw-bold">{'Note:'}</span>
                  <span>{`You have already purchased annual plan. You cannot further upgrade your plan.`}</span>
                </>
              ) : (
                <span>{''}</span>
              )}
            </div>
            <div className="buttom-subscription-container">
              <GenericButton
                onPress={() => {
                  upgradeSubscriptionHandler();
                }}
                buttonText={'Upgrade'}
                loading={btnLoader}
                disable={selectedPlan == currentPlan.planId}
              />
              <GenericButton
                buttonText={'Back'}
                onPress={() => {
                  navigate(-1);
                }}
                grayButtonSecondary
              />
            </div>
          </div>
        </>
      )}

      {showModal && (
        <GenericModal
          show={showModal}
          btnText={allCommonText.ok}
          type={popUpResponse.type}
          body={popUpResponse.details}
          handleClose={() => {
            navigate(-1);
            setShowModal(false);
          }}
        />
      )}
    </div>
  );
}

export default UpgradeSubscriptionPlan;
