import React, {useState, useEffect} from 'react';
import './style.scss';
import Grid from '@mui/material/Grid';
import {
  GenericButton,
  GenericModal,
  InputField,
  MobileVerificationPopup,
} from '../../components';
import {images} from '../../common/images';
import {allCommonText, signinText} from '../../common/constants';
import {Formik} from 'formik';
import {signinSchema} from '../../common/schemas';
import {useNavigate} from 'react-router-dom';
import {path} from '../../common/routesNames';
import {performLogin} from '../../utils/rest-services/index';
import {setProfile, setToken, setDeviceToken_} from '../../utils/localstorage';
import {getToken} from 'firebase/messaging';
import {messaging} from '../../firebase';

function Login() {
  const initialValues = {
    email: '',
    password: '',
  };
  const {login, forgotPassword, createAccount} = signinText;
  const [btnLoading, setBnLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [userToken, setUserToken] = useState('');
  const [customerData, setCustomerData] = useState({});
  const [phoneNumber, setPhoneNumber] = useState('');
  const [deviceToken, setDeviceToken] = useState('');
  const [loginResponse, setLoginResponse] = useState({
    type: allCommonText.popupEnums.success,
    title: '',
    details: '',
  });
  let navigate = useNavigate();

  const requestNotifications = async () => {
    let permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPI_KEY,
      });
      setDeviceToken(token);
      setDeviceToken_(token);
    } else if (permission === 'denied') {
      alert('you can not be able to get notifications.');
    }
  };
  useEffect(() => {
    requestNotifications();
  }, []);

  const saveUserData = (token, customer) => {
    setToken(`bearer ${token}`);
    setProfile(customer);
    navigate(path.dashboard, {replace: true});
  };
  const requestLogin = async (values, formik) => {
    setBnLoading(true);
    const userPayload = {
      email: values.email,
      password: values.password,
      deviceToken: deviceToken,
      deviceType: 'web',
    };
    let result = await performLogin(userPayload);
    const {
      data: {success, content, description},
    } = result || {};
    if (result && success) {
      const {token, customer} = content;
      setCustomerData(customer);
      if (!customer.isPhoneConfirmed) {
        setIsMobileVerified(true);
        setUserToken(token);
        setPhoneNumber(
          customer.phone.replace(/^(\+1)/, '').replace(/^(1)/, ''),
        );
      } else {
        saveUserData(token, customer);
        formik.resetForm();
      }
    } else {
      setLoginResponse({
        type: allCommonText.popupEnums.error,
        title: allCommonText.error,
        details: description,
      });
      setShowModal(true);
    }

    setBnLoading(false);
  };
  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={true}
      onSubmit={(values, formikActions) => {
        requestLogin(values, formikActions);
      }}
      validationSchema={signinSchema}
      enableReinitialize>
      {({handleChange, handleBlur, handleSubmit, touched, errors}) => (
        <div>
          <Grid container spacing={0}>
            <Grid item xs={12} xl={5.5} lg={5.5} md={5.5}>
              <div className="login-section">
                <div className="login-fields-container">
                  <span className="login-text">
                    Log into your <span className="redTxt">NO DUK</span> account
                  </span>
                  <InputField
                    name={'email'}
                    id={'email'}
                    placeholder={'Email'}
                    error={errors.email}
                    touched={touched.email}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <InputField
                    name={'password'}
                    id={'password'}
                    placeholder={'Password'}
                    error={errors.password}
                    touched={touched.password}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isPassword
                  />
                  <span
                    onClick={() => {
                      navigate({
                        pathname: path.forgotPassword,
                      });
                    }}
                    className="forgot-pass-txt">
                    {forgotPassword}
                  </span>
                  <GenericButton
                    id="Login-btn"
                    loading={btnLoading}
                    onPress={handleSubmit}
                    buttonText={login}
                  />
                  <span
                    onClick={() => {
                      navigate({
                        pathname: path.signup,
                      });
                    }}
                    className="create-account">
                    {createAccount}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} xl={6.5} lg={6.5} md={6.5}>
              <div
                style={{
                  backgroundImage: `url(${images.background})`,
                }}
                className="image-section"></div>
            </Grid>
          </Grid>
          <GenericModal
            show={showModal}
            btnText={allCommonText.ok}
            type={loginResponse.type}
            body={loginResponse.details}
            handleClose={() => {
              setShowModal(false);
            }}
          />
          {isMobileVerified && (
            <MobileVerificationPopup
              number={phoneNumber}
              userToken={userToken}
              show={isMobileVerified}
              handleClose={isLogin => {
                if (isLogin == true) {
                  saveUserData(userToken, customerData);
                }
                setIsMobileVerified(false);
              }}
            />
          )}
        </div>
      )}
    </Formik>
  );
}

export default Login;
