import React, {useState} from 'react';
import './styles.scss';
import Grid from '@mui/material/Grid';
import {GenericButton, GenericModal, InputField} from '../../components';
import {images} from '../../common/images';
import {allCommonText} from '../../common/constants';
import {Formik} from 'formik';
import {forgotPasswordSchema} from '../../common/schemas';
import {useNavigate} from 'react-router-dom';
import {path} from '../../common/routesNames';
import {requestForgotPassword} from '../../utils/rest-services';

const ForgotPassword = () => {
  const [btnLoading, setBnLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [apiResponse, setApiResponse] = useState({
    type: allCommonText.popupEnums.success,
    title: '',
    details: '',
  });
  let navigate = useNavigate();

  const forgotPasswordHandler = async values => {
    setBnLoading(true);

    let response = await requestForgotPassword(values.email);
    setBnLoading(false);
    if (response && response.success) {
      setApiResponse({
        type: allCommonText.popupEnums.success,
        details: response.description,
      });
      setShowModal(true);
    } else {
      setApiResponse({
        type: allCommonText.popupEnums.error,
        details: response.description,
      });
      setShowModal(true);
    }
  };
  return (
    <Formik
      initialValues={{email: ''}}
      validateOnMount={true}
      onSubmit={(values, formikActions) => {
        forgotPasswordHandler(values, formikActions);
      }}
      validationSchema={forgotPasswordSchema}
      enableReinitialize>
      {({handleChange, handleBlur, handleSubmit, touched, errors}) => (
        <div>
          <Grid container spacing={0}>
            <Grid item xs={12} xl={5.5} lg={5.5} md={5.5}>
              <div className="login-section">
                <div className="login-fields-container">
                  <span className="login-text">
                    {allCommonText.forgotPassword.forgotPassword}
                  </span>
                  <p>{allCommonText.forgotPassword.forgotMessage}</p>
                  <InputField
                    name={'email'}
                    id={'email'}
                    placeholder={'Email'}
                    error={errors.email}
                    touched={touched.email}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <GenericButton
                    id="Forgot-password-btn"
                    loading={btnLoading}
                    onPress={handleSubmit}
                    buttonText={allCommonText.forgotPassword.send}
                  />
                  <span
                    onClick={() => {
                      navigate({
                        pathname: path.login,
                      });
                    }}
                    className="create-account">
                    {allCommonText.forgotPassword.backToLogin}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} xl={6.5} lg={6.5} md={6.5}>
              <div
                style={{
                  backgroundImage: `url(${images.background})`,
                }}
                className="image-section"></div>
            </Grid>
          </Grid>
          <GenericModal
            show={showModal}
            btnText={allCommonText.ok}
            type={apiResponse.type}
            body={apiResponse.details}
            handleClose={() => {
              setShowModal(false);
            }}
          />
        </div>
      )}
    </Formik>
  );
};

export default ForgotPassword;
