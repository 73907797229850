import {CircularProgress} from '@mui/material';
import React from 'react';
import './style.scss';

export function GenericButton({
  loading,
  onPress,
  buttonText,
  disable,
  grayButton,
  grayButtonSecondary,
  id,
}) {
  return (
    <div className="generic-btn-container" id={id}>
      <button
        type="button"
        disabled={loading || disable}
        className={
          loading || disable || grayButton ? 'disable-generic-btn' : grayButtonSecondary ? "disable-generic-btn-secondary" : ""
        }
        onClick={onPress}>
        {loading ? <CircularProgress size={20} color="inherit" /> : buttonText}
      </button>
    </div>
  );
}
