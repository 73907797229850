import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {
  errorPageRoute,
  pagesRoutes,
  path,
  publicPageRoutes,
} from './common/routesNames';
import PublicRoute from './routes/publiceRoutes';
import PrivateRoute from './routes/privateRoute';
import './App.scss';

const BaseRoutes = () => {
  const availableRoutes = Object.values(pagesRoutes);
  const availablePublicRoutes = Object.values(publicPageRoutes);
  const ErrorRoute = Object.values(errorPageRoute);

  return (
    <Routes>
      {availableRoutes.map(
        ({component: Component, name, path, subPage, ...rest}) => {
          return (
            <Route
              path={path}
              key={`route-${name}`}
              element={
                <PrivateRoute>
                  <Component
                    SubPage={subPage != null ? subPage : null}
                    {...rest}
                  />
                </PrivateRoute>
              }
              {...rest}
            />
          );
        },
      )}
      {availablePublicRoutes.map(({component: Component, name, path}) => {
        return (
          <Route
            path={path}
            key={`route-${name}`}
            element={
              <PublicRoute>
                <Component />
              </PublicRoute>
            }
          />
        );
      })}
      {ErrorRoute.map(({component: Component, name, path}) => {
        return (
          <Route path={path} key={`route-${name}`} element={<Component />} />
        );
      })}
      <Route path="*" element={<Navigate to={path.dashboard} />} />
    </Routes>
  );
};
export default BaseRoutes;
