import React, {useEffect} from 'react';
import {useNavigate} from 'react-router';
import {images} from '../../common';
import {allCommonText} from '../../common/constants';
import {requestVerifyEmail} from '../../utils/rest-services';
import './styles.scss';
import {path} from '../../common/routesNames';
import {useLocation} from 'react-router';

const EmailConfirmation = () => {
  const location = useLocation();

  const getParamsFromUrl = () => {
    return new URLSearchParams(location.search);
  };

  let query = getParamsFromUrl();

  const token = query.get('ec');

  useEffect(() => {
    verifyEmail();
  }, []);

  const verifyEmail = async () => {
    await requestVerifyEmail(token);
  };
  let navigate = useNavigate();
  return (
    <div className="emailConfirmationContainer">
      <img src={images.thanks} />
      <span>
        {allCommonText.emailVerifications}
        <span
          onClick={() => {
            navigate({
              pathname: path.login,
            });
          }}
          className="noduk-email-confirmation">
          {allCommonText.nodukPortal}
        </span>
      </span>
      <span>{allCommonText.thanks}</span>
    </div>
  );
};

export default EmailConfirmation;
