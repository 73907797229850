import React from 'react';
import {MdOutlineManageSearch} from 'react-icons/md';
import {allCommonText} from '../../common/constants';
import './styles.scss';

export const PolicyCard = ({
  planIconTitle,
  badge,
  LeftTopDetails,
  LeftBottomDetails,
  rightTopDetails,
  cost,
  rigthBottomDetails,
  onDetailBtnPress,
  cardType,
  lastBottomDetails,
  lastLeftBottomDetails,
}) => {
  return (
    <div className="main-description-box">
      <div className="detail-invoice-container">
        <div className="description-header">
          <div className="description-planIcon"> {planIconTitle}</div>
          <div className="description-badge">{badge}</div>
          <div className="cost-action">{cost}</div>
        </div>
        <div className="description-details">
          {LeftTopDetails}
          {LeftBottomDetails}
        </div>
        <div className="description-details">
          {rightTopDetails}
          {rigthBottomDetails}
        </div>
        {
          <div className="description-details">
            {lastLeftBottomDetails}
            {lastBottomDetails}
          </div>
        }
      </div>
      <div class="middle-btn-invoice">
        {(cardType == allCommonText.viewCardType.subscription ||
          cardType === allCommonText.viewCardType.invoice ||
          cardType === allCommonText.viewCardType.refund) && (
          <div onClick={onDetailBtnPress} class="view-invoice-btn">
            <MdOutlineManageSearch size={25} />
            <span>{allCommonText.buttonTexts.viewDetails}</span>
          </div>
        )}
      </div>
    </div>
  );
};
