import React, {useState, useEffect} from 'react';
import './styles.scss';
import {NotificationItem, Loader} from '../../../components';
import {allCommonText} from '../../../common/constants';
import {getAllNotifications} from '../../../utils/rest-services';
import {toast} from 'react-toastify';
import {useContext} from 'react';
import ApplicationContext from '../../../utils/context-api/context';

export default function Notification() {
  const {setNotificationCount, notificationsList, setNotificationsList} =
    useContext(ApplicationContext);
  const [pageLoader, setPageLoader] = useState(true);

  useEffect(() => {
    fetchCustomerNotifications();
  }, []);

  const fetchCustomerNotifications = async () => {
    setPageLoader(true);
    let response = await getAllNotifications();

    if (response && response.success) {
      const content = response.content;
      if (content && content?.length > 0) {
        setNotificationCount(content.filter(item => !item.isRead).length);
        setNotificationsList(content);
      } else {
        setNotificationsList([]);
      }
      setPageLoader(false);
    } else {
      setPageLoader(false);
      toast.error(response?.data?.description || allCommonText.somethingWrong, {
        autoClose: false,
      });
    }
  };

  return (
    <div className="main-notification-container">
      <h3>{allCommonText.profile.notifications}</h3>
      <hr />
      {pageLoader ? (
        <div className="no-subscription-container">
          <Loader size={40} />
        </div>
      ) : notificationsList?.length > 0 ? (
        notificationsList?.map((i, index) => (
          <NotificationItem key={index} notify={i} />
        ))
      ) : (
        !pageLoader && (
          <div className="no-subscription-container">
            <span>{allCommonText.labels.noNotification}</span>
          </div>
        )
      )}
      {/* <NotificationItem type="invoice" title="Upcoming Invoice" text="You have an upcoming invoice inv_782 of amount $400 is planned on 06/05/20" date="12:00:00 AM" />
            <NotificationItem type="" title="" text="" date="12:00:00 AM" /> */}
    </div>
  );
}
