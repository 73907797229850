import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import {GenericButton} from '../generic-button';
import {allCommonText} from '../../common/constants';
import {GrClose} from 'react-icons/gr';
import {colors} from '../../common';
import './style.scss';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {addNewPaymentCard} from '../../utils/rest-services';
import {toast} from 'react-toastify';

export function AddNewBillingMethod({show, handleClose}) {
  const [btnLoader, setBtnLoader] = useState(false);
  const [cardName, setCardName] = useState('');
  const [stripeError, setStripeError] = useState({
    cardName: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  });

  const addNewCardHandler = async payload => {
    let response = await addNewPaymentCard(payload);
    if (response && response.data.success) {
      setBtnLoader(false);
      handleClose(response.data.success);
      toast.success(response?.data?.description || 'Something Went Wrong!');
    } else {
      setBtnLoader(false);
      toast.error(response?.data?.description || 'Something Went Wrong!');
    }
  };

  const handleCardFieldChange = (event, name) => {
    if (event.error) {
      setStripeError(other => ({other, [name]: event.error.message}));
    } else {
      setStripeError(other => ({other, [name]: null}));
    }
  };
  const stripe = useStripe();
  const elements = useElements();

  const CreateCardStripe = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const cardNumber = elements.getElement(CardNumberElement);
    const cardExpiry = elements.getElement(CardExpiryElement);
    const cardCvc = elements.getElement(CardCvcElement);
    const isValidCard = cardNumber._empty || cardNumber._invalid;
    const isValidExpiry = cardExpiry._empty || cardExpiry._invalid;
    const isValidCvc = cardCvc._empty || cardCvc._invalid;

    if (isValidCard || isValidExpiry || isValidCvc || cardName == '') {
      setStripeError(other => ({
        other,
        cardName: cardName == '' ? allCommonText.errorMessage.cardName : null,
        cardNumber: isValidCard && allCommonText.errorMessage.cardNumber,
        cardExpiry: isValidExpiry && allCommonText.errorMessage.cardExpiry,
        cardCvc: isValidCvc && allCommonText.errorMessage.cardCvc,
      }));
      return;
    }
    setBtnLoader(true);
    const {error, token} = await stripe.createToken(
      elements.getElement(CardNumberElement),
    );
    if (error) {
      alert(error.message || '');
      setBtnLoader(false);
    } else {
      const {paymentMethod, error} = await stripe.createPaymentMethod({
        type: 'card',
        card: {
          token: token.id,
        },
      });
      if (paymentMethod) {
        const addNewCardPayload = {
          paymentMethodId: paymentMethod.id,
          nameOnCard: cardName,
        };
        addNewCardHandler(addNewCardPayload);
      } else if (error) {
        alert(error.message || '');
      }
    }
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="add-payment-popup-wrapper">
          <div className="top-add-payment-container">
            <span>{allCommonText.heading.newCardAdd} </span>
            <GrClose
              className="add-payment-close-btn"
              onClick={handleClose}
              size={25}
              color={colors.primary}
            />
          </div>
          <div className={`add-payment-main-content`}>
            <div className="add-card-option">
              <>
                <div className="holder-name-container">
                  <label>{allCommonText.labels.name} </label>
                  <input
                    placeholder={allCommonText.placeholders.enterHolderName}
                    type={'text'}
                    className="card-holder-name"
                    value={cardName}
                    onChange={e => {
                      const {value} = e.target;
                      const re = /^[A-Za-z ]+$/;
                      if (value === '' || re.test(value)) {
                        setCardName(value);
                        setStripeError(other => ({
                          other,
                          cardName: null,
                        }));
                      }
                    }}
                  />
                  {stripeError?.cardName != '' && (
                    <span className="error-field">{stripeError?.cardName}</span>
                  )}
                </div>
                <label>{allCommonText.labels.cardNumber} </label>
                <div className="card-number">
                  <CardNumberElement
                    onChange={i => handleCardFieldChange(i, 'cardNumber')}
                    required
                    options={{
                      showIcon: true,
                    }}
                  />
                </div>
                {stripeError?.cardNumber != '' && (
                  <span className="error-field">{stripeError?.cardNumber}</span>
                )}
              </>
              <div className="expiry-cvc-container">
                <div className="expiry-container">
                  <label>{allCommonText.labels.expiry} </label>
                  <div className="card-expiry">
                    <CardExpiryElement
                      onChange={i => handleCardFieldChange(i, 'cardExpiry')}
                      required
                    />
                  </div>
                  {stripeError?.cardExpiry != '' && (
                    <span className="error-field">
                      {stripeError?.cardExpiry}
                    </span>
                  )}
                </div>
                <div className="cvv-container">
                  <label>{allCommonText.labels.cvv} </label>
                  <div className="card-cvc">
                    <CardCvcElement
                      onChange={i => handleCardFieldChange(i, 'cardCvc')}
                      required
                    />
                  </div>
                  {stripeError?.cardCvc != '' && (
                    <span className="error-field">{stripeError?.cardCvc}</span>
                  )}
                </div>
              </div>

              <div className="save-btn-container-card">
                <GenericButton
                  id="save-btn"
                  buttonText={allCommonText.buttonTexts.addCard_}
                  onPress={CreateCardStripe}
                  loading={btnLoader}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
