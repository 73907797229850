import React, {useState, useEffect, useContext} from 'react';
import {Grid} from '@mui/material';
import {allCommonText, plans} from '../../common/constants';
import '../../App.scss';
import {
  GenericModal,
  AddVehicleInputs,
  AddHomeInputs,
  AddDevicesInputs,
  AddCommercialsInputs,
} from '../../components';
import {
  getCarrierMakerByType,
  getDeductibleList,
  getCartItemById,
  getCartCount,
} from '../../utils/rest-services';
import ApplicationContext from '../../utils/context-api/context';
import {images} from '../../common/images';
import {useLocation, useNavigate} from 'react-router';
import {path} from '../../common/routesNames';

const PlansSector = () => {
  const [selectedId, setSelectedId] = useState(1);
  const [makerItem, setMakerItem] = useState([]);
  const [carrierItem, setCarrierItem] = useState([]);
  const [deductibleItem, setDeductibleItem] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedCartItems, setSelectedCartItems] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const {
    popUpResponse,
    setPopUpResponse,
    showModal,
    setSowModal,
    setCartCount,
    setIsAccountButton,
  } = useContext(ApplicationContext);
  let navigate = useNavigate();

  const location = useLocation();
  const getParamsFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  const fetchCartCount = async () => {
    let response = await getCartCount();
    if (response && response.success) {
      const {content} = response;
      setCartCount(content);
    }
  };
  useEffect(() => {
    retreiveCarrierWithType();
    fetchDeductibles();
    setSelectedCartItems({});
  }, [selectedId]);

  useEffect(() => {
    fetchIdsFromURL();
    fetchCartCount();
  }, [location]);

  const fetchIdsFromURL = () => {
    let query = getParamsFromUrl();
    const cartItemId = query.get('cartItemId');
    const serviceTypeId = query.get('serviceTypeId');
    if (serviceTypeId != '' && serviceTypeId != null) {
      setSelectedId(parseInt(serviceTypeId));
    }
    if (cartItemId != '' && serviceTypeId != null) {
      fetchCartItemById(cartItemId);
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  };

  const fetchCartItemById = async id => {
    setLoadingData(true);
    let response = await getCartItemById(id);
    if (response && response.success) {
      setLoadingData(false);
      setSelectedCartItems(response.content);
    }
  };

  const retreiveCarrierWithType = async () => {
    const responseData = await getCarrierMakerByType(selectedId);
    if (responseData && responseData.success) {
      const {
        content: {carriers, makers},
      } = responseData;
      setMakerItem(makers || []);
      setCarrierItem(carriers || {});
    }
  };

  const fetchDeductibles = async () => {
    let response = await getDeductibleList();
    if (response && response.success) {
      const {content} = response;
      setDeductibleItem(content || {});
    }
  };

  const planSelectHandler = id => {
    switch (id) {
      case allCommonText.serviceTypeEnums.vehicle:
        return (
          <AddVehicleInputs
            makerItem={makerItem}
            carrierItem={carrierItem}
            deductibleItem={deductibleItem}
            setPopUpResponse={setPopUpResponse}
            setSowModal={setSowModal}
            selectedCartItems={selectedCartItems}
            loadingData={loadingData}
            onItemAddedToCart={fetchCartCount}
          />
        );
      case allCommonText.serviceTypeEnums.home:
        return (
          <AddHomeInputs
            carrierItem={carrierItem}
            deductibleItem={deductibleItem}
            setPopUpResponse={setPopUpResponse}
            setSowModal={setSowModal}
            selectedItems={selectedCartItems}
            selectedCartItems={selectedCartItems}
            loadingData={loadingData}
            onItemAddedToCart={fetchCartCount}
          />
        );
      case allCommonText.serviceTypeEnums.device:
        return (
          <AddDevicesInputs
            carrierItem={carrierItem}
            deductibleItem={deductibleItem}
            setPopUpResponse={setPopUpResponse}
            setSowModal={setSowModal}
            selectedCartItems={selectedCartItems}
            loadingData={loadingData}
            onItemAddedToCart={fetchCartCount}
          />
        );
      case allCommonText.serviceTypeEnums.commercial:
        return (
          <AddCommercialsInputs
            carrierItem={carrierItem}
            deductibleItem={deductibleItem}
            setPopUpResponse={setPopUpResponse}
            setSowModal={setSowModal}
            selectedCartItems={selectedCartItems}
            loadingData={loadingData}
            onItemAddedToCart={fetchCartCount}
          />
        );
    }
  };
  const planSelectTitleHandler = id => {
    switch (id) {
      case allCommonText.serviceTypeEnums.vehicle:
        return isEditMode
          ? `${allCommonText.buttonTexts.edit} ${plans.addVehicle}`
          : `${allCommonText.buttonTexts.add} ${plans.addVehicle}`;
      case allCommonText.serviceTypeEnums.home:
        return isEditMode
          ? `${allCommonText.buttonTexts.edit} ${plans.addHome}`
          : `${allCommonText.buttonTexts.add} ${plans.addHome}`;
      case allCommonText.serviceTypeEnums.device:
        return isEditMode
          ? `${allCommonText.buttonTexts.edit} ${plans.addDevice}`
          : `${allCommonText.buttonTexts.add} ${plans.addDevice}`;
      case allCommonText.serviceTypeEnums.commercial:
        return isEditMode
          ? `${allCommonText.buttonTexts.edit} ${plans.addCommercial}`
          : `${allCommonText.buttonTexts.add} ${plans.addCommercial}`;
    }
  };
  const GenericPlanOptions = ({
    genericStyle,
    plansIcon,
    planTitle,
    planToggler,
    selectedPlanId,
    topBtn,
  }) => {
    return (
      <div className={genericStyle}>
        {topBtn && (
          <div
            onClick={planToggler}
            className={
              selectedPlanId === selectedId
                ? 'leftPrimaryElement selctedleftPrimaryElement'
                : 'leftPrimaryElement'
            }>
            <div
              className={
                selectedPlanId !== selectedId
                  ? 'plansIcon'
                  : 'plansIcon selectedPlanIcon'
              }>
              <span
                className={
                  selectedPlanId !== selectedId ? 'commonImage' : 'selected'
                }>
                <img src={plansIcon} alt={plans.vehicleIcon} />
              </span>
            </div>
            <div className="plansLabel">
              <span>{planTitle}</span>
            </div>
          </div>
        )}
        {selectedId === selectedPlanId && (
          <div className="leftSecondaryElement">
            <div className="leftElementTitle">
              <span>{planSelectTitleHandler(selectedPlanId)}</span>
              {planSelectHandler(selectedPlanId)}
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <div
      className="mainpageLayout"
      onClick={() => {
        setIsAccountButton(false);
      }}>
      <div className="plansContent">
        <h1>{plans.selectPlans}</h1>
        <div className="plansSelectorBox">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <div className="left-Child">
                <GenericPlanOptions
                  genericStyle={`leftChildGenericContainer`}
                  topBtn
                  planTitle={plans.vehicle}
                  selectedPlanId={allCommonText.serviceTypeEnums.vehicle}
                  plansIcon={images.vehicleV}
                  planToggler={() => {
                    setSelectedId(allCommonText.serviceTypeEnums.vehicle);
                    navigate({
                      pathname: path.plans,
                    });
                  }}
                />
                <GenericPlanOptions
                  genericStyle={`leftChildGenericContainer`}
                  planTitle={plans.home}
                  topBtn
                  selectedPlanId={allCommonText.serviceTypeEnums.home}
                  plansIcon={images.homeV}
                  planToggler={() => {
                    setSelectedId(allCommonText.serviceTypeEnums.home);
                    navigate({
                      pathname: path.plans,
                    });
                  }}
                />
                <GenericPlanOptions
                  genericStyle={`leftChildGenericContainer`}
                  planTitle={plans.devices}
                  topBtn
                  selectedPlanId={allCommonText.serviceTypeEnums.device}
                  plansIcon={images.devicesV}
                  planToggler={() => {
                    setSelectedId(allCommonText.serviceTypeEnums.device);
                    navigate({
                      pathname: path.plans,
                    });
                  }}
                />
                <GenericPlanOptions
                  genericStyle={`leftChildGenericContainer`}
                  planTitle={plans.commercial}
                  topBtn
                  plansIcon={images.commercialV}
                  selectedPlanId={allCommonText.serviceTypeEnums.commercial}
                  planToggler={() => {
                    setSelectedId(allCommonText.serviceTypeEnums.commercial);
                    navigate({
                      pathname: path.plans,
                    });
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
              <GenericPlanOptions
                selectedPlanId={selectedId}
                genericStyle={`rightChildGenericContainer`}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <GenericModal
        show={showModal}
        btnText={allCommonText.ok}
        type={popUpResponse.type}
        body={popUpResponse.description}
        handleClose={() => {
          setSowModal(false);
          setSelectedCartItems({});
        }}
      />
    </div>
  );
};
export default PlansSector;
