import React from 'react';
import {render} from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import AppJs from './App';

export const App = () => {
  // Sentry.init({
  //   dsn: 'https://dd902be5e6194504b0583d4d71111018@o4504922606796800.ingest.sentry.io/4504922671742976',
  //   integrations: [new BrowserTracing()],
  //   tracesSampleRate: 1.0,
  // });
  return (
    <>
      <div>
        <React.StrictMode>
          <BrowserRouter>
            <AppJs />
          </BrowserRouter>
        </React.StrictMode>
      </div>
    </>
  );
};

render(<App />, document.getElementById('root'));
