import React, {useContext, useEffect} from 'react';
import {Grid} from '@mui/material';
import {allCommonText} from '../../common/constants';
import {ProfileAccountSlider} from '../../components';
import {GenericModal} from '../../components';
import {useNavigate, useLocation} from 'react-router';
import './profile/styles.scss';
import ApplicationContext from './../../utils/context-api/context';
import {path} from '../../common/routesNames';
import './styles.scss';
import {IoMdNotificationsOutline} from 'react-icons/io';
import {RiDeleteBin5Line} from 'react-icons/ri';
import {HiOutlineEllipsisHorizontalCircle} from 'react-icons/hi2';

const UserProfileSections = ({SubPage, ...rest}) => {
  useEffect(() => {
    setSelectedTab(rest.selectedId);
  }, [rest]);

  const {
    setIsAccountButton,
    showModal,
    setSowModal,
    popUpResponse,
    selectedTab,
    setSelectedTab,
    notificationCount,
  } = useContext(ApplicationContext);

  let navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const tabIndex = navTabs.findIndex(item => item.path === location.pathname);

    if (tabIndex !== -1) {
      setSelectedTab(tabIndex);
    }
  }, [location.pathname, setSelectedTab]);

  const navTabs = [
    {
      path: path.dashboard,
      name: allCommonText.dashboard.dashboard,
      icon: <span class="icon-dashBoardIcon"></span>,
    },
    {
      path: path.policyAndClaims,
      name: allCommonText.profile.policyClaims,
      icon: <span class="icon-policyClaimIcon"></span>,
    },
    {
      path: path.invoicesAndPayments,
      name: allCommonText.profile.invoicesPayments,
      icon: <span class="icon-invoicePaymentIcon"></span>,
    },
    {
      path: path.subscriptions,
      name: allCommonText.profile.subscriptions,
      icon: <span class="icon-subscriptionIcon"></span>,
    },
    {
      path: path.refund,
      name: allCommonText.profile.refund,
      icon: <span class="icon-refund"></span>,
    },
    {
      path: path.billingCards,
      name: allCommonText.profile.billingCards,
      icon: <span class="icon-billingCardIcon"></span>,
    },
    {
      path: path.profile,
      name: allCommonText.profile.editProfile,
      icon: <span class="icon-userIcon"></span>,
    },
    {
      path: path.notifications,
      name: allCommonText.profile.notifications,
      icon: (
        <IoMdNotificationsOutline
          size={22}
          style={{marginRight: 7, marginBottom: 5}}
        />
      ),
    },
    {
      path: path.changePassword,
      name: allCommonText.profile.changePassword,
      icon: <span class="icon-Change-password"></span>,
    },
    {
      path: path.feedBack,
      name: allCommonText.profile.feedBack,
      icon: (
        <HiOutlineEllipsisHorizontalCircle
          size={22}
          style={{marginRight: 7, marginBottom: 5}}
        />
      ),
    },
    {
      path: path.deleteAccount,
      name: allCommonText.profile.deleteAccount,
      icon: (
        <RiDeleteBin5Line size={22} style={{marginRight: 7, marginBottom: 5}} />
      ),
    },
  ];
  return (
    <>
      <div
        className="profile-container"
        onClick={() => {
          setIsAccountButton(false);
        }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <div
              onClick={() => {
                navigate({pathname: path.plans});
              }}
              className="dashboard-btn-container">
              <span>{allCommonText.addNewPlans}</span>
            </div>
            <div className="profile-account-button">
              <ProfileAccountSlider />
            </div>
            <div className="profile-category">
              <ul>
                {navTabs.map((item, index) => {
                  const {path, name, icon} = item;
                  return (
                    <li
                      key={index}
                      className={selectedTab === index ? 'selectedTab' : ''}
                      onClick={() => {
                        navigate({pathname: path});
                        setSelectedTab(index);
                      }}>
                      {icon}
                      {name}
                      {item.name === allCommonText.profile.notifications &&
                        notificationCount > 0 && (
                          <span
                            className={
                              selectedTab === index
                                ? 'notify-count notf-selected '
                                : 'notify-count'
                            }>
                            {notificationCount}
                          </span>
                        )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
            <SubPage />
          </Grid>
        </Grid>
        <GenericModal
          show={showModal}
          btnText={allCommonText.ok}
          type={popUpResponse.type}
          body={popUpResponse.details}
          handleClose={() => {
            setSowModal(false);
          }}
        />
      </div>
    </>
  );
};

export default UserProfileSections;
