import React, {useState} from 'react';
import './styles.scss';
import {allCommonText} from '../../../common/constants';
import {GenericButton, GenericModal, TextAreaFeild} from '../../../components';
import {Formik} from 'formik';
import {FaStar} from 'react-icons/fa';
import {images} from '../../../common';
import {addFeedback} from '../../../utils/rest-services';

function FeedBack() {
  const [selectedId, setSelectedId] = useState(1);
  const [selectedStar, setSelectedStar] = useState(1);
  const [selectedImage, setSelectedImage] = useState(images.veryBad);
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setBtnLoader] = useState(false);
  const [popupResponse, setPopUpResponse] = useState({
    type: allCommonText.popupEnums.success,
    title: '',
    details: '',
  });

  const feedBackHandler = async (values, formik) => {
    setBtnLoader(true);
    formik.setSubmitting(false);
    const payload = {
      feedBackText: improvedTabText[selectedId],
      feedBackDetail: values.feedBackDetails,
      rating: selectedStar,
    };
    let response = await addFeedback(payload);

    if (response && response.data) {
      const {
        data: {success, description},
      } = response;
      if (success) {
        formik.resetForm();
        setPopUpResponse({
          type: allCommonText.popupEnums.success,
          details: 'Feedback added successfully',
        });
      } else {
        setPopUpResponse({
          type: allCommonText.popupEnums.error,
          details: description,
        });
      }
      setBtnLoader(false);
    }
    setShowAlert(true);
  };

  const handleStarClick = star => {
    setSelectedStar(star);
    setSelectedImage(getImageForStar(star));
  };

  const getImageForStar = star => {
    switch (star) {
      case 1:
        return images.veryBad;
      case 2:
        return images.ok;
      case 3:
        return images.good;
      case 4:
        return images.veryGood;
      case 5:
        return images.excellent;
      default:
        return images.veryBad;
    }
  };

  const improvedTabText = {
    1: allCommonText.feedback.overallPerfect,
    2: allCommonText.feedback.customerSupport,
    3: allCommonText.feedback.speedAndEfficiency,
    4: allCommonText.feedback.bug,
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        validateOnMount={true}
        initialValues={{feedBackDetails: ''}}
        onSubmit={(values, formik) => {
          feedBackHandler(values, formik);
          // console.log('values', values);
        }}>
        {({handleSubmit, setFieldValue, values}) => (
          <div className="main-feedback-container">
            <h3>{allCommonText.profile.feedBack}</h3>
            <hr />
            <div className="experience-container">
              <h3>{allCommonText.feedback.rate}</h3>
              <div className="experience-animated-box">
                <div className="star-box">
                  {[1, 2, 3, 4, 5].map(star => (
                    <span
                      key={star}
                      className={`star-${star}`}
                      onClick={() => handleStarClick(star)}>
                      <FaStar
                        size={30}
                        color={selectedStar >= star ? '#dd3333' : '#DCDCDC'}
                      />
                    </span>
                  ))}
                </div>
                <div className="experience-faces">
                  <div className="experience-face-container">
                    <img src={selectedImage} alt="Experience Face" />
                  </div>
                </div>
              </div>
              <div className="experience-improved-box">
                <p>{allCommonText.feedback.improved}</p>
                <div className="improved-tab">
                  <span
                    className={
                      selectedId === 1
                        ? 'improved-selected-tab'
                        : 'improved-unSelected-tab'
                    }
                    onClick={() => setSelectedId(1)}>
                    {allCommonText.feedback.overallPerfect}
                  </span>
                  <span
                    className={
                      selectedId === 2
                        ? 'improved-selected-tab'
                        : 'improved-unSelected-tab'
                    }
                    onClick={() => setSelectedId(2)}>
                    {allCommonText.feedback.customerSupport}
                  </span>

                  <span
                    className={
                      selectedId === 3
                        ? 'improved-selected-tab'
                        : 'improved-unSelected-tab'
                    }
                    onClick={() => setSelectedId(3)}>
                    {allCommonText.feedback.speedAndEfficiency}
                  </span>
                  <span
                    className={
                      selectedId === 4
                        ? 'improved-selected-tab'
                        : 'improved-unSelected-tab'
                    }
                    onClick={() => setSelectedId(4)}>
                    {allCommonText.feedback.bug}
                  </span>
                </div>
                <div className="feedbackContainer">
                  <TextAreaFeild
                    id="feedBackDetails"
                    name="feedBackDetails"
                    value={values.feedBackDetails}
                    setValue={e => {
                      setFieldValue('feedBackDetails', e.target.value);
                    }}
                    placeholder={allCommonText.feedback.shareFeedback}
                  />
                </div>
                <GenericButton
                  buttonText={allCommonText.feedback.submit}
                  onPress={handleSubmit}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>
      <GenericModal
        show={showAlert}
        type={popupResponse.type}
        body={popupResponse.details}
        handleClose={() => setShowAlert(false)}
        btnText={`OK`}
      />
    </>
  );
}

export default FeedBack;
