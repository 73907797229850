import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import {allCommonText} from '../../common/constants';
import {GrClose} from 'react-icons/gr';
import {colors} from '../../common';
import './style.scss';
import {getLinkedSubscriptions} from '../../utils/rest-services';
import {Loader} from '../loader';
import 'react-tooltip/dist/react-tooltip.css';
import {useNavigate} from 'react-router';
import {path} from '../../common/routesNames';

export function ShowLinkedSubscription({show, handleClose, subscriptionId}) {
  const [linkedSubscriptionList, setLinkedSubscriptionList] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchLinkedSubscriptions();
  }, []);

  const fetchLinkedSubscriptions = async () => {
    setLoader(true);
    let response = await getLinkedSubscriptions(subscriptionId);
    setLoader(false);

    if (response && response.data.success) {
      setLinkedSubscriptionList(response.data.content.subscription);
    } else {
      setLinkedSubscriptionList([]);
    }
  };

  const ReturnBadgeType = type => {
    if (
      type.toLowerCase() == allCommonText.planTypes.month.toLowerCase() ||
      type.toLowerCase() == allCommonText.planTypes.monthly.toLowerCase()
    ) {
      return colors.black2;
    } else if (
      type.toLowerCase() == allCommonText.planTypes.biannual.toLowerCase() ||
      type.toLowerCase() == allCommonText.planTypes.biannually.toLowerCase()
    ) {
      return colors.gray;
    } else {
      return colors.primary;
    }
  };

  const SubscriptionItem = ({type, id}) => {
    return (
      <div className="subscription-item">
        <span
          data-tooltip-id={`billing-id-tooltip`}
          data-tooltip-content={`Copy to Clipboard`}
          style={{cursor: 'pointer'}}
          onClick={() => {
            navigate(`${path.subscriptionDetails}?id=${id.split('_')[1]}`);
            navigator.clipboard.writeText(id);
          }}>
          {id}
        </span>
        <div
          style={{backgroundColor: ReturnBadgeType(type)}}
          className="subscription-type-badge">
          <span>{type} </span>
        </div>
      </div>
    );
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="show-subscription-popup-wrapper">
          <div className="top-show-subscription-container">
            <span>{allCommonText.linkedSubscription} </span>
            <GrClose
              className="show-subscription-close-btn"
              onClick={handleClose}
              size={25}
              color={colors.primary}
            />
          </div>
          <div>
            {loader ? (
              <Loader />
            ) : (
              linkedSubscriptionList.map((i, index) => (
                <SubscriptionItem
                  key={index}
                  type={i.subscriptionType}
                  id={i.viewSubscriptionId}
                />
              ))
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
