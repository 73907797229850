import React, {useState, useEffect} from 'react';
import './App.css';
import ContextProvider from './utils/context-api/contextProvider';
import ErrorBoundary from './ErrorBoundary';
import BaseRoutes from './routes';
import {Footer, Header} from './components';
import {EmailConfirmation} from './pages';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {ToastContainer, toast} from 'react-toastify';
import {path, routes} from './common/routesNames';
import PublicRoute from './routes/publiceRoutes';
import {Route, Routes, useLocation} from 'react-router-dom';
import {onMessageListener} from './firebase';
import {getAllNotifications} from './utils/rest-services';
import {allCommonText} from './common/constants';
import {getToken} from './utils/localstorage';

function App() {
  const location = useLocation();
  const isLoggedIn = getToken() !== '';

  const [notification, setNotification] = useState({title: '', body: ''});
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationsList, setNotificationsList] = useState([]);

  const fetchCustomerNotifications = async () => {
    let response = await getAllNotifications();
    if (response && response.success) {
      const content = response.content;
      if (content && content?.length > 0) {
        setNotificationCount(content.filter(item => !item.isRead).length);
        setNotificationsList(content);
      } else {
        setNotificationsList([]);
      }
    } else {
      toast.error(response?.data?.description || allCommonText.somethingWrong, {
        autoClose: false,
      });
    }
  };
  useEffect(() => {
    if (notificationsList.length === 0) {
      if (isLoggedIn) {
        fetchCustomerNotifications();
      }
    }
  }, [isLoggedIn]);

  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  }
  useEffect(() => {
    if (notification?.title) {
      notify();
      fetchCustomerNotifications();
    }
  }, [notification]);

  onMessageListener()
    .then(payload => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch(err => console.error('failed: ', err));

  const stripePromise = loadStripe(process.env.REACT_APP_STRIP_KEY);
  //for Testing
  // const stripePromise = loadStripe(
  //   'pk_test_51KC643FKQ9IO2pYuc7cYnRXj2XkwmyNxcBKD5x1dRZRw0LQFZ9d5HbAl5tvVQ2reuR0MUk4Nh1QrFedtUzf5ShWA00MwoxCxjW',
  // );

  // for Production
  // const stripePromise = loadStripe(
  //   'pk_live_51KC643FKQ9IO2pYuWOnO2bpRE1TC41B1eRBvrgqfXV5A9T7ev8jDd9gDpbmyFwg34CMcosn9T08g93w9S9kWlVnO00JixNf4Pq',
  // )
  return (
    <Elements stripe={stripePromise}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />{' '}
      <ContextProvider
        notificationCount={notificationCount}
        setNotificationCount={setNotificationCount}
        notificationsList={notificationsList}
        setNotificationsList={setNotificationsList}>
        <ErrorBoundary>
          {location.pathname !== path.emailConfirmation ? (
            <>
              <Header />
              <BaseRoutes />
              <Footer />
            </>
          ) : (
            <Routes>
              <Route
                path={path.emailConfirmation}
                key={`route-${routes.emailConfirmation}`}
                element={
                  <PublicRoute>
                    <EmailConfirmation />
                  </PublicRoute>
                }
              />
            </Routes>
          )}
        </ErrorBoundary>
      </ContextProvider>
    </Elements>
  );
}

export default App;
