import React from 'react';
import {BsCloudUpload} from 'react-icons/bs';
import {HiCheckCircle} from 'react-icons/hi';
import {RiCloseFill} from 'react-icons/ri';
import './styles.scss';

export const ChooseFile = ({
  uploadTitle,
  note,
  value,
  handleBlur,
  error,
  onChangeHandler,
  onRemoveFileHandler,
  files,
}) => {
  const uploadHandler = event => {
    const file = event.target.files[0];
    onChangeHandler(file);
  };

  const removeFile = file => {
    onRemoveFileHandler(file);
  };

  return (
    <>
      <div className="file-card">
        <div className="file-input">
          <input
            value={value}
            onBlur={handleBlur}
            onChange={uploadHandler}
            type="file"
            onClick={e => (e.target.value = null)}
          />
          <button>
            <div className="cloud-icon">
              <BsCloudUpload className="uploaderButton" />
            </div>
            <div className="file-info">
              <span>{uploadTitle}</span>
              <p>{note}</p>
            </div>
          </button>
        </div>

        <ul className="fileList">
          {files &&
            files.map((item, index) => {
              return (
                <li key={index}>
                  <div className="list-item">
                    <div className="left-list-elements">
                      <HiCheckCircle />
                      <span>{item.name}</span>
                    </div>
                    <div
                      className="right-list-elements"
                      onClick={() => {
                        removeFile(item);
                      }}>
                      <RiCloseFill />
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
      {error && (
        <div className="error-input-container">
          {error ? <p className="form-error">{error}</p> : null}
        </div>
      )}
    </>
  );
};
