import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {allCommonText} from '../../common/constants';
import {GrClose} from 'react-icons/gr';
import {colors, images} from '../../common';
import {Loader} from '../loader';
import {planCategory} from '../../pages/user-profile-section/policy-and-claims';
import {
  getInvoiceById,
  getRefundById,
  getSubscriptionById,
} from '../../utils/rest-services';
import './styles.scss';
import 'bootstrap/dist/css/bootstrap.css';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const InvoiceItem = ({
  name,
  deductible,
  cost,
  serviceTypeId,
  make,
  model,
  address,
}) => {
  return (
    <div className="invoice-item-view">
      <div className="plan-icon">
        <img src={planSelectorIcon(serviceTypeId)} alt="" />
      </div>
      <div className="invoice-detail-card">
        <div className="primary-card-container-invoice ">
          <span>{name}</span>
          {make && <span>{`Make: ${make}`} </span>}
          {model && <span>{`Model: ${model}`} </span>}
          {address && <span>{`Address: ${address}`} </span>}
        </div>
        <div className="secondary-card-container-invoice">
          <span>
            {`${allCommonText.popupEnums.subscriptionPlanHeadings.deductibles}: ${deductible}`}
          </span>
          <div>
            <span> {`${allCommonText.labels.cost}: ${cost}`} </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const planSelectorIcon = id => {
  switch (id) {
    case allCommonText.serviceTypeEnums.vehicle:
      return images.vehicleV;
    case allCommonText.serviceTypeEnums.home:
      return images.homeV;
    case allCommonText.serviceTypeEnums.device:
      return images.devicesV;
    case allCommonText.serviceTypeEnums.commercial:
      return images.commercialV;
    case allCommonText.serviceTypeEnums.disaster:
      return images.disasterIcon;
  }
};

export function InvoiceAndSubscriptionDetails({
  show,
  handelClose,
  invoiceId,
  refundId,
  subscriptionId,
}) {
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [invoiceItemList, setInvoiceItemList] = useState([]);
  const [refundDetails, setRefundDetails] = useState({});
  const [refundItemList, setRefundItemList] = useState([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (invoiceId) {
      fetchInvoiceDetails();
    } else if (subscriptionId) {
      fetchSubscriptionDetails();
    } else {
      fetchRefundDetails();
    }
  }, []);

  const fetchInvoiceDetails = async () => {
    setLoader(true);
    let response = await getInvoiceById(invoiceId);
    if (response && response.data.success) {
      const {
        data: {content},
      } = response;
      setInvoiceDetails(content.invoice);
      setInvoiceItemList(content.invoiceItems);
      setLoader(false);
    }
  };
  const fetchSubscriptionDetails = async () => {
    setLoader(true);
    let response = await getSubscriptionById(subscriptionId);

    if (response && response.data.success) {
      const {
        data: {content},
      } = response;
      setSubscriptionDetails(content);
      setLoader(false);
    }
  };

  const fetchRefundDetails = async () => {
    setLoader(true);
    let response = await getRefundById(refundId.split('_')[1]);
    if (response && response.data.success) {
      const {
        data: {
          content: {refund, items},
        },
      } = response;
      setLoader(false);
      setRefundDetails(refund);
      setRefundItemList(items);
    }
  };

  const ItemText = ({keyy, value, isBadge, onClick, isCoppiedable}) => {
    return (
      <div
        onClick={isCoppiedable ? onClick : null}
        className={`balance-details`}>
        <div>
          <span>{keyy} </span>
          <span
            style={{cursor: isCoppiedable && 'pointer'}}
            data-tooltip-id={isCoppiedable && `id-tooltip-${keyy}`}
            data-tooltip-content={isCoppiedable && `Copy to Clipboard`}
            className={`${
              isBadge ? `${planCategory(value)} invoice-plan-type-badge` : ''
            }`}>
            {value}
            {isCoppiedable && <Tooltip id={`id-tooltip-${keyy}`} />}
          </span>
        </div>
      </div>
    );
  };
  const getInvoiceStatus = (status, text) => {
    if (status === 'False') {
      return (
        <span class="badge rounded-pill bg-warning text-light">
          {allCommonText.labels.invoiceStatus.upcoming}
        </span>
      );
    } else if (status === false) {
      return (
        <span class="badge rounded-pill bg-danger text-light">
          {text || allCommonText.labels.subscriptionStatus.cancel}
        </span>
      );
    } else if (status === 'succeeded') {
      return (
        <span class="badge rounded-pill bg-success">
          {text || allCommonText.labels.refundStatus.succeed}
        </span>
      );
    } else if (status === 'failed') {
      return (
        <span class="badge rounded-pill bg-danger text-light">
          {allCommonText.labels.refundStatus.failed}
        </span>
      );
    } else if (status === 'pending') {
      return (
        <span class="badge rounded-pill bg-warning">
          {allCommonText.labels.refundStatus.pending}
        </span>
      );
    } else if (status === true) {
      return (
        <span class="badge rounded-pill bg-success center-align">
          {text || allCommonText.labels.refundStatus.succeed}
        </span>
      );
    } else {
      return (
        <span class="badge rounded-pill bg-success">
          {text || allCommonText.labels.invoiceStatus.paid}
        </span>
      );
    }
  };

  const getPopupTitle = () => {
    if (invoiceId) {
      return allCommonText.invoiceDetails;
    } else if (subscriptionId) {
      return allCommonText.heading.subscriptionDetails;
    } else return allCommonText.refundDetails;
  };

  const invoiceDetailsItems = [
    {
      key: allCommonText.labels.invoiceId,
      value: invoiceDetails?.viewInvoiceId,
    },
    {
      key: allCommonText.labels.subscriptionId,
      value: invoiceDetails?.viewSubId,
    },
    // {
    //   key: allCommonText.labels.previousBalance,
    //   value: invoiceDetails?.previousBalance,
    // },
    // {
    //   key: allCommonText.labels.currentBalance,
    //   value: invoiceDetails?.currentBalance,
    // },
    {
      key: allCommonText.labels.dueDate,
      value: invoiceDetails.dueOn?.split(' ')[0],
    },
    {
      key: allCommonText.labels.paidDate,
      value: invoiceDetails.datePaid?.split(' ')[0],
    },
  ];

  const subscriptionDetailsItem = [
    {
      key: allCommonText.labels.subscriptionId,
      value: subscriptionDetails.viewSubscriptionId,
    },
    {
      key: allCommonText.labels.subscriptionType,
      value: subscriptionDetails.subscriptionType,
    },
    {
      key: allCommonText.labels.subscriptionAmount,
      value: `$${parseFloat(subscriptionDetails.subscriptionSubtotal).toFixed(
        2,
      )}`,
    },
    {
      key: allCommonText.labels.createdDate,
      value: subscriptionDetails.createdDate?.split('T')[0],
    },
  ];

  const refundDetailsItem = [
    {
      key: allCommonText.labels.refundID,
      value: refundDetails.refundId,
    },
    {
      key: allCommonText.labels.subscriptionId,
      value: refundDetails.viewSubscriptionId,
    },
    {
      key: allCommonText.labels.refundDate,
      value: refundDetails.refundDate,
    },
    {
      key: allCommonText.labels.subscriptionType,
      value: refundDetails.subscriptionType,
    },
    {
      key: allCommonText.labels.refundType,
      value: refundDetails.refundType,
    },
  ];
  return (
    <Modal centered show={show} size={'lg'} onHide={handelClose}>
      <Modal.Body className="modal-body-invoice">
        {loader ? (
          <div className="loader-container-invoice">
            <Loader />
          </div>
        ) : (
          <>
            <div className="top-add-payment-container">
              <span>{getPopupTitle()}</span>
              <GrClose
                className="add-payment-close-btn"
                onClick={handelClose}
                size={25}
                color={colors.primary}
              />
            </div>
            <div className="invoice-details-container">
              <div
                className={`invoice-top-detail-title ${
                  (subscriptionId && 'badge-center') ||
                  (refundId && 'badge-center')
                }`}>
                {/* {invoiceId && (
                  <span>{`${invoiceDetails.firstName} ${invoiceDetails.lastName}`}</span>
                )} */}
                {invoiceId && getInvoiceStatus(invoiceDetails.isPaid)}
                {subscriptionId &&
                  getInvoiceStatus(
                    subscriptionDetails.isActive,
                    subscriptionDetails.isActive
                      ? allCommonText.labels.subscriptionStatus.active
                      : allCommonText.labels.subscriptionStatus.cancel,
                  )}
                {refundId &&
                  getInvoiceStatus(
                    refundDetails.refundStatus,
                    refundDetails.refundStatus,
                  )}
              </div>

              {invoiceId &&
                invoiceDetailsItems.map(i => (
                  <ItemText
                    key={i}
                    keyy={i.key}
                    isCoppiedable={
                      i.key == allCommonText.labels.invoiceId ||
                      i.key == allCommonText.labels.subscriptionId
                    }
                    onClick={() => {
                      navigator.clipboard.writeText(i.value);
                    }}
                    isBadge={
                      subscriptionDetails.subscriptionType ? true : false
                    }
                    value={i.value}
                  />
                ))}
              {subscriptionId &&
                subscriptionDetailsItem.map(i => (
                  <ItemText
                    key={i}
                    keyy={i.key}
                    isCoppiedable={i.key == allCommonText.labels.subscriptionId}
                    onClick={() => {
                      navigator.clipboard.writeText(i.value);
                    }}
                    isBadge={
                      i.key === allCommonText.labels.subscriptionType &&
                      subscriptionDetails.subscriptionType
                        ? true
                        : false
                    }
                    value={i.value}
                  />
                ))}
              {refundId &&
                refundDetailsItem.map(i => (
                  <ItemText
                    key={i}
                    keyy={i.key}
                    isCoppiedable={
                      i.key == allCommonText.labels.subscriptionId ||
                      i.key == allCommonText.labels.refundID
                    }
                    onClick={() => {
                      navigator.clipboard.writeText(i.value);
                    }}
                    isBadge={
                      i.key === allCommonText.labels.subscriptionType &&
                      refundDetails.subscriptionType
                        ? true
                        : false
                    }
                    value={i.value}
                  />
                ))}
              {invoiceId && (
                <div className="total-balance-invoice">
                  <div>
                    <span>{allCommonText.labels.cost} </span>
                    <span>{invoiceDetails.cost} </span>
                  </div>
                </div>
              )}
              {refundId && (
                <div className="total-balance-invoice">
                  <div>
                    <span>{allCommonText.labels.amount} </span>
                    <span className="primary-text-color">
                      {refundDetails.refundAmount}{' '}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {subscriptionId && (
              <div className="invoice-container-v2">
                <span className="invoice-title">
                  {allCommonText.heading.subscriptionItems}
                </span>
                <div className="invoice-item-details">
                  {subscriptionDetails?.items?.map(i => (
                    <InvoiceItem
                      key={i}
                      make={i?.make}
                      model={i?.model}
                      serviceTypeId={i.serviceTypeId}
                      address={i?.address}
                      cost={`$${parseFloat(i.cost).toFixed(2)}`}
                      deductible={`$${parseFloat(i.deductibleAmount).toFixed(
                        2,
                      )}`}
                      name={i.providerName}
                    />
                  ))}
                </div>
              </div>
            )}
            {invoiceId && (
              <div className="invoice-container-v2">
                <span className="invoice-title">
                  {allCommonText.heading.invoiceItems}
                </span>
                <div className="invoice-item-details">
                  {invoiceItemList?.map(i => (
                    <InvoiceItem
                      key={i}
                      make={i?.make}
                      model={i?.model}
                      serviceTypeId={i.serviceTypeId}
                      address={i?.address}
                      cost={`$${parseFloat(i.cost).toFixed(2)}`}
                      deductible={`$${parseFloat(i.deductibleAmount).toFixed(
                        2,
                      )}`}
                      name={i.providerName}
                    />
                  ))}
                </div>
              </div>
            )}
            {refundId && refundItemList?.length > 0 && (
              <div className="invoice-container-v2">
                <span className="invoice-title">
                  {allCommonText.heading.refundItems}
                </span>
                <div className="invoice-item-details">
                  {refundItemList?.map(i => (
                    <InvoiceItem
                      key={i}
                      make={i?.make}
                      model={i?.model}
                      serviceTypeId={i.serviceTypeId}
                      cost={`$${parseFloat(i.cost).toFixed(2)}`}
                      address={i.address}
                      deductible={`$${parseFloat(i.deductibleAmount).toFixed(
                        2,
                      )}`}
                      name={i.providerName}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
