import React, {useState} from 'react';
import {GiHamburgerMenu} from 'react-icons/gi';
import {useNavigate} from 'react-router';
import {GoX} from 'react-icons/go';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import {allCommonText} from '../../common/constants';
import {path} from '../../common/routesNames';
import './styles.scss';

export const ProfileAccountSlider = ({...rest}) => {
  const [selectedTab, setSelectedTab] = useState(rest.selectedId);
  let navigate = useNavigate();
  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  const navTabs = [
    {
      path: path.dashboard,
      name: allCommonText.dashboard.dashboard,
      icon: <span class="icon-dashBoardIcon"></span>,
    },
    {
      path: path.profile,
      name: allCommonText.profile.editProfile,
      icon: <span class="icon-userIcon"></span>,
    },
    {
      path: path.policyAndClaims,
      name: allCommonText.profile.policyClaims,
      icon: <span class="icon-policyClaimIcon"></span>,
    },
    {
      path: path.invoicesAndPayments,
      name: allCommonText.profile.invoicesPayments,
      icon: <span class="icon-invoicePaymentIcon"></span>,
    },
    {
      path: path.subscriptions,
      name: allCommonText.profile.subscriptions,
      icon: <span class="icon-subscriptionIcon"></span>,
    },
    {
      path: path.refund,
      name: allCommonText.profile.refund,
      icon: <span class="icon-refund"></span>,
    },
    {
      path: path.billingCards,
      name: allCommonText.profile.billingCards,
      icon: <span class="icon-billingCardIcon"></span>,
    },
    {
      path: path.changePassword,
      name: allCommonText.profile.changePassword,
      icon: <span class="icon-Change-password"></span>,
    },
  ];

  return (
    <>
      <div className="profile-slider">
        <div className="profile-handler-button">
          <button onClick={() => setState({isPaneOpenLeft: true})}>
            <span>
              <GiHamburgerMenu />
            </span>
            <span> {allCommonText.profile.account}</span>
          </button>
        </div>
        <div className="profile-slider-content">
          <SlidingPane
            isOpen={state.isPaneOpenLeft}
            onRequestClose={() => setState({isPaneOpenLeft: false})}>
            <span
              className="slider-close"
              onClick={() => setState({isPaneOpenLeft: false})}>
              {<GoX />}
            </span>
            <div className="slider-profile-category">
              <ul>
                {navTabs.map((item, index) => {
                  const {path, name, icon} = item;
                  return (
                    <li
                      key={index}
                      className={selectedTab === index ? 'selectedTab' : ''}
                      onClick={() => {
                        navigate({pathname: path});
                        setSelectedTab(index);
                        setState({isPaneOpenLeft: false});
                      }}>
                      <span>
                        {icon}
                        {name}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </SlidingPane>
        </div>
      </div>
    </>
  );
};
