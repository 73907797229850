import React from 'react';
import {Grid} from '@mui/material';
import {SlSocialInstagram} from 'react-icons/sl';
import {FaFacebookF} from 'react-icons/fa';
import {allCommonText, footer} from '../../common/constants';
import {images} from '../../common/images';
import {publicPaths, pagewithoutfouter} from '../../common/routesNames';
import {useLocation} from 'react-router';
import './style.scss';

export const Footer = () => {
  const location = useLocation();
  const pagePath = location.pathname;
  return (
    <>
      <footer className="footerBox">
        {publicPaths.indexOf(pagePath) !== -1 && (
          <Grid container spacing={0}>
            <div className="footer-layout">
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <div className="footerLogo">
                  <img
                    src={images.newNodukLogo}
                    alt={footer.newNodukLogo}
                    style={{height: '150px'}}
                  />
                  <div className="socailmediaicons">
                    <a href={allCommonText.urls.nodukTwitter}>
                      <img
                        src={images.xicon}
                        className=" links twittericon"></img>
                    </a>
                    <a href={allCommonText.urls.nodukfb} className="linksAtags">
                      <FaFacebookF className="links fbsnapchat" />
                    </a>
                    <a
                      href={allCommonText.urls.nodukInstagram}
                      className="linksAtags">
                      <SlSocialInstagram className="links fbsnapchat" />
                    </a>
                  </div>

                  <p className="copyright">Copyright © 2024 NO DUK</p>
                </div>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <div>
                  <p className="servicesSection">Services</p>
                  <a
                    href={allCommonText.deductablePlans.deductableNoduk}
                    className="servicesoption">
                    <p> Deductible Plans</p>
                  </a>
                </div>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <div>
                  <p className="servicesSection">Company</p>
                  <a
                    href={allCommonText.aboutUs.aboutNoduk}
                    className="servicesoption">
                    <p> About Us</p>
                  </a>
                  <a
                    href={allCommonText.joinTheTeam.jointeamNoduk}
                    className="servicesoption">
                    <p>Careers</p>
                  </a>
                  <a
                    href={allCommonText.contactUs.contactNoduk}
                    className="servicesoption">
                    <p>Contact Us</p>
                  </a>
                </div>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <div>
                  <p className="servicesSection">Address</p>

                  <p>8 The Green</p>

                  <p>Suite #8234</p>

                  <p>Dover,DE 19901</p>

                  <p className="contactonFooter">+1-855-NODUK99</p>
                  <p className="poweredby">Powered by NO DUK</p>
                </div>
              </Grid>
            </div>
          </Grid>
        )}
        {pagewithoutfouter.indexOf(pagePath) !== -1 && (
          <div className={`rightsBox`}>
            <span>
              {footer.rights}
              <a
                href="https://noduk.com/privacy/"
                target="_blank"
                rel="noreferrer"></a>
            </span>
          </div>
        )}
      </footer>
    </>
  );
};
