import React from 'react';
import {Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import {allCommonText} from '../../common/constants';
import {images} from '../../common/images';
import {GenericButton} from '../generic-button';
import './style.scss';

export const GenericModal = ({
  show,
  handleClose,
  body,
  btnText,
  grayButton,
  grayButton2,
  type,
  button2,
  btnText2,
  handleAction,
  title,
  buttonLoader,
  backDrop,
}) => {
  const modalAspectsHandler = type => {
    switch (type) {
      case 1:
        return {
          image: images.success,
          title: allCommonText.success,
          color: 'modal-title success-title-color-modal',
        };
      case 2:
        return {
          image: images.error,
          title: allCommonText.error,
          color: 'modal-title error-title-color-modal',
        };
      case 3:
        return {
          image: images.alert,
          title: title || allCommonText.alert,
          color: 'modal-title alert-title-color-modal',
        };
      case 4:
        return {
          image: images.trash,
          title: title || allCommonText.alert,
          color: 'modal-title error-title-color-modal',
        };
      case 5:
        return {
          image: images.sure,
          title: title || allCommonText.sure,
          color: 'modal-title alert-title-color-modal',
        };

      default:
        return {
          image: images.success,
          title: allCommonText.success,
          color: 'modal-title success-title-color-modal',
        };
    }
  };

  return (
    <>
      <Modal centered show={show} onHide={backDrop || handleClose}>
        <Modal.Body>
          {
            <div className="modal-body-generic">
              <img src={modalAspectsHandler(type).image} alt="icon" />
              <span className={modalAspectsHandler(type).color}>
                {modalAspectsHandler(type).title}
              </span>
              <span className="modal-description">{body}</span>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <div className="footerContainer">
            {
              <GenericButton
                id="generic-modal-close"
                grayButton={grayButton}
                onPress={handleClose}
                buttonText={btnText}
                loading={buttonLoader}
              />
            }
            {button2 && (
              <GenericButton
                id="generic-modal-confirm"
                grayButton={grayButton2}
                onPress={handleAction}
                buttonText={btnText2}
              />
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
