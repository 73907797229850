import React, {useState} from 'react';
import './styles.scss';
import {allCommonText} from '../../../common/constants';
import {
  GenericButton,
  GenericModal,
  InputField,
  TextAreaFeild,
} from '../../../components';
import {Formik} from 'formik';
import {deleteAccountSchema} from '../../../common/schemas';
import {deleteAccount} from '../../../utils/rest-services';
import {useNavigate} from 'react-router';
import {clearStorage} from '../../../utils/localstorage';
import {path} from '../../../common/routesNames';

function DeleteAccount() {
  const reasonData = [
    {
      reason: allCommonText.deleteAccount.likeReason,
    },
    {
      reason: allCommonText.deleteAccount.lookReason,
    },
    {
      reason: allCommonText.deleteAccount.payReason,
    },
    {
      reason: allCommonText.deleteAccount.relateReason,
    },
    {
      reason: allCommonText.deleteAccount.future,
    },
  ];

  const [selectedReasonIndex, setSelectedReasonIndex] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [popupResponse, setPopUpResponse] = useState({
    type: allCommonText.popupEnums.success,
    title: '',
    details: '',
  });
  const navigate = useNavigate();
  const performDeleteAccount = async (values, formik) => {
    formik.setSubmitting(false);
    const payload = {
      deleteType: selectedReasonIndex,
      deleteReason: values.reason,
      password: values.password,
    };
    let response = await deleteAccount(payload);
    if (response && response.data) {
      const {
        data: {success, description},
      } = response;

      if (success) {
        setPopUpResponse({
          type: allCommonText.popupEnums.sure,
          // details: description,
          details: allCommonText.deleteAccount.deleteNotes,
        });
      } else {
        setPopUpResponse({
          type: allCommonText.popupEnums.error,
          details: description,
        });
      }
    }
    setShowAlert(true);
  };

  const performLogout = () => {
    clearStorage();
    navigate(path.login, {replace: true});
  };

  return (
    <>
      <Formik
        initialValues={{
          reason: '',
          password: '',
        }}
        onSubmit={(values, formik) => {
          performDeleteAccount(values, formik);
        }}
        validationSchema={deleteAccountSchema}
        enableReinitialize={true}
        validateOnMount={true}>
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          touched,
          errors,
        }) => (
          <div className="main-delete-account-container">
            <h3>{allCommonText.profile.deleteAccount}</h3>
            <hr />
            <div className="delete-description">
              <h5>{allCommonText.deleteAccount.deleteDescription}</h5>
              <p>{allCommonText.deleteAccount.deleteNote}</p>
            </div>
            <div className="delete-reason-box">
              <div className="delete-reason-option">
                {reasonData.map((item, index) => (
                  <div className="delete-reason-item" key={index}>
                    <label>
                      <input
                        type="radio"
                        name="type"
                        id="type"
                        value={index}
                        checked={selectedReasonIndex === index}
                        onChange={() => setSelectedReasonIndex(index)}
                        className="deleteRadioButton"
                      />
                      <span>{item.reason}</span>
                    </label>
                  </div>
                ))}
                <div className="typeReason">
                  <TextAreaFeild
                    id="reason"
                    name="reason"
                    value={values.reason}
                    setValue={handleChange('reason')}
                    handleBlur={handleBlur}
                    error={touched.reason && errors.reason}
                    placeholder={allCommonText.deleteAccount.typeReason}
                  />
                </div>
                <div className="deletePassword">
                  <p>{allCommonText.deleteAccount.deleteConitune}</p>
                  <InputField
                    isPassword={true}
                    id="password"
                    name="password"
                    value={values.password}
                    placeholder={allCommonText.deleteAccount.enterPassword}
                    error={errors.password}
                    touched={touched.password}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </div>
              </div>
              <GenericButton
                buttonText={allCommonText.profile.deleteAccount}
                onPress={handleSubmit}
              />
            </div>
          </div>
        )}
      </Formik>
      <GenericModal
        show={showAlert}
        type={popupResponse.type}
        body={popupResponse.details}
        handleClose={() => {
          if (popupResponse.type === allCommonText.popupEnums.sure) {
            performLogout();
          } else {
            setShowAlert(false);
          }
        }}
        btnText={`OK`}
        button2={true}
        btnText2={`Cancel`}
        grayButton2={true}
        handleAction={() => setShowAlert(false)}
      />
    </>
  );
}

export default DeleteAccount;
