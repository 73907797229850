import React, { useState } from 'react';
import './style.scss';
import { GenericButton, TextInputField } from '..';
import { FaPlus } from 'react-icons/fa';
import { FaMinus } from 'react-icons/fa';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { Grid } from '@mui/material';
import Dropdown from 'react-dropdown';
import { allCommonText } from '../../common/constants';

export function CustomizedSearch({
  onSearchBtnPress,
  btnLoader,
  title,
  status,
  firstLabel,
  firstPlaceholder,
  secondBox,
  secondLabel,
  secondPlaceholder,
  thirdLabel,
  subscriptionType,
  subscriptionTypeLabel,
  subscriptionStatus,
  onClearFilterHandler,
  secondDropdownValue,
  firstDropdownValue,
  firstPrefixValue,
  secondPrefixvalue
}) {
  const [primaryFilter, setPrimaryFilter] = useState('');
  const [secondryFilter, setSecondryFilter] = useState('');
  const [ispopupOpen, setIspopupOpen] = useState(true);
  const [subscriptionTypeStatus, setsubscriptionTypeStatus] = useState(
    firstDropdownValue || allCommonText.labels.invoiceStatus.all,
  );
  const [selectedStatus, setSelectedStatus] = useState(
    secondDropdownValue || allCommonText.labels.invoiceStatus.all,
  );
  
  const onClearFilter = () => {
    setPrimaryFilter('');
    setSecondryFilter('');
    setsubscriptionTypeStatus(allCommonText.labels.invoiceStatus.all);
    setSelectedStatus(allCommonText.labels.invoiceStatus.all);
    onClearFilterHandler();
  };
  const isClearVisible =
    primaryFilter != '' ||
    secondryFilter != '' ||
    subscriptionTypeStatus != allCommonText.labels.invoiceStatus.all ||
    selectedStatus != allCommonText.labels.invoiceStatus.all;
  return (
    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button "
            type="button"
            onClick={() => {
              setIspopupOpen(!ispopupOpen);
            }}
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne">
            <div className="top-search-hearder">
              <span>{title}</span>
              <span className="accordianbuttonStyle">
                {ispopupOpen ? <FaPlus /> : <FaMinus />}
              </span>
              {!ispopupOpen && isClearVisible && (
                <span onClick={onClearFilter} className="clear-filter">
                  Clear All
                </span>
              )}
            </div>
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">

          <div class="accordion-body">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <label htmlFor="/">{firstLabel}</label>
                <TextInputField
                  prefixerValue={firstPrefixValue}
                  search
                  placeHolder={firstPlaceholder}
                  value={primaryFilter}
                  setValue={e => {
                    const inputValue = e.target.value;
                    const numericValue = inputValue
                      .replace(/\D/g, '')
                      .slice(0, 6);
                    setPrimaryFilter(numericValue);
                  }}
                />
              </Grid>
              {secondBox ? (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <label htmlFor="/">{secondLabel}</label>
                  <TextInputField
                    prefixerValue={secondPrefixvalue}
                    search
                    placeHolder={secondPlaceholder}
                    value={secondryFilter}
                    setValue={e => {
                      const inputValue = e.target.value;
                      const numericValue = inputValue
                        .replace(/\D/g, '')
                        .slice(0, 6);
                      setSecondryFilter(numericValue);
                    }}
                  />
                </Grid>
              ) : null}
              {subscriptionType ? (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <label htmlFor="/">{subscriptionTypeLabel}</label>
                    <Dropdown
                      options={subscriptionStatus}
                      value={subscriptionTypeStatus}
                      onChange={i => {
                        setsubscriptionTypeStatus(i.value);
                      }}
                      menuClassName="dropdown-menu-alluser"
                      className="dropdown-style-by-status"
                      controlClassName="status-control-dropdown"
                      placeholder="Select an option"
                      arrowClosed={<BsChevronDown />}
                      arrowOpen={<BsChevronUp />}
                    />
                </Grid>
              ) : null}
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <label htmlFor="/">{thirdLabel}</label>
                  <Dropdown
                    options={status}
                    onChange={i => {
                      setSelectedStatus(i.value);
                    }}
                    value={selectedStatus}
                    menuClassName="dropdown-menu-alluser"
                    className="dropdown-style-by-status"
                    controlClassName="status-control-dropdown"
                    placeholder="Select an option"
                    arrowClosed={<BsChevronDown />}
                    arrowOpen={<BsChevronUp />}
                  />
              </Grid>
            </Grid>

            <GenericButton
              id="customized-search"
              loading={btnLoader}
              buttonText={allCommonText.buttonTexts.search}
              onPress={() => {
                onSearchBtnPress(
                  primaryFilter,
                  secondryFilter,
                  selectedStatus,
                  subscriptionTypeStatus,
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
