import React, {useState} from 'react';
import {Grid} from '@mui/material';
import {TextInputField} from '../../../components/input-others/index';
import {GenericButton} from '../../../components/generic-button/index';
import {Formik} from 'formik';
import {changePasswordScehma} from '../../../common/schemas';
import {allCommonText} from '../../../common/constants';
import {GenericModal} from '../../../components';
import {clearStorage} from '../../../utils/localstorage';
import {requestChangePassword} from '../../../utils/rest-services/index';
import {useNavigate} from 'react-router-dom';
import {path} from '../../../common/routesNames';
import './styles.scss';

const ChangePassword = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [popupResponse, setPopUpResponse] = useState({
    type: allCommonText.popupEnums.success,
    title: '',
    details: '',
  });
  const navigate = useNavigate();
  const [pageData, setPageData] = useState({
    currentPassword: ``,
    password: ``,
    confirmPassword: ``,
  });

  const performLogout = () => {
    clearStorage();
    navigate(path.login, {replace: true});
  };

  const performChangePassword = async (values, formik) => {
    setPageData(values);
    formik.setSubmitting(false);
    setIsLoading(true); // Set isLoading to true when the API call is being made

    const payload = {
      currentPassword: values.currentPassword,
      newPassword: values.password,
    };

    try {
      const responseObject = await requestChangePassword(payload);

      if (responseObject && responseObject.data) {
        const {
          data: {success, description},
        } = responseObject;
        if (success) {
          setPopUpResponse({
            type: allCommonText.popupEnums.success,
            details: description,
          });
        } else {
          setPopUpResponse({
            type: allCommonText.popupEnums.error,
            details: description,
          });
        }
      } else {
        setPopUpResponse({
          type: allCommonText.popupEnums.error,
          details: `Something went wrong`,
        });
      }
      setShowAlert(true);
    } catch (error) {
      console.error('Error occurred:', error);
      setPopUpResponse({
        type: allCommonText.popupEnums.error,
        details: `An error occurred while processing your request.`,
      });
      setShowAlert(true);
    } finally {
      setIsLoading(false); // Set isLoading to false after the API call is complete
    }
  };

  return (
    <div className="change-password-info">
      <div className="change-password-box">
        <Formik
          initialValues={{
            currentPassword: pageData.currentPassword || '',
            password: pageData.password || '',
            confirmPassword: pageData.confirmPassword || '',
          }}
          validateOnMount={true}
          onSubmit={(values, formik) => {
            performChangePassword(values, formik);
          }}
          validationSchema={changePasswordScehma}
          enableReinitialize>
          {({
            handleChange,
            handleSubmit,
            touched,
            errors,
            values,
            // isSubmitting,
          }) => (
            <>
              <div className="change-password-container mt-4">
                <h3>{allCommonText.profile.changePassword}</h3>
                <Grid container spacing={4}>
                  <div className="c-password">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <label htmlFor="/">
                        {allCommonText.placeholders.currentPassword}
                      </label>
                      <span className="profileEmail">
                        <TextInputField
                          name={'currentPassword'}
                          id={'currentPassword'}
                          placeHolder={
                            allCommonText.placeholders.enterCurrentPassword
                          }
                          value={values.currentPassword}
                          setValue={handleChange}
                          isPassword
                          className="input-field-container p-2 drop-down-height"
                          error={
                            touched.currentPassword && errors.currentPassword
                          }
                        />
                      </span>
                    </Grid>
                  </div>
                  <div className="c-password">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <label htmlFor="/">
                        {allCommonText.placeholders.newPassword}
                      </label>
                      <span className="profileEmail">
                        <TextInputField
                          className="input-field-container p-2 drop-down-height"
                          name={'password'}
                          id={'password'}
                          placeHolder={
                            allCommonText.placeholders.enterNewPassword
                          }
                          value={values.password}
                          setValue={handleChange}
                          isPassword
                          error={touched.password && errors.password}
                        />
                      </span>
                    </Grid>
                  </div>
                  <div className="c-password">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <label htmlFor="/">
                        {allCommonText.placeholders.confirmNewPassword}
                      </label>
                      <span className="profileEmail">
                        <TextInputField
                          className="input-field-container p-2 drop-down-height"
                          name={'confirmPassword'}
                          id={'confirmPassword'}
                          placeHolder={
                            allCommonText.placeholders.confirmNewPassword
                          }
                          value={values.confirmPassword}
                          setValue={handleChange}
                          isPassword
                          error={
                            touched.confirmPassword && errors.confirmPassword
                          }
                        />
                      </span>
                    </Grid>
                  </div>
                </Grid>
                <div className="profile-save-button">
                  <GenericButton
                    id="profile-save-button"
                    buttonText={allCommonText.profile.changePassword}
                    loading={isLoading}
                    onPress={() => {
                      handleSubmit();
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
        <GenericModal
          show={showAlert}
          type={popupResponse.type}
          body={popupResponse.details}
          handleClose={() => {
            if (popupResponse.type === allCommonText.popupEnums.success) {
              performLogout();
            } else {
              setShowAlert(false);
            }
          }}
          btnText={`Ok`}
        />
      </div>
    </div>
  );
};

export default ChangePassword;
