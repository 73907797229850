import React, {useState, useEffect} from 'react';
import {
  CustomizedSearch,
  InvoiceAndSubscriptionDetails,
  PolicyCard,
  Loader,
} from '../../../components';
import './styles.scss';
import '../../../components/policy-card/styles.scss';
import {getCustomersInvoices} from '../../../utils/rest-services';
import Pagination from '@mui/material/Pagination';
import {allCommonText} from '../../../common/constants';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router';
import {path} from './../../../common/routesNames';

export const INVOICE_STATUS_ENUMS = {
  Paid: 1,
  Upcoming: 2,
  Failed: 3,
  Open: 4,
};
function getKeyByValue(value) {
  return Object.keys(INVOICE_STATUS_ENUMS).find(
    key => INVOICE_STATUS_ENUMS[key] === value,
  );
}
export const getInvoiceStatus = status => {
  status = getKeyByValue(parseInt(status))?.toLowerCase();
  switch (status) {
    case 'upcoming':
      return (
        <span class="badge rounded-pill bg-warning text-light">
          {allCommonText.labels.invoiceStatus.upcoming}
        </span>
      );
    case 'paid':
      return (
        <span class="badge rounded-pill bg-success">
          {allCommonText.labels.invoiceStatus.paid}
        </span>
      );
    case 'failed':
      return (
        <span class="badge rounded-pill bg-danger">
          {allCommonText.labels.invoiceStatus.failed}
        </span>
      );
    case 'open':
      return (
        <span class="badge rounded-pill opened-invoice-color">
          {allCommonText.labels.invoiceStatus.open}
        </span>
      );
  }
};
export default function InvoicesPayments() {
  const [showDetails, setShowDetails] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [invoicesList, setInvoicesList] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [selectedId, setSelectedId] = useState(0);
  const [currentStatus, setCurrentStatus] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    let payload = {
      pageNo: currentPage,
      rowsPerPage: 10,
      status: currentStatus,
      id: 0,
      subscriptionId: 0,
    };
    fetchCustomerInvoices(payload);
  }, [currentPage]);

  const fetchCustomerInvoices = async payload => {
    setPageLoader(true);
    let response = await getCustomersInvoices(payload);
    if (response && response.data.success) {
      const {
        data: {
          content: {list, totalPages},
        },
      } = response;
      if (list && list?.length > 0) {
        setInvoicesList(list);
        setTotalPages(totalPages);
      } else {
        setInvoicesList([]);
        setTotalPages(0);
      }
      setPageLoader(false);
    } else {
      setPageLoader(false);
      toast.error(response?.data?.description || allCommonText.somethingWrong, {
        autoClose: false,
      });
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const onSearchHandler = async (invoiceId, subscriptionId, status, clear) => {
    let payload;
    if (clear) {
      setCurrentStatus(0);
      payload = {
        pageNo: 1,
        rowsPerPage: 10,
        status: 0,
        id: 0,
        subscriptionId: 0,
      };
    } else {
      status = INVOICE_STATUS_ENUMS[status];
      setCurrentStatus(status);
      payload = {
        pageNo: 1,
        rowsPerPage: 10,
        status: status,
        id: invoiceId == '' ? 0 : invoiceId.replace(/\D/g, ''),
        subscriptionId:
          subscriptionId == '' ? 0 : subscriptionId.replace(/\D/g, ''),
      };
    }

    setCurrentPage(1);
    setBtnLoader(true);
    await fetchCustomerInvoices(payload);
    setBtnLoader(false);
  };
  return (
    <>
      <div className="main-invoice-container">
        <h3>{allCommonText.profile.invoicesPayments}</h3>
        <CustomizedSearch
          firstLabel={allCommonText.labels.invoiceId}
          firstPlaceholder={` ${allCommonText.placeholders.invoiceIdNumber}`}
          secondBox
          secondLabel={allCommonText.labels.subscriptionId}
          secondPlaceholder={`${allCommonText.labels.enter} ${allCommonText.placeholders.subscriptionId}`}
          thirdLabel={allCommonText.labels.viewByStatus}
          firstPrefixValue={'inv_'}
          secondPrefixvalue={'sub_'}
          status={[
            allCommonText.labels.invoiceStatus.all,
            allCommonText.labels.invoiceStatus.paid,
            allCommonText.labels.invoiceStatus.upcoming,
            allCommonText.labels.invoiceStatus.failed,
            allCommonText.labels.invoiceStatus.open,
          ]}
          title={allCommonText.heading.searchInvoice}
          btnLoader={btnLoader}
          onClearFilterHandler={() => {
            onSearchHandler('', '', '', true);
          }}
          onSearchBtnPress={(invoiceId, subscriptionId, status) => {
            onSearchHandler(invoiceId, subscriptionId, status);
          }}
        />
        {pageLoader ? (
          <div className="no-invoice-container">
            <Loader size={40} />
          </div>
        ) : invoicesList?.length > 0 ? (
          invoicesList?.map((i, index) => (
            <PolicyCard
              key={index}
              cardType={allCommonText.viewCardType.invoice}
              onDetailBtnPress={() => {
                setSelectedId(i.invoiceId);
                // setShowDetails(true);
                navigate({
                  pathname: path.invoicePaymentDetail,
                  search: `?id=${i.invoiceId}`,
                });
              }}
              planIconTitle={<span>{i.viewInvoiceId}</span>}
              badge={getInvoiceStatus(i?.status)}
              cost={<span className="bold-payment"> {`Cost: ${i.cost}`} </span>}
              LeftTopDetails={
                <div className="details-item">
                  <span>{allCommonText.labels.dueDate}</span>
                  <span>
                    {i.dueOn === ''
                      ? allCommonText.policyClaims.notAvailable
                      : `${i.dueOn.replace(/\//g, '-').split(' ')[0]}`}
                  </span>
                </div>
              }
              LeftBottomDetails={
                i.isPaid === 'False' ? null : (
                  <div className="details-item">
                    <span>{allCommonText.labels.paidDate}</span>
                    <span>
                      {i.datePaid === ''
                        ? allCommonText.policyClaims.notAvailable
                        : `${i.datePaid.replace(/\//g, '-').split(' ')[0]}`}
                    </span>
                  </div>
                )
              }
              // rightTopDetails={
              //   <div className="details-item">
              //     <span>{allCommonText.labels.currentBalance}</span>
              //     <span>{`${i.currentBalance}`}</span>
              //   </div>
              // }
              // will add Api property
              // rigthBottomDetails={
              //   <div className="details-item">
              //     <span>{allCommonText.labels.previousBalance}</span>
              //     <span>{`${i.previousBalance}`}</span>
              //   </div>
              // }
            />
          ))
        ) : (
          !pageLoader && (
            <div className="no-invoice-container">
              <span>{allCommonText.labels.noInvoice}</span>
            </div>
          )
        )}
        {showDetails && (
          <InvoiceAndSubscriptionDetails
            show={showDetails}
            invoiceId={selectedId}
            handelClose={() => setShowDetails(false)}
          />
        )}
      </div>
      {totalPages > 1 && (
        <div className="pagination-container">
          <Pagination
            page={currentPage}
            color={'secondary'}
            onChange={handlePageChange}
            count={totalPages}
          />
        </div>
      )}
    </>
  );
}
