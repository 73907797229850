import React, {useState, useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './style.scss';
import {colors, images} from '../../common';
import OtpInput from 'react-otp-input';
import {GrClose} from 'react-icons/gr';
import {GenericButton} from '../generic-button';
import {InputField} from '../input-field';
import {allCommonText} from '../../common/constants';
import {
  editPhoneNumber,
  sendLatestCodeToNumber,
  verifyUserOtp,
  verifyUserOtpWithoutToken,
} from '../../utils/rest-services';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Loader} from '../loader';

export function MobileVerificationPopup({
  show,
  handleClose,
  number,
  userToken,
  profileScreen,
}) {
  const [otp, setOtp] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(number);
  const [btnLoader, setBtnLoader] = useState(false);
  const [isOtpRequest, setIsOtpRequest] = useState(false);

  useEffect(() => {
    setPhoneNumber(number);
    if (!profileScreen) {
      sendCodeToNumber(number);
    }
  }, [number]);

  const sendCodeToNumber = async phoneNumber => {
    let response = await sendLatestCodeToNumber(phoneNumber, userToken);
    if (response && response.data.success) {
      toast.success(response.data.description, {
        autoClose: false,
      });
    } else {
      toast.error(response.data.description, {
        autoClose: false,
      });
    }
    setBtnLoader(false);
  };
  const editNumberHandler = async phoneNumber => {
    let response = await editPhoneNumber(phoneNumber);
    if (response && response.data.success) {
      toast.success(response.data.description, {
        autoClose: false,
      });
    } else {
      toast.error(response.data.description, {
        autoClose: false,
      });
    }
    setBtnLoader(false);
  };

  const otpRequestHandlerWithoutToken = async (otp, phoneNumber) => {
    setIsOtpRequest(true);
    let response = await verifyUserOtpWithoutToken(otp, phoneNumber);
    if (response && response.data.success) {
      toast.success(response.data.description);
      setTimeout(() => {
        setIsOtpRequest(false);
        handleClose(true);
      }, 1000);
    } else {
      toast.error(response.data.description);
      setIsOtpRequest(false);
    }
  };
  const otpRequestHandler = async (otp, phoneNumber) => {
    setIsOtpRequest(true);
    let response = await verifyUserOtp(otp, userToken, phoneNumber);
    if (response && response.data.success) {
      toast.success(response.data.description);
      setTimeout(() => {
        setIsOtpRequest(false);
        handleClose(true);
      }, 1000);
    } else {
      toast.error(response.data.description);
      setIsOtpRequest(false);
    }
  };
  function ValidatePhoneNumber(event) {
    return event.replace(/^(\+1)/, '').replace(/^(1)/, '');
  }
  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body>
        <GrClose
          className="mobile-verfication-close"
          onClick={handleClose}
          size={25}
          color={colors.primary}
        />
        <div className="verfication-container">
          <div className="title-verification">
            {allCommonText.heading.phoneVerficationHeading}
            {!profileScreen ? (
              <div>{`${allCommonText.heading.phoneVerficationDescription} ${number}`}</div>
            ) : (
              <>
                <div>
                  {allCommonText.heading.editPhoneVerficationDescription}
                  <div>{allCommonText.labels.sixDigitCode}</div>
                </div>
              </>
            )}
          </div>
          <div className="image-container">
            <img src={images.mobileVerification} />
          </div>
          <div className="input-phone-verification">
            {!profileScreen && (
              <div className="verification-text">
                <span>Note: </span>
                <span>{allCommonText.verificationNote}</span>
              </div>
            )}
            <InputField
              name={'phone'}
              id={'phone'}
              handleChange={e => {
                let inputValue = e.target.value;

                const numericValue = inputValue.replace(/[^0-9+]/g, '');
                const cleanValue = numericValue.startsWith('1')
                  ? numericValue.substring(1)
                  : numericValue;

                const restrictedValue = cleanValue.substring(0, 12);

                setPhoneNumber(restrictedValue);
              }}
              handleBlur={e => {
                let phone = ValidatePhoneNumber(e.target.value);
                setPhoneNumber(phone);
              }}
              value={phoneNumber}
              placeholder={allCommonText.placeholders.phone}
              phone
            />

            {/* <InputField
              name={'phone'}
              id={'phone'}
              handleChange={e => {
                const inputValue = e.target.value;
                const numericValue = inputValue.replace(/[^0-9+]/g, ''); // remove non-numeric characters
                const restrictedValue = numericValue.substring(0, 12); // restrict to 10 digits
                setPhoneNumber(restrictedValue);
              }}
              handleBlur={e => {
                let phone = ValidatePhoneNumber(e.target.value);
                setPhoneNumber(phone);
              }}
              value={phoneNumber}
              placeholder={allCommonText.placeholders.phone}
              phone
            /> */}
            <GenericButton
              buttonText={allCommonText.buttonTexts.sendCode}
              loading={btnLoader}
              onPress={() => {
                setBtnLoader(true);
                if (profileScreen) {
                  editNumberHandler(phoneNumber);
                } else {
                  sendCodeToNumber(phoneNumber);
                }
              }}
            />
          </div>
          <OtpInput
            value={otp}
            onChange={e => {
              setOtp(e);
              if (e.length === 6) {
                if (profileScreen) {
                  otpRequestHandlerWithoutToken(e, phoneNumber);
                } else {
                  otpRequestHandler(e, phoneNumber);
                }
              }
            }}
            numInputs={6}
            renderSeparator={<span> {'  '} </span>}
            containerStyle={'otp-container'}
            inputStyle="otp-inputStyle"
            renderInput={props => <input disabled={isOtpRequest} {...props} />}
          />
          {isOtpRequest && (
            <div className="loader-container">
              <Loader />
            </div>
          )}
          {/* <div className="send-new-code">
            {allCommonText.buttonTexts.sendNewCode}
          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="mobile-verfication-footer">
          <span>
            {allCommonText.labels.cantAccess}
            <a href={allCommonText.urls.home}>
              {allCommonText.buttonTexts.contactUs}
            </a>
          </span>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
