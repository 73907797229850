import React, {useState} from 'react';
import './style.scss';
import Grid from '@mui/material/Grid';
import {GenericButton, GenericModal, InputField} from '../../components';
import {images} from '../../common/images';
import {allCommonText, signinText} from '../../common/constants';
import {Formik} from 'formik';
import {resetSchema} from '../../common/schemas';
import {useLocation, useNavigate} from 'react-router';
import {path} from '../../common/routesNames';
import {
  resetUserPassword,
  setCustomerAdminPassword,
} from '../../utils/rest-services/index';

function ResetPassword() {
  const initialValues = {
    confirmPassword: '',
    password: '',
  };
  const {resetPassword, resetDetails} = signinText;
  const [btnLoading, setBnLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [apiResponse, setApiResponse] = useState({
    type: allCommonText.popupEnums.success,
    title: '',
    details: '',
  });
  let navigate = useNavigate();

  const location = useLocation();
  const getParamFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  let query = getParamFromUrl();
  const newPassword = query.get('nu');
  const resetPasswordHandler = async (values, formik) => {
    setBnLoading(true);
    const token = query.get('tok');
    const id = query.get('id');
    const employeeEncrypted = query.get('ec');
    var response;
    if (newPassword == 1) {
      let payloadObject = {
        signupId: employeeEncrypted,
        employeeId: '',
        token: '',
        password: values.password,
      };
      response = await setCustomerAdminPassword(payloadObject);
    } else {
      let payloadObject = {
        signupId: id,
        token: token,
        password: values.password,
      };
      response = await resetUserPassword(payloadObject);
    }
    if (response && response.data.success) {
      formik.resetForm();
      setApiResponse({
        type: allCommonText.popupEnums.success,
        title: allCommonText.success.toUpperCase(),
        details: response.data.description,
      });
    } else {
      setApiResponse({
        type: allCommonText.popupEnums.error,
        title: allCommonText.error.toUpperCase(),
        details: response.data.description,
      });
    }
    setShowModal(true);
    setBnLoading(false);
  };
  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={true}
      onSubmit={(values, formikActions) => {
        resetPasswordHandler(values, formikActions);
      }}
      validationSchema={resetSchema}
      enableReinitialize>
      {({handleChange, handleBlur, handleSubmit, touched, errors}) => (
        <div>
          <Grid container spacing={0}>
            <Grid item xs={12} xl={5.5} lg={5.5} md={5.5}>
              <div className="login-section">
                <div className="login-fields-container">
                  <span className="login-text">
                    {newPassword == 1 ? 'Add Password' : resetPassword}
                  </span>
                  <span className="forgot-text-details">{resetDetails}</span>
                  <InputField
                    name={'password'}
                    id={'password'}
                    placeholder={'Password'}
                    error={errors.password}
                    touched={touched.password}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isPassword
                  />
                  <InputField
                    name={'confirmPassword'}
                    id={'confirmPassword'}
                    placeholder={'Confirm Password'}
                    error={errors.confirmPassword}
                    touched={touched.confirmPassword}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isPassword
                  />
                  <GenericButton
                    id="ResetPassword-btn"
                    loading={btnLoading}
                    onPress={handleSubmit}
                    buttonText={
                      newPassword == 1 ? 'Add Password' : resetPassword
                    }
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} xl={6.5} lg={6.5} md={6.5}>
              <div
                style={{
                  backgroundImage: `url(${images.background})`,
                }}
                className="image-section"></div>
            </Grid>
          </Grid>
          <GenericModal
            show={showModal}
            btnText={allCommonText.ok}
            type={apiResponse.type}
            body={apiResponse.details}
            handleClose={() => {
              setShowModal(false);
              if (apiResponse.type === allCommonText.popupEnums.success) {
                navigate(path.login);
              }
            }}
          />
        </div>
      )}
    </Formik>
  );
}

export default ResetPassword;
