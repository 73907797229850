import React, {useState, useRef, useEffect} from 'react';
import {useNavigate} from 'react-router';
import {Grid} from '@mui/material';
import {Formik} from 'formik';
import {allCommonText, plans} from '../../common/constants';
import {path} from '../../common/routesNames';
import {addVehicleSchema, editVehicleSchema} from '../../common/schemas';
import {
  addItemToCart,
  getVehicleModelListById,
} from '../../utils/rest-services';
import {
  TextInputField,
  CriteriaDropdown,
  GenericButton,
  Loader,
} from '../index';
import '../../App.scss';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {images} from './../../common/images';
import {ViewCartButton} from './../view-cart-button/index';

export const AddVehicleInputs = ({
  carrierItem,
  makerItem,
  deductibleItem,
  setPopUpResponse,
  setSowModal,
  selectedCartItems,
  loadingData,
  onItemAddedToCart,
}) => {
  useEffect(() => {
    getSelectedItems(selectedCartItems);
  }, []);

  const [modelItem, setModelItem] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    carrier: null,
    maker: null,
    model: null,
    deductible: null,
    otherDeductibles: '',
    year: null,
  });
  const [isOtherDeductibles, setIsOtherDeductibles] = useState(false);
  const [isOtherCarrier, setIsOtherCarrier] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const carrierRef = useRef();
  const deductiblesRef = useRef();
  const {hiddenKeys, serviceTypeEnums, error} = allCommonText;

  let navigate = useNavigate();

  const fetchVehicleModelList = async makerId => {
    let response = await getVehicleModelListById(
      allCommonText.serviceTypeEnums.vehicle,
      makerId,
    );
    if (response && response.success) {
      const {content} = response;
      setModelItem(content || []);
    }
  };

  function isEmptyObject(obj) {
    return !Object.keys(obj).length;
  }

  const numberWithCommas = x => {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
    return x;
  };

  const getSelectedItems = selectedCartItems => {
    const isObjectEmpty = isEmptyObject(selectedCartItems);
    if (!isObjectEmpty) {
      // check weather it is in edit mode or add mode
      const {
        serviceProviderName,
        serviceProviderId,
        deductibleAmount,
        make,
        model,
        year,
      } = selectedCartItems || {};
      let deductible = `$${numberWithCommas(deductibleAmount)}.00`;
      let index = deductibleItem.findIndex(item => item.value == deductible);
      const carrierObj = {
        value: serviceProviderName,
        label: serviceProviderName,
        id: serviceProviderId,
      };
      const makerObj = {
        value: make,
        label: make,
        id: 0,
      };
      const modelObj = {
        value: model,
        label: model,
        id: 0,
      };
      const deductibleObj = {
        value: deductible,
        label: deductible,
        id: 0,
      };
      const otherDeductibleObj = {
        id: -1,
        value: allCommonText.otherDeductibles,
        label: allCommonText.otherDeductibles,
      };

      if (index === -1) {
        setIsOtherDeductibles(true);
        setSelectedOption({
          carrier: carrierObj,
          maker: makerObj,
          model: modelObj,
          year: year,
          otherDeductibles: deductibleAmount.toString(), // here is difference
          deductible: otherDeductibleObj,
        });
      } else {
        setIsOtherDeductibles(false);
        setSelectedOption({
          carrier: carrierObj,
          maker: makerObj,
          model: modelObj,
          year: year,
          deductible: deductibleObj, // here is difference
        });
      }
      setIsEditable(true); // make component ready for edit or add
    } else {
      setSelectedOption({
        carrier: null,
        maker: null,
        model: null,
        deductible: null,
        otherDeductibles: '',
        year: null,
      });
      setIsEditable(false);
    }
  };

  const AddVehicleToCart = async (values, actions) => {
    let AddToCartPayload;
    if (isEditable) {
      AddToCartPayload = {
        serviceTypeId: serviceTypeEnums.vehicle.toString(),
        serviceProviderId: selectedCartItems.serviceProviderId,
        otherServiceProvider: null,
        deductibleAmount:
          values.deductibles.id === hiddenKeys.notAvailableFieldId
            ? values.otherDeductibles
            : values.deductibles.id,
        make: selectedCartItems.make,
        model: selectedCartItems.model,
        year: selectedCartItems.year,
        cartItemId: selectedCartItems.cartItemId,
      };
    } else {
      AddToCartPayload = {
        serviceTypeId: serviceTypeEnums.vehicle.toString(),
        serviceProviderId: values.carrier.id,
        otherServiceProvider: values.otherCarrier,
        deductibleAmount:
          values.deductibles.id === hiddenKeys.notAvailableFieldId
            ? values.otherDeductibles
            : values.deductibles.id,
        make: values.maker.value,
        model: values.model.value,
        year: values.year,
        cartItemId: 0,
      };
    }

    let result = await addItemToCart(AddToCartPayload);
    if (result && result.success) {
      toast(
        <div className="cart-icon-toaster">
          <img src={images.cartSmall} />
          <span className="toast-text">{result.description}</span>
        </div>,
      );
      onItemAddedToCart();
      if (isEditable) {
        navigate({
          pathname: path.plans,
        });
      }
    } else {
      setPopUpResponse({
        type: allCommonText.popupEnums.error,
        title: error,
        description: result.description,
      });
      setSowModal(true);
    }
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        carrier: null,
        maker: null,
        model: null,
        year: '',
        deductibles: selectedOption.deductible || null,
        otherDeductibles: selectedOption.otherDeductibles || '',
        otherCarrier: '',
      }}
      onSubmit={(values, formikActions) => {
        AddVehicleToCart(values, formikActions);
      }}
      validationSchema={
        isEditable
          ? editVehicleSchema(isOtherDeductibles)
          : addVehicleSchema(isOtherDeductibles, isOtherCarrier)
      }
      enableReinitialize={true}>
      {({
        handleChange,
        handleSubmit,
        touched,
        errors,
        values,
        setFieldValue,
        isSubmitting,
      }) => (
        <>
          {loadingData ? (
            <Loader customContainerClass="loader-container-plan" />
          ) : (
            <div className="selectCriteria">
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <div className="selectOptionCOntainer">
                    <label htmlFor="" className="plan-label">
                      {plans.carrier_}
                    </label>
                    <CriteriaDropdown
                      options={carrierItem}
                      isDisable={isEditable}
                      name={'carrier'}
                      selectedOption={selectedOption.carrier || values.carrier}
                      error={touched.carrier && errors.carrier}
                      dropdowntitle={plans.selectCriteria}
                      onChangeOption={e => {
                        isEditable && setSelectedOption({carrier: e});
                        setFieldValue('carrier', e);
                        handleChange('carrier');
                        if (e.id === hiddenKeys.notAvailableFieldId) {
                          setIsOtherCarrier(true);
                          setTimeout(() => {
                            carrierRef?.current?.focus();
                          }, 500);
                        } else {
                          setIsOtherCarrier(false);
                        }
                      }}
                    />
                  </div>
                </Grid>
                {values?.carrier?.id === hiddenKeys.notAvailableFieldId && (
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <label htmlFor="" className="plan-label">
                      {plans.otherCarrier_}
                    </label>
                    <TextInputField
                      value={values.otherCarrier}
                      disable={isEditable}
                      setValue={e => {
                        setFieldValue('otherCarrier', e.target.value);
                        handleChange('otherCarrier');
                      }}
                      placeHolder={plans.otherCarrierName}
                      name={allCommonText.otherCarrier}
                      error={touched.otherCarrier && errors.otherCarrier}
                      reff={carrierRef}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <label htmlFor="" className="plan-label">
                    {plans.maker_}
                  </label>
                  <CriteriaDropdown
                    options={makerItem}
                    name={'maker_'}
                    selectedOption={selectedOption.maker || values.maker}
                    error={touched.maker && errors.maker}
                    isDisable={isEditable}
                    dropdowntitle={plans.selectMaker}
                    onChangeOption={async e => {
                      handleChange('maker');
                      setFieldValue('maker', e);
                      await fetchVehicleModelList(e.id);
                      setFieldValue('model', null);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <label htmlFor="" className="plan-label">
                    {plans.model_}
                  </label>
                  <CriteriaDropdown
                    options={modelItem}
                    error={touched.model && errors.model}
                    name={allCommonText.modal}
                    selectedOption={selectedOption.model || values.model}
                    isDisable={isEditable}
                    dropdowntitle={plans.selectModel}
                    onChangeOption={e => {
                      handleChange('model');
                      setFieldValue('model', e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <label htmlFor="" className="plan-label">
                    {plans.year_}
                  </label>
                  <TextInputField
                    value={selectedOption.year || values.year}
                    setValue={e => {
                      const inputValue = e.target.value;
                      const numericValue = inputValue.replace(/[^0-9+]/g, ''); // remove non-numeric characters
                      const restrictedValue = numericValue.substring(0, 4); // restrict to 4 digits
                      setFieldValue('year', restrictedValue);
                      handleChange('year');
                    }}
                    placeHolder={plans.enterYear}
                    disable={isEditable}
                    name={allCommonText.year}
                    error={touched.year && errors.year}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <label htmlFor="" className="plan-label">
                    {plans.deductibles_}
                  </label>
                  <CriteriaDropdown
                    options={deductibleItem}
                    error={touched.deductibles && errors.deductibles}
                    dropdowntitle={plans.selectDeductible}
                    name={allCommonText.deductibles}
                    selectedOption={
                      selectedOption.deductible || values.deductibles
                    }
                    onChangeOption={e => {
                      isEditable &&
                        setSelectedOption(other => ({
                          ...other,
                          deductible: e,
                        }));
                      handleChange('deductibles');
                      setFieldValue('deductibles', e);
                      if (e.id === hiddenKeys.notAvailableFieldId) {
                        setIsOtherDeductibles(true);
                        setTimeout(() => {
                          deductiblesRef?.current?.focus();
                        }, 500);
                      } else {
                        setIsOtherDeductibles(false);
                      }
                    }}
                  />
                </Grid>
                {(values?.deductibles?.id === hiddenKeys.notAvailableFieldId ||
                  isOtherDeductibles) && (
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <label htmlFor="" className="plan-label">
                      {plans.otherDeductibles_}
                    </label>
                    <TextInputField
                      value={
                        selectedOption.otherDeductibles ||
                        values.otherDeductibles
                      }
                      setValue={e => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9.]/g, ''); // remove non-numeric characters
                        const restrictedValue = numericValue.substring(0, 7); // restrict to 10 digits
                        setFieldValue('otherDeductibles', restrictedValue);
                        handleChange('otherDeductibles');
                        isEditable &&
                          setSelectedOption(other => ({
                            ...other,
                            otherDeductibles: e.target.value,
                          }));
                      }}
                      placeHolder={plans.otherDeductiblesAmount}
                      name={allCommonText.otherDeductibles}
                      error={
                        touched.otherDeductibles && errors.otherDeductibles
                      }
                      maxLength={7}
                      reff={deductiblesRef}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          )}
          <div className="cartContainer mt-3">
            <GenericButton
              id="container-cart"
              loading={isSubmitting}
              buttonText={isEditable ? plans.editCart : plans.AddToCart}
              onPress={handleSubmit}
            />
            <ViewCartButton />
          </div>
        </>
      )}
    </Formik>
  );
};
