import React, {useState, useEffect} from 'react';
import './styles.scss';
import {allCommonText} from '../../../../common/constants';
import {images} from '../../../../common';
import {planCategory} from '../../policy-and-claims';
import {Loader} from '../../../../components';
import {getRefundById} from '../../../../utils/rest-services';
import {path} from '../../../../common/routesNames';
import {useNavigate} from 'react-router';

function RefundDetail() {
  const [refundItemList, setRefundItemList] = useState([]);
  const [refundDetail, setRefundDetail] = useState({});
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const getParamsFromUrl = () => {
    return new URLSearchParams(location.search);
  };

  let query = getParamsFromUrl();
  const refundId = query.get('id');

  useEffect(() => {
    fetchRefundDetails(refundId);
  }, []);

  const fetchRefundDetails = async () => {
    setLoader(true);
    let response = await getRefundById(refundId.split('_')[1]);
    if (response && response.data.success) {
      const {
        data: {
          content: {refund, items},
        },
      } = response;
      setLoader(false);
      setRefundDetail(refund);
      setRefundItemList(items);
    }
  };

  const RefundDetailsContent = ({title, Value, valueAmount}) => {
    return (
      <div className="ref-detail-content">
        <span className="ref-detail-content-title">{title}</span>
        <span className={valueAmount ? 'boldColor' : ''}>{Value}</span>
      </div>
    );
  };

  const InvoiceItem = ({
    name,
    deductible,
    cost,
    serviceTypeId,
    make,
    model,
    address,
  }) => {
    return (
      <div className="invoice-item-view">
        <div className="plan-icon">
          <img src={planSelectorIcon(serviceTypeId)} alt="" />
        </div>
        <div className="invoice-detail-card">
          <div className="primary-card-container-invoice ">
            <span>{name}</span>
            {make && <span>{`Make: ${make}`} </span>}
            {model && <span>{`Model: ${model}`} </span>}
            {address && <span>{`Address: ${address}`} </span>}
          </div>
          <div className="secondary-card-container-invoice">
            <span>
              {`${allCommonText.popupEnums.subscriptionPlanHeadings.deductibles}: ${deductible}`}
            </span>
            <div>
              <span> {`${allCommonText.labels.cost} ${cost}`} </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const planSelectorIcon = id => {
    switch (id) {
      case allCommonText.serviceTypeEnums.vehicle:
        return images.vehicleV;
      case allCommonText.serviceTypeEnums.home:
        return images.homeV;
      case allCommonText.serviceTypeEnums.device:
        return images.devicesV;
      case allCommonText.serviceTypeEnums.commercial:
        return images.commercialV;
      case allCommonText.serviceTypeEnums.disaster:
        return images.disasterIcon;
    }
  };

  return loader ? (
    <div className="loader-container-invoice w-100">
      <Loader />
    </div>
  ) : (
    <div className="main-subscription-container ref-detail-pad">
      <div className="ref-detail-header">
        <div className="ref-detail-title">
          <img
            src={images.redirectIcon}
            alt=""
            onClick={() => navigate({pathname: path.refund})}
          />
          <h3>{allCommonText.profile.refundDetails}</h3>
        </div>
        <div className="ref-detail-badges">
          <span className={planCategory('refundDetail.refundStatus')}>
            {refundDetail.refundStatus}
          </span>
          <span className={planCategory(refundDetail.subscriptionType)}>
            {refundDetail.subscriptionType}
          </span>
        </div>
      </div>
      <div className="ref-detail-content-box">
        <RefundDetailsContent
          title={allCommonText.labels.refundID}
          Value={refundDetail.refundId}
        />
        <RefundDetailsContent
          title={allCommonText.labels.subscriptionId}
          Value={refundDetail.viewSubscriptionId}
        />
      </div>
      <div className="ref-detail-content-box">
        <RefundDetailsContent
          title={allCommonText.labels.refundType}
          Value={refundDetail.refundType}
        />
        <RefundDetailsContent
          title={allCommonText.labels.refundDate}
          Value={refundDetail?.refundDate?.replace(/\//g, '-')}
        />
      </div>
      <div className="ref-detail-content-box">
        <RefundDetailsContent
          title={allCommonText.labels.totalAmount}
          Value={refundDetail.refundAmount}
          valueAmount={true}
        />
      </div>

      <div className="invoice-container-v2">
        <span className="invoice-title">
          {allCommonText.heading.refundsItems}
        </span>
        <div className="invoice-item-details">
          {refundItemList?.map(i => (
            <InvoiceItem
              key={i}
              make={i?.make}
              model={i?.model}
              serviceTypeId={i.serviceTypeId}
              cost={`$${parseFloat(i.cost).toFixed(2)}`}
              address={i.address}
              deductible={`$${parseFloat(i.deductibleAmount).toFixed(2)}`}
              name={i.providerName}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default RefundDetail;
