import React, {useState, useEffect, useContext, useRef} from 'react';
import {Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import {GenericButton} from '../generic-button';
import {allCommonText} from '../../common/constants';
import {GrClose} from 'react-icons/gr';
import {colors, images} from '../../common';
import './style.scss';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import ApplicationContext from '../../utils/context-api/context';
import {updateSubscription} from '../../utils/rest-services';
import {toast} from 'react-toastify';

export function AddPaymentMethod({
  show,
  handleClose,
  existingCards,
  onSubscriptionScreen,
  subscriptionId,
  stripeSubscriptionId,
  stripePaymentMethodId,
}) {
  const [isCardSave, setIsCardSave] = useState(false);
  const [addNewSelected, setAddNewSelected] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [cardName, setCardName] = useState('');
  const [slectedPaymentCardId, setSlectedPaymentCardId] = useState(-1);
  const [btnLoading, setBtnLoading] = useState(false);
  const [currentCard, setCurrentCard] = useState({});
  const [stripeError, setStripeError] = useState({
    cardName: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  });

  const updateSubscriptionHandler = async payload => {
    let response = await updateSubscription(payload);
    if (response && response.data.success) {
      setBtnLoading(false);
      toast.success(response.data.description);
      handleClose(response.data.success);
    } else {
      setBtnLoading(false);
      toast.error(response.data.description);
    }
  };

  const handleCardFieldChange = (event, name) => {
    if (event.error) {
      setStripeError(other => ({other, [name]: event.error.message}));
    } else {
      setStripeError(other => ({other, [name]: null}));
    }
  };
  const {setSelectedCard, selectedCard} = useContext(ApplicationContext);
  const stripe = useStripe();
  const elements = useElements();
  const myRef = useRef(null);

  const scrollToRef = () => {
    myRef?.current?.scrollIntoView({behavior: 'smooth'});
  };

  useEffect(() => {
    if (show) {
      if (onSubscriptionScreen) {
        let selectedCard = existingCards?.find(
          e => e.stripePaymentMethodId == stripePaymentMethodId,
        );
        setSlectedPaymentCardId(parseInt(selectedCard?.paymentInfoId));
        const newCardObj = {
          paymentMethodId: selectedCard?.stripePaymentMethodId,
          cardType: selectedCard?.cardType,
          last4: selectedCard?.cardNumber,
          isCardSave: false,
          cardName: '',
          newCard: false,
          temporaryCard: false,
        };
        setCurrentCard(newCardObj);
        scrollToRef();
        return;
      }
      setBtnLoader(false);
      setStripeError(null);
      setCardName('');
      // selectedCard?.temporaryCard && setSelectedCard(null);   // for the time being not discard new card untill user select new card
      if (existingCards?.length > 0) {
        scrollToRef();
        setAddNewSelected(false);
      }
      if (existingCards?.length > 0 && !selectedCard) {
        setSlectedPaymentCardId(parseInt(existingCards[0]?.paymentInfoId));
        const item = existingCards[0];
        const newCardObj = {
          paymentMethodId: item.stripePaymentMethodId,
          cardType: item.cardType,
          last4: item.cardNumber,
          isCardSave: false,
          cardName: '',
          newCard: false,
          temporaryCard: false,
        };
        setCurrentCard(newCardObj);
      } else {
        setAddNewSelected(false);
      }
    }
  }, [show]);

  const CreateCardStripe = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const cardNumber = elements.getElement(CardNumberElement);
    const cardExpiry = elements.getElement(CardExpiryElement);
    const cardCvc = elements.getElement(CardCvcElement);
    const isValidCard = cardNumber._empty || cardNumber._invalid;
    const isValidExpiry = cardExpiry._empty || cardExpiry._invalid;
    const isValidCvc = cardCvc._empty || cardCvc._invalid;

    if (isValidCard || isValidExpiry || isValidCvc || cardName == '') {
      setStripeError(other => ({
        other,
        cardName: cardName == '' ? allCommonText.errorMessage.cardName : null,
        cardNumber: isValidCard && allCommonText.errorMessage.cardNumber,
        cardExpiry: isValidExpiry && allCommonText.errorMessage.cardExpiry,
        cardCvc: isValidCvc && allCommonText.errorMessage.cardCvc,
      }));
      return;
    }

    setBtnLoader(true);
    const {error, token} = await stripe.createToken(
      elements.getElement(CardNumberElement),
    );
    if (error) {
      alert(error.message);
      setBtnLoader(false);
    } else {
      const {paymentMethod, error} = await stripe.createPaymentMethod({
        type: 'card',
        card: {
          token: token.id,
        },
      });
      const {
        id,
        card: {last4, brand},
      } = paymentMethod;
      if (paymentMethod) {
        if (onSubscriptionScreen) {
          let payload = {
            subscriptionId: subscriptionId,
            stripeSubscriptionId: stripeSubscriptionId,
            paymentMethodId: id,
            newCard: true,
            isCardSave: true,
            nameOnCard: cardName,
          };
          await updateSubscriptionHandler(payload);
          setBtnLoader(false);
        } else {
          setBtnLoader(false);
          const newCardObj = {
            paymentMethodId: id,
            cardType: brand,
            last4,
            isCardSave,
            cardName,
            newCard: true,
            temporaryCard: true,
          };
          setSelectedCard(newCardObj);
          handleClose();
        }
      } else if (error) {
        alert(error.message);
      }
    }
  };

  const onCardSelectHandler = item => {
    setSlectedPaymentCardId(parseInt(item.paymentInfoId));
    setAddNewSelected(false);
    const newCardObj = {
      paymentMethodId: item.stripePaymentMethodId,
      cardType: item.cardType,
      last4: item.cardNumber,
      isCardSave: false,
      cardName: '',
      newCard: false,
      temporaryCard: false,
    };
    setCurrentCard(newCardObj);
  };
  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="add-payment-popup-wrapper">
          <div className="top-add-payment-container">
            <span>{allCommonText.selectPayment} </span>
            <GrClose
              className="add-payment-close-btn"
              onClick={handleClose}
              size={25}
              color={colors.primary}
            />
          </div>
          <div className={`add-payment-main-content`}>
            <GenericButton
              id="add-payment"
              onPress={() => {
                setAddNewSelected(!addNewSelected);
                setSlectedPaymentCardId(-1);
              }}
              buttonText={allCommonText.buttonTexts.addCard}
              disable={existingCards?.length >= 5}
            />
            {existingCards?.length >= 5 && (
              <div className="card-limit-exceed">
                <span>{allCommonText.note}</span>
                <span> {allCommonText.billingCardLimit} </span>
              </div>
            )}

            {existingCards?.length === 0 && !addNewSelected && (
              <div className="no-card-available">
                <img src={images.noCardAvailable} />
              </div>
            )}
            {addNewSelected && (
              <div className="add-payment-card-option">
                <div className="payment-title">
                  {allCommonText.heading.addNewCard}
                </div>
                <>
                  <div className="holder-name-container">
                    <label>{allCommonText.labels.name} </label>
                    <input
                      placeholder={allCommonText.placeholders.enterHolderName}
                      type={'text'}
                      className="card-holder-name"
                      value={cardName}
                      onChange={e => {
                        const {value} = e.target;
                        const re = /^[A-Za-z ]+$/;
                        if (value === '' || re.test(value)) {
                          setCardName(value);
                          setStripeError(other => ({
                            other,
                            cardName: null,
                          }));
                        }
                      }}
                    />
                    {stripeError?.cardName != '' && (
                      <span className="error-field">
                        {stripeError?.cardName}
                      </span>
                    )}
                  </div>
                  <label>{allCommonText.labels.cardNumber} </label>
                  <div className="card-number">
                    <CardNumberElement
                      onChange={i => handleCardFieldChange(i, 'cardNumber')}
                      required
                      options={{
                        showIcon: true,
                      }}
                    />
                  </div>
                  {stripeError?.cardNumber != '' && (
                    <span className="error-field">
                      {stripeError?.cardNumber}
                    </span>
                  )}
                </>
                <div className="expiry-cvc-container">
                  <div className="expiry-container">
                    <label>{allCommonText.labels.expiry} </label>
                    <div className="card-expiry">
                      <CardExpiryElement
                        onChange={i => handleCardFieldChange(i, 'cardExpiry')}
                        required
                      />
                    </div>
                    {stripeError?.cardExpiry != '' && (
                      <span className="error-field">
                        {stripeError?.cardExpiry}
                      </span>
                    )}
                  </div>
                  <div className="cvv-container">
                    <label>{allCommonText.labels.cvv} </label>
                    <div className="card-cvc">
                      <CardCvcElement
                        onChange={i => handleCardFieldChange(i, 'cardCvc')}
                        required
                      />
                    </div>
                    {stripeError?.cardCvc != '' && (
                      <span className="error-field">
                        {stripeError?.cardCvc}
                      </span>
                    )}
                  </div>
                </div>
                {onSubscriptionScreen && <div style={{height: 10}} />}
                {!onSubscriptionScreen && (
                  <div class="form-check save-card">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked={isCardSave}
                      onChange={d => {
                        setIsCardSave(d.target.checked);
                      }}
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {allCommonText.labels.saveCard}
                    </label>
                  </div>
                )}
                <div className="save-btn-container">
                  <GenericButton
                    id="save-button"
                    buttonText={allCommonText.buttonTexts.saveAndcontinue}
                    onPress={CreateCardStripe}
                    loading={btnLoader}
                  />
                </div>
              </div>
            )}
          </div>
          {existingCards?.length > 0 && (
            <div className="mt-3">
              <span className="cards-count">{`Available Cards (${existingCards?.length})`}</span>
              <div className="existing-card-container">
                {existingCards?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      onCardSelectHandler(item);
                    }}
                    className="existing-card-info">
                    <input
                      ref={
                        slectedPaymentCardId == parseInt(item.paymentInfoId)
                          ? myRef
                          : null ||
                            stripePaymentMethodId == item.stripePaymentMethodId
                          ? myRef
                          : null
                      }
                      class="form-check-input"
                      type="radio"
                      name="radio-group-1"
                      value={`name-${index}`}
                      id="flexRadioDefault2"
                      key={index}
                      onClick={() => {
                        onCardSelectHandler(item);
                      }}
                      checked={
                        slectedPaymentCardId == parseInt(item.paymentInfoId)
                      }
                      onChange={() => {
                        onCardSelectHandler(item);
                      }}
                    />
                    <i
                      class={`fa-brands fa-cc-${item.cardType}`}
                      style={{color: colors.primary}}></i>
                    <span>{`**** **** **** ${item?.cardNumber}`}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <GenericButton
          id="add-payment-modal"
          disable={slectedPaymentCardId == -1}
          loading={btnLoading}
          onPress={() => {
            if (onSubscriptionScreen) {
              setBtnLoading(true);
              updateSubscriptionHandler({
                subscriptionId: subscriptionId,
                stripeSubscriptionId: stripeSubscriptionId,
                paymentMethodId: currentCard.paymentMethodId,
                newCard: false,
              });
            } else {
              handleClose();
              setSelectedCard(currentCard);
            }
          }}
          buttonText={
            onSubscriptionScreen
              ? allCommonText.buttonTexts.updateCard
              : allCommonText.buttonTexts.continue
          }
        />
      </Modal.Footer>
    </Modal>
  );
}
