import React, {useState, useEffect} from 'react';
import {
  CustomizedSearch,
  InvoiceAndSubscriptionDetails,
  PolicyCard,
  Loader,
  GenericModal,
  ConfirmCancellationPopup,
  ActionDropDownPopUp,
  AddPaymentMethod,
} from '../../../components';
import './styles.scss';
import '../../../components/policy-card/styles.scss';
import {
  cancelSubscription,
  getAllSubscriptions,
  getPaymentDefaultCards,
} from '../../../utils/rest-services';
import Pagination from '@mui/material/Pagination';
import {allCommonText} from '../../../common/constants';
import {colors} from '../../../common';
import {toast} from 'react-toastify';
import {planCategory} from '../policy-and-claims';
import {path} from '../../../common/routesNames';
import {useNavigate} from 'react-router';
import {FiEdit2} from 'react-icons/fi';
import {MdDelete, MdOutlineManageSearch} from 'react-icons/md';

export default function Subscriptions() {
  const [showDetails, setShowDetails] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [selectedId, setSelectedId] = useState(0);
  const [cancelPolicyPopup, setCancelPolicyPopup] = useState(false);
  const [isCancelPolicy, setIsCancelPolicy] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [currentStatus, setCurrentStatus] = useState(0);
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState(0);
  const [selectedSubscriptionItem, setSelectedSubscriptionItem] = useState({});
  const [currentSubscriptionType, setCurrentSubscriptionType] = useState(0);
  const [addPaymentMethodPopUp, setAddPaymentMethodPopUp] = useState(false);
  const [popUpResponse, setPopUpResponse] = useState({
    title: '',
    type: allCommonText.popupEnums.alert,
    description: allCommonText.policyClaims.cancelSubscriptionmessage,
  });
  const [subscriptionIds, setSubscriptionIds] = useState({
    stripeSubscriptionId: '',
    subscriptionId: '',
  });
  const [existingCards, setExistingCards] = useState([]);

  useEffect(() => {
    let payload = {
      pageNo: currentPage,
      rowsPerPage: 10,
      status: currentStatus,
      id: 0,
      subscriptionType: 0,
    };
    getPaymentCards();
    fetchCustomerSubscription(payload);
  }, [currentPage]);

  useEffect(() => {
    if (isCancelPolicy) {
      setCancelReason('');
    }
  }, [isCancelPolicy]);

  const getPaymentCards = async () => {
    let result = await getPaymentDefaultCards();
    const {
      data: {success, content},
    } = result || {};
    if (result && success) {
      setExistingCards(content);
    } else {
      setExistingCards([]);
    }
  };
  const fetchCustomerSubscription = async payload => {
    setPageLoader(true);
    let response = await getAllSubscriptions(payload);
    if (response && response.data.success) {
      const {
        data: {
          content: {list, totalPages},
        },
      } = response;
      if (list && list?.length > 0) {
        setSubscriptionList(list);
        setTotalPages(totalPages);
      } else {
        setSubscriptionList([]);
        setTotalPages(1);
      }
      setPageLoader(false);
    } else {
      setPageLoader(false);
      toast.error(response?.data?.description || allCommonText.somethingWrong, {
        autoClose: false,
      });
    }
  };
  const getInvoiceStatus = status => {
    if (!status) {
      return (
        <span class="badge rounded-pill bg-danger text-light">
          {allCommonText.labels.subscriptionStatus.cancel}
        </span>
      );
    } else {
      return (
        <span class="badge rounded-pill bg-success">
          {allCommonText.labels.subscriptionStatus.active}
        </span>
      );
    }
  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const getStatusByText = text => {
    if (text == allCommonText.labels.subscriptionStatus.all) {
      return 0;
    } else if (text == allCommonText.labels.subscriptionStatus.active) {
      return 1;
    } else {
      return 2;
    }
  };
  const getSubscriptionType = type => {
    if (type.toLowerCase() == 'all') {
      return 0;
    } else if (type.toLowerCase() == 'monthly') {
      return allCommonText.policyClaims.month;
    } else if (type.toLowerCase() == 'annual') {
      return allCommonText.policyClaims.annual;
    } else {
      return allCommonText.policyClaims.biAnnual;
    }
  };
  const confirmCancelSubscriptionHandler = async reason => {
    const payload = {
      cancelReason: reason,
      stripeSubscriptionId: subscriptionIds.stripeSubscriptionId,
      subscriptionId: subscriptionIds.subscriptionId,
    };
    setBtnLoader(true);
    let result = await cancelSubscription(payload);
    if (result && result.data.success) {
      let customerPayload = {
        pageNo: currentPage,
        rowsPerPage: 10,
        status: currentStatus,
        id: currentSubscriptionId == '' ? 0 : currentSubscriptionId,
        subscriptionType: currentSubscriptionType,
      };
      setPopUpResponse({
        type: allCommonText.popupEnums.success,
        description: result.data.description,
      });
      setCancelPolicyPopup(true);
      fetchCustomerSubscription(customerPayload);
      setBtnLoader(false);
      setIsCancelPolicy(false);
    } else {
      setPopUpResponse({
        type: allCommonText.popupEnums.error,
        description: result.data.description,
      });
      setBtnLoader(false);
      setCancelPolicyPopup(true);
    }
  };

  const onSearchHandler = async (
    subscriptionId,
    id,
    status,
    subscriptionType,
    isClear,
  ) => {
    let payload;
    if (isClear) {
      setCurrentStatus(0);
      setCurrentSubscriptionId(0);
      setCurrentSubscriptionType(0);
      payload = {
        pageNo: 1,
        rowsPerPage: 10,
        status: 0,
        id: 0,
        subscriptionType: 0,
      };
    } else {
      setCurrentStatus(getStatusByText(status));
      setCurrentSubscriptionId(subscriptionId == '' ? 0 : subscriptionId);
      setCurrentSubscriptionType(getSubscriptionType(subscriptionType));
      payload = {
        pageNo: 1,
        rowsPerPage: 10,
        status: getStatusByText(status),
        id: subscriptionId == '' ? 0 : subscriptionId.replace(/\D/g, ''),
        subscriptionType: getSubscriptionType(subscriptionType),
      };
    }

    setCurrentPage(1);
    setBtnLoader(true);
    await fetchCustomerSubscription(payload);
    setBtnLoader(false);
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="main-subscription-container">
        <h3>{allCommonText.profile.subscriptions}</h3>
        <CustomizedSearch
          firstLabel={allCommonText.labels.subscriptionId}
          firstPlaceholder={`${allCommonText.placeholders.subscriptionId}`}
          firstPrefixValue={'sub_'}
          subscriptionType
          subscriptionTypeLabel={allCommonText.labels.subscriptionType}
          secondPlaceholder={`${allCommonText.labels.enter} ${allCommonText.placeholders.subscriptionType}`}
          subscriptionStatus={[
            allCommonText.policyClaims.all,
            allCommonText.policyClaims.monthly,
            allCommonText.policyClaims.biAnnual,
            allCommonText.policyClaims.annual,
          ]}
          thirdLabel={allCommonText.labels.viewByStatus}
          status={[
            allCommonText.labels.subscriptionStatus.all,
            allCommonText.labels.subscriptionStatus.active,
            allCommonText.labels.subscriptionStatus.cancel,
          ]}
          btnLoader={btnLoader}
          title={allCommonText.heading.searchSubscription}
          onClearFilterHandler={() => {
            onSearchHandler('', '', '', '', true);
          }}
          onSearchBtnPress={(subscriptionId, id, status, subscriptionType) =>
            onSearchHandler(subscriptionId, id, status, subscriptionType)
          }
        />
        {pageLoader ? (
          <div className="no-subscription-container">
            <Loader size={40} />
          </div>
        ) : subscriptionList?.length > 0 ? (
          subscriptionList?.map((i, index) => (
            <PolicyCard
              key={index}
              item={i}
              cardType={
                i.isActive
                  ? allCommonText.viewCardType.subscription
                  : allCommonText.viewCardType.detailButtonOnly
              }
              planIconTitle={
                <span>
                  <span class="icon-subscriptionIcon sub-color-badge"></span>
                  {`${i.viewSubscriptionId}`}
                </span>
              }
              badge={getInvoiceStatus(i.isActive)}
              cost={
                <div className="subscription-popup">
                  <span
                    className={planCategory(
                      i.subscriptionType,
                    )}>{`${i.subscriptionType}`}</span>
                  <ActionDropDownPopUp
                    firstItem={
                      i.isActive && (
                        <div
                          onClick={() => {
                            setSelectedSubscriptionItem(i);
                            setAddPaymentMethodPopUp(true);
                          }}
                          class="view-update-btn-subs">
                          <div className="update-subs-items-icon-container">
                            <FiEdit2 size={15} color={colors.black2} />
                          </div>
                          <span>{allCommonText.buttonTexts.updatePayment}</span>
                        </div>
                      )
                    }
                    secondItem={
                      <div
                        onClick={() => {
                          setSelectedId(i.subscriptionId);
                          navigate({
                            pathname: path.subscriptionDetails,
                            search: `?id=${i.subscriptionId}`,
                          });
                          // setShowDetails(true);
                        }}
                        class="view-invoice-btn-subs">
                        <div className="update-subs-items-icon-container">
                          <MdOutlineManageSearch
                            color={colors.black2}
                            size={15}
                          />
                        </div>
                        <span>{allCommonText.buttonTexts.viewDetails}</span>
                      </div>
                    }
                    thiredOption={
                      i.isActive && (
                        <div
                          onClick={() => {
                            if (i.isCancelable) {
                              setIsCancelPolicy(true);
                              setSubscriptionIds({
                                stripeSubscriptionId: i.stripeSubscriptionId,
                                subscriptionId: i.subscriptionId,
                              });
                            } else {
                              setCancelPolicyPopup(true);
                              setPopUpResponse({
                                type: allCommonText.popupEnums.alert,
                                description:
                                  allCommonText.policyClaims
                                    .cancelSubscriptionmessage,
                                title:
                                  allCommonText.policyClaims
                                    .cannotCancelSubscription,
                              });
                              setSubscriptionIds(null);
                            }
                          }}
                          class="view-cancel-btn-subs">
                          <div className="update-subs-items-icon-container">
                            <MdDelete size={15} color={colors.black2} />
                          </div>
                          <span>
                            {allCommonText.buttonTexts.cancelSubscription}
                          </span>
                        </div>
                      )
                    }
                    // forthOption={
                    //   i.isActive && (
                    //     <div
                    //       onClick={() => {
                    //         navigate({
                    //           pathname: path.upgradeSubscription,
                    //           search: `?id=${i.subscriptionId}&eId=${i.stripeSubscriptionId}`,
                    //         });
                    //       }}
                    //       class="view-cancel-btn-subs">
                    //       <div className="update-subs-items-icon-container">
                    //         <img src={images.upgradeSubscription} />
                    //       </div>
                    //       <span>
                    //         {allCommonText.buttonTexts.upgradeSubscription}
                    //       </span>
                    //     </div>
                    //   )
                    // }
                  />
                </div>
              }
              LeftTopDetails={
                <div className="details-item">
                  <span>{allCommonText.labels.TotalAmount}</span>
                  <span>
                    {i.subscriptionTotal === ''
                      ? allCommonText.policyClaims.notAvailable
                      : `$${parseFloat(i.subscriptionTotal).toFixed(2)}`}
                  </span>
                </div>
              }
              LeftBottomDetails={
                <div className="details-item">
                  <span>{allCommonText.labels.createdDate}</span>
                  <span>
                    {i.createdDate === ''
                      ? allCommonText.policyClaims.notAvailable
                      : `${i.createdDate.replace(/\//g, '-').split('T')[0]}`}
                  </span>
                </div>
              }
              rightTopDetails={
                <div className="details-item billing-card ">
                  <span>{allCommonText.labels.billingCard}</span>
                  {i.cardNumber === '' ? (
                    allCommonText.policyClaims.notAvailable
                  ) : (
                    <div className="billing-card-digits">
                      <i
                        className={`subscription-card-type fa-brands fa-cc-${i.cardType}`}
                        style={{color: colors.primary}}></i>
                      <span>
                        {`${'**** **** ****'}${i.cardNumber.substring(
                          i.cardNumber.length - 4,
                        )}`}
                      </span>
                    </div>
                  )}
                </div>
              }
              rigthBottomDetails={
                <div className="details-item extra-card"></div>
              }
            />
          ))
        ) : (
          !pageLoader && (
            <div className="no-subscription-container">
              <span>{allCommonText.labels.noSubscription}</span>
            </div>
          )
        )}
        {showDetails && (
          <InvoiceAndSubscriptionDetails
            show={showDetails}
            subscriptionId={selectedId}
            handelClose={() => setShowDetails(false)}
          />
        )}
        <GenericModal
          show={cancelPolicyPopup}
          btnText={allCommonText.ok}
          type={popUpResponse.type}
          body={popUpResponse.description}
          title={popUpResponse.title}
          handleClose={() => {
            setCancelPolicyPopup(false);
          }}
        />
        {isCancelPolicy && (
          <ConfirmCancellationPopup
            show={isCancelPolicy}
            reason={cancelReason}
            cancleTitle={allCommonText.policyClaims.cancelSubscription}
            cancelMessage={allCommonText.policyClaims.cancelsubscriptionMessage}
            noteMessage={allCommonText.policyClaims.noteCancelSubsciption}
            setReason={setCancelReason}
            loader={btnLoader}
            handelSubmit={data => {
              confirmCancelSubscriptionHandler(data);
            }}
            handleClose={() => {
              setIsCancelPolicy(false);
            }}
          />
        )}
      </div>
      {totalPages > 1 && (
        <div className="pagination-container">
          <Pagination
            page={currentPage}
            color={'secondary'}
            onChange={handlePageChange}
            count={totalPages}
          />
        </div>
      )}
      {addPaymentMethodPopUp && (
        <AddPaymentMethod
          handleClose={isCardUpdated => {
            setAddPaymentMethodPopUp(false);
            if (isCardUpdated === true) {
              let payload = {
                pageNo: currentPage,
                rowsPerPage: 10,
                status: currentStatus,
                id: currentSubscriptionId,
                subscriptionType: currentSubscriptionType,
              };
              fetchCustomerSubscription(payload);
            }
          }}
          subscriptionId={selectedSubscriptionItem.subscriptionId}
          stripeSubscriptionId={selectedSubscriptionItem.stripeSubscriptionId}
          stripePaymentMethodId={selectedSubscriptionItem.stripePaymentMethodId}
          show={addPaymentMethodPopUp}
          existingCards={existingCards}
          onSubscriptionScreen
        />
      )}
    </>
  );
}
