import React, {useState} from 'react';
import './style.scss';
import Grid from '@mui/material/Grid';
import {GenericButton, GenericModal, InputField} from '../../components';
import StepWizard from 'react-step-wizard';
import {Stepper, Step} from 'react-form-stepper';
import {signupText, allCommonText} from '../../common/constants';
import {Formik} from 'formik';
import {
  signUpStep1Schema,
  signUpStep2Schema,
  signUpStep3Schema,
} from '../../common/schemas';
import {useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router';
import {path} from '../../common/routesNames';
import Autocomplete from 'react-google-autocomplete';
import {userSignUp} from '../../utils/rest-services';
import {images} from '../../common';

const {
  formHeadings: {step1, step2, step3},
  personalDetails,
  addressInfo,
  password,
  back,
  next,
  submit,
  //nodukSignup,
  login,
  //customerHeading,
  ADDRESSKEY,
  fieldTypeEnums,
} = signupText;

const ActionButtons = props => {
  const handleBack = () => {
    props.previousStep();
    const object1 = props.userDetails;
    const object2 = props.values;
    const mergedObject = Object.assign({}, object1, object2);
    props.setUserDetails(mergedObject);
  };
  const handleNext = () => {
    props.nextStep();
  };
  return (
    <div className="signup-btn-container">
      {props.currentStep > 1 && (
        <GenericButton
          buttonText={back}
          onPress={handleBack}
          id="Action-button-step1"
        />
      )}
      {props.currentStep < props.totalSteps && (
        <GenericButton
          id="Action-button-step2"
          buttonText={next}
          onPress={() => {
            props.handleSubmit(() => handleNext());
          }}
        />
      )}
      {props.currentStep === props.totalSteps && (
        <GenericButton
          id="Action-button-step3"
          buttonText={submit}
          disable={!props.isDisable}
          loading={props.btnLoading}
          onPress={() => {
            props.handleSubmit();
          }}
        />
      )}
    </div>
  );
};

function Step3(props) {
  return (
    <Formik
      initialValues={{
        password: props.userDetails.password,
        confirmPassword: props.userDetails.confirmPassword,
      }}
      validateOnMount={true}
      onSubmit={values => {
        props.setUserDetails(data => ({
          ...data,
          password: values.password,
          confirmPassword: values.confirmPassword,
        }));
        props.performSignup(values.password);
      }}
      validationSchema={signUpStep3Schema}
      enableReinitialize>
      {({handleChange, handleBlur, handleSubmit, touched, errors, values}) => (
        <div className="Sign-up-box">
          <div className="form-container">
            <div className="sign-up-form-heading">
              <span>{password}</span>
            </div>
            <Grid container spacing={0.5}>
              <Grid item xs={12} xl={12} lg={12} md={12}>
                <InputField
                  name={'password'}
                  id={'password'}
                  error={errors.password}
                  touched={touched.password}
                  handleChange={handleChange('password')}
                  handleBlur={handleBlur('password')}
                  value={values.password}
                  isPassword
                  placeholder={allCommonText.placeholders.password}
                />
              </Grid>
              <Grid item xs={12} xl={12} lg={12} md={12}>
                <InputField
                  name={'confirmPassword'}
                  id={'confirmPassword'}
                  error={errors.confirmPassword}
                  touched={touched.confirmPassword}
                  handleChange={handleChange('confirmPassword')}
                  handleBlur={handleBlur}
                  value={values.confirmPassword}
                  isPassword
                  placeholder={allCommonText.placeholders.confirmPassword}
                />
              </Grid>
              <Grid item xs={12} xl={12} lg={12} md={12}>
                <div className="termCondition">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked={props.isTermAndConditionCheck}
                    onChange={d => {
                      props.setIsTermAndConditionCheck(d.target.checked);
                    }}
                  />
                  <label>
                    {allCommonText.termsCondition1}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={allCommonText.aboutUs.privacy}>
                      {allCommonText.termsCondition2}
                    </a>
                  </label>
                </div>
              </Grid>
            </Grid>
          </div>
          <ActionButtons
            setUserDetails={props.setUserDetails}
            userDetails={props.userDetails}
            btnLoading={props.btnLoading}
            isDisable={props.isTermAndConditionCheck}
            values={values}
            handleSubmit={handleSubmit}
            {...props}
          />
        </div>
      )}
    </Formik>
  );
}

function Signup() {
  const {fieldsValue, placeholders} = allCommonText;
  const [activeStep, setActiveStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isTermAndConditionCheck, setIsTermAndConditionCheck] = useState(false);
  const [signupResponse, setSignupResponse] = useState({
    type: 1,
    title: '',
    details: '',
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    firstNme: '',
    lastName: '',
    email: '',
    dob: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    password: '',
    confirmPassword: '',
  });
  const navigate = useNavigate();

  const convertArrayToString = data => {
    const joined = data.join();
    let newAddress = joined.replace(/,/g, ' ');
    return newAddress;
  };

  const AddressSeprateHandler = (data, setFieldValue, values) => {
    let address = [];
    let city = [];
    let state = [];
    let postal = [];
    data.map(dataItem => {
      dataItem.types.map(type => {
        if (type === fieldTypeEnums.streetNumber) {
          address.push(dataItem.long_name);
        } else if (type === fieldTypeEnums.route) {
          address.push(dataItem.long_name);
        } else if (type === fieldTypeEnums.naibhour) {
          address.push(dataItem.long_name);
        } else if (type === fieldTypeEnums.locality) {
          city.push(dataItem.long_name);
        } else if (type === fieldTypeEnums.admin) {
          state.push(dataItem.long_name);
        } else if (type === fieldTypeEnums.code) {
          postal.push(dataItem.long_name);
        }
      });
    });
    //convert Address array to string
    setFieldValue(fieldsValue.address, values);
    setFieldValue(fieldsValue.city, convertArrayToString(city));
    setFieldValue(fieldsValue.state, convertArrayToString(state));
    setFieldValue(fieldsValue.zip, convertArrayToString(postal));
    setUserDetails(data => ({
      ...data,
      address: values,
      city: convertArrayToString(city),
      state: convertArrayToString(state),
      zip: convertArrayToString(postal),
    }));
  };
  const gererateFormData = (
    FirstName,
    LastName,
    BirthDate,
    Address,
    City,
    State,
    Zip,
    Phone,
    Email,
    Password,
    Profile,
    affiliateId,
    agId,
  ) => {
    let formData = new FormData();
    formData.append('FirstName', FirstName); //Hint: For the time Being its static
    formData.append('LastName', LastName); //Hint: For the time Being its static
    formData.append('BirthDate', BirthDate);
    formData.append('Address', Address);
    formData.append('City', City);
    formData.append('State', State);
    formData.append('Zip', Zip);
    formData.append('Phone', Phone);
    formData.append('Email', Email);
    formData.append('Password', Password);
    formData.append('Profile', Profile);
    formData.append('AffiliateId', affiliateId);
    formData.append('AgentId', agId);

    return formData;
  };
  const location = useLocation();
  const getParamsFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  const performSignup = async password => {
    let query = getParamsFromUrl();
    const affId = query.get('affId');
    const agId = query.get('agId');
    setBtnLoading(true);
    // const dob = userDetails.dob.split('-');
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const currentDate = `${year}-${month}-${day}`;
    const customerDetails = gererateFormData(
      userDetails.firstNme,
      userDetails.lastName,
      // `${dob[1]}-${dob[2]}-${dob[0]}`,
      currentDate,
      userDetails.address,
      userDetails.city,
      userDetails.state,
      userDetails.zip,
      userDetails.phone,
      userDetails.email,
      password,
      null,
      affId ? affId : 0,
      agId ? agId : '',
    );

    let result = await userSignUp(customerDetails);
    const {
      data: {success, description},
    } = result || {};
    if (result && success) {
      setSignupResponse({
        type: allCommonText.popupEnums.success,
        title: '',
        details: description,
      });
      setShowModal(true);
    } else {
      setSignupResponse({
        type: allCommonText.popupEnums.error,
        title: '',
        details: description,
      });
      setShowModal(true);
    }

    setBtnLoading(false);
  };
  function ValidatePhoneNumber(event) {
    return (event.value = event.value
      .replace(/^(\+1)/, '')
      .replace(/^(1)/, ''));
  }
  function Step1(props) {
    return (
      <Formik
        initialValues={{
          firstName: userDetails.firstNme,
          lastName: userDetails.lastName,
          email: userDetails.email,
          dob: userDetails.dob || null,
          phone: userDetails.phone,
        }}
        validateOnMount={true}
        onSubmit={values => {
          setUserDetails(data => ({
            ...data,
            firstNme: values.firstName,
            lastName: values.lastName,
            email: values.email,
            dob: values.dob,
            phone: values.phone,
          }));
          props.nextStep();
        }}
        validationSchema={signUpStep1Schema}
        enableReinitialize>
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          errors,
          values,
          setFieldValue,
        }) => (
          <div className="Sign-up-box">
            <div className="form-container">
              <div className="sign-up-form-heading">
                <span>{personalDetails}</span>
              </div>
              <Grid container spacing={0.7}>
                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <div className="mb">
                    <InputField
                      name={'firstName'}
                      id={'firstName'}
                      error={errors.firstName}
                      touched={touched.firstName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      placeholder={placeholders.firstName}
                      value={values.firstName}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} xl={6} lg={6} md={6}>
                  <InputField
                    name={'lastName'}
                    id={'lastName'}
                    error={errors.lastName}
                    touched={touched.lastName}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    placeholder={placeholders.lastName}
                    value={values.lastName}
                  />
                </Grid>
                <Grid item xs={12} xl={12} lg={12} md={12}>
                  <div className="mb-1">
                    <InputField
                      name={'email'}
                      id={'email'}
                      error={errors.email}
                      touched={touched.email}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      fullWidth
                      placeholder={placeholders.email}
                      value={values.email}
                    />
                  </div>
                </Grid>
                {/* <Grid item xs={12} xl={6} lg={6} md={6}>
                  <InputField
                    name={'dob'}
                    id={'dob'}
                    error={errors.dob}
                    touched={touched.dob}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    type="Date"
                    placeholder={placeholders.dob}
                    value={values.dob}
                  />
                </Grid> */}
                <Grid item xs={12} xl={12} lg={12} md={12}>
                  <InputField
                    name={'phone'}
                    id={'phone'}
                    error={errors.phone}
                    touched={touched.phone}
                    handleChange={e => {
                      const inputValue = e.target.value
                        .replace(/^(\+1)/, '')
                        .replace(/^(1)/, '');
                      const numericValue = inputValue.replace(/[^0-9+]/g, ''); // remove non-numeric characters
                      const restrictedValue = numericValue.substring(0, 12); // restrict to 10 digits
                      setFieldValue('phone', restrictedValue);
                    }}
                    handleBlur={e => {
                      handleBlur('phone');
                      ValidatePhoneNumber(e.target);
                    }}
                    value={values.phone}
                    placeholder={placeholders.phone}
                    phone
                  />
                </Grid>
              </Grid>
            </div>
            <ActionButtons
              setUserDetails={setUserDetails}
              userDetails={userDetails}
              handleSubmit={handleSubmit}
              {...props}
            />
          </div>
        )}
      </Formik>
    );
  }
  function Step2(props) {
    return (
      <Formik
        initialValues={{
          address: userDetails.address,
          city: userDetails.city,
          state: userDetails.state,
          zip: userDetails.zip,
        }}
        validateOnMount={true}
        onSubmit={values => {
          setUserDetails(data => ({
            ...data,
            address: values.address,
            city: values.city,
            state: values.state,
            zip: values.zip,
          }));
          props.nextStep();
        }}
        validationSchema={signUpStep2Schema}
        enableReinitialize>
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          errors,
          setFieldValue,
          values,
        }) => (
          <form autoComplete="new-password">
            <div className="Sign-up-box">
              <div className="form-container">
                <div className="sign-up-form-heading">
                  <span>{addressInfo}</span>
                </div>
                <Grid container spacing={0.5}>
                  <Grid item xs={12} xl={12} lg={12} md={12}>
                    <Autocomplete
                      className="input-field-generic auto-complete-address"
                      apiKey={ADDRESSKEY}
                      // apiKey={process.env.REACT_APP_ADDRESSKEY}
                      defaultValue={values.address}
                      onChange={handleChange('address')}
                      onBlur={handleBlur}
                      options={{
                        componentRestrictions: {country: 'us'},
                      }}
                      onPlaceSelected={data => {
                        AddressSeprateHandler(
                          data.address_components,
                          setFieldValue,
                          data.formatted_address,
                        );
                      }}
                    />
                    <div className="error-input-container">
                      {errors.address && touched.address ? (
                        <p className="form-error">{errors.address}</p>
                      ) : null}
                    </div>
                  </Grid>

                  <Grid item xs={12} xl={6} lg={6} md={6}>
                    <InputField
                      name={'city'}
                      id={'city'}
                      error={errors.city}
                      touched={touched.city}
                      handleChange={handleChange('city')}
                      handleBlur={handleBlur}
                      value={values.city}
                      placeholder={placeholders.city}
                    />
                  </Grid>
                  <Grid item xs={12} xl={6} lg={6} md={6}>
                    <InputField
                      name={'state'}
                      id={'state'}
                      error={errors.state}
                      touched={touched.state}
                      handleChange={handleChange('state')}
                      handleBlur={handleBlur}
                      value={values.state}
                      placeholder={placeholders.state}
                    />
                  </Grid>

                  <Grid item xs={12} xl={6} lg={6} md={6}>
                    <InputField
                      name={'zip'}
                      id={'zip'}
                      error={errors.zip}
                      touched={touched.zip}
                      handleChange={handleChange('zip')}
                      handleBlur={handleBlur}
                      value={values.zip}
                      placeholder={placeholders.zipCode}
                      autoComplete="new-password"
                    />
                  </Grid>
                </Grid>
              </div>

              <ActionButtons
                setUserDetails={setUserDetails}
                values={values}
                userDetails={userDetails}
                handleSubmit={handleSubmit}
                {...props}
              />
            </div>
          </form>
        )}
      </Formik>
    );
  }

  const handleStepChange = e => {
    setActiveStep(e.activeStep - 1);
  };

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12} xl={5.5} lg={5.5} md={5.5}>
          <div className="signup-section">
            <div className="signup-form-container">
              <div className="sign-up-heading">
                <span>
                  Register for a new <span className="redTxt">No Duk</span>{' '}
                  account
                </span>
              </div>
              <Stepper
                activeStep={activeStep}
                className="stepper-width-handler"
                connectorStyleConfig={{
                  size: 3,
                  // disabledColor: colors.disabledRed,
                  // activeColor: colors.activeColor,
                  // completedColor: colors.completedColor,
                  stepSize: '2em',
                  style: 'solid',
                }}>
                <Step label={step1} />
                <Step label={step2} />
                <Step label={step3} />
              </Stepper>
              <div className="sign-up-container-wizard">
                <StepWizard onStepChange={handleStepChange}>
                  <Step1 />
                  <Step2 />
                  <Step3
                    btnLoading={btnLoading}
                    userDetails={userDetails}
                    setUserDetails={setUserDetails}
                    performSignup={performSignup}
                    isTermAndConditionCheck={isTermAndConditionCheck}
                    setIsTermAndConditionCheck={setIsTermAndConditionCheck}
                  />
                </StepWizard>
                <div
                  onClick={() => {
                    navigate({
                      pathname: path.login,
                    });
                  }}
                  className="sign-up-creat-account">
                  <span>{login}</span>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} xl={6.5} lg={6.5} md={6.5}>
          <div className="sign-up-image-section">
            <div class="video-container">
              <img src={images.signUpMobile} />
              <div className="download-icons-container">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://play.google.com/store/search?q=no%20duk&c=apps&hl=en&gl=US">
                  <img src={images.androidDownload} />
                </a>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://apps.apple.com/pk/app/no-duk/id1659304731">
                  <img src={images.iosDownload} />
                </a>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <GenericModal
        show={showModal}
        btnText={allCommonText.ok}
        type={signupResponse.type}
        body={signupResponse.details}
        handleClose={() => {
          setShowModal(false);
          if (signupResponse.type === allCommonText.popupEnums.success)
            navigate(path.login);
        }}
      />
    </div>
  );
}

export default Signup;
