import React, {useState, useContext, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import {allCommonText} from '../../common/constants';
import './style.scss';
import {colors, images} from '../../common';
import {plans} from '../../common/constants';
import {
  AddPaymentMethod,
  GenericButton,
  GenericModal,
  InputField,
} from '../../components';
import ApplicationContext from '../../utils/context-api/context';
import {getPlanBadgColors, PlanCard} from './plan-card';
import {
  AddStripeSubId,
  CreateSubscription,
  getCheckoutCompleteObject,
  getPaymentDefaultCards,
  getPromoCodeCheckoutObjectInformation,
} from '../../utils/rest-services';
import {useStripe} from '@stripe/react-stripe-js';
import {useNavigate} from 'react-router';
import {path} from '../../common/routesNames';
import {AiFillCheckCircle} from 'react-icons/ai';
import {HiOutlineInformationCircle} from 'react-icons/hi2';
import {Tooltip} from 'react-tooltip';
import _ from 'lodash';

const COUPON_TYPE_ONCE = 'once';
const COUPON_TPE_FOREVER = 'forever';
const MONTHLY_PLAN_ID = 1;
const BIANUAL_PLAN_ID = 2;
const ANNUAL_PLAN_ID = 3;

function Checkout() {
  // New Checkout Code States
  const [selectedPlanId, setSelectedPlanId] = useState(1);
  const [checkOutCompleteData, setCheckOutCompleteData] = useState(null);
  const [taxPercentage, setTaxPercentage] = useState(2.9);
  const [processingFee, setProcessingFee] = useState(1);
  const [monthlyPlanData, setMonthlyPlanData] = useState(null);
  const [biAnnualPlanData, setBiAnnualPlanData] = useState(null);
  const [annualPlanData, setAnnualPlanData] = useState(null);
  const [selectedPlanRecords, setSelectedPlanRecords] = useState(null);
  const [cartItems, setCartItems] = useState(null);
  const [totalCost, setTotalCost] = useState(null);

  // Old Checkout Implementations
  const [addPaymentMethodPopUp, setAddPaymentMethodPopUp] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [existingCards, setExistingCards] = useState([]);
  const [popUpResponse, setPopUpResponse] = useState({
    type: 1,
    detail: '',
    title: '',
  });
  const {selectedCard, isOpenPane} = useContext(ApplicationContext);
  // const [actualDiscountByCoupon, setActualDiscountByCoupon] = useState('');
  const stripe = useStripe();
  let navigate = useNavigate();
  const [couponCode, setCouponCode] = useState('');
  const [showBadge, setShowBadge] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    hiddenKeys: {plansId},
    popupEnums: {plansType, subscriptionPlanHeadings},
    serviceTypeEnums,
    heading: {discountsPlan},
  } = allCommonText;

  useEffect(() => {
    fetchCheckoutObject();
    getPaymentCards();
  }, []);

  useEffect(() => {
    if (selectedPlanId == MONTHLY_PLAN_ID) {
      return setSelectedPlanRecords(monthlyPlanData);
    } else if (selectedPlanId == BIANUAL_PLAN_ID) {
      return setSelectedPlanRecords(biAnnualPlanData);
    } else {
      return setSelectedPlanRecords(annualPlanData);
    }
  }, [selectedPlanId]);

  useEffect(() => {
    if (!isOpenPane.isPaneOpen) {
      fetchCheckoutObject();
      getPaymentCards();
    }
  }, [isOpenPane]);

  const saveStripeID = async stripeSubsId => {
    let response = await AddStripeSubId(stripeSubsId);
    if (response && response?.data?.success) {
      const {
        data: {success},
      } = response || {};
      if (success) {
        setBtnLoader(false);
        setShowModal(true);
        setPopUpResponse({
          type: allCommonText.popupEnums.success,
          detail: allCommonText.successfullPayment.description,
        });
      }
    } else {
      setShowModal(true);
      setPopUpResponse({
        type: allCommonText.popupEnums.error,
        detail: response?.data?.description || allCommonText.checkoutError,
      });
    }
  };
  const removeCouponHandler = () => {
    setCouponCode('');
    setShowBadge(false);
    setPlanAmountsHandler({content: checkOutCompleteData});
    setSelectedPlanId(MONTHLY_PLAN_ID);
  };
  const discountUsingCoupen = async code => {
    setLoading(true);
    let response = await getPromoCodeCheckoutObjectInformation(code);
    setLoading(false);
    const {success, description} = response || {};
    if (response && response?.success) {
      if (success) {
        setPlanAmountsHandler(response);
        setShowBadge(true);
      }
    } else {
      setPopUpResponse({
        type: allCommonText.popupEnums.error,
        detail: description,
      });
      setShowModal(true);
    }
  };
  const PlaceOrderHandler = () => {
    const subscriptionObject = {
      paymentMethodId: selectedCard?.paymentMethodId,
      subscriptionType: getPlanType(),
      saveCard: selectedCard.isCardSave,
      nameOnCard: selectedCard.cardName,
      newCard: selectedCard.newCard || false, // we will updat it for exisiting cards
      paymentPlatform: 1,
      promoCode: showBadge
        ? selectedPlanRecords?.appliedDiscount?.couponCode
        : '',
      couponType: selectedPlanRecords?.appliedDiscount?.discountType || 0,
    };
    subscriptionWithExistingCard(subscriptionObject);
  };

  const subscriptionWithExistingCard = async subscriptionObject => {
    setBtnLoader(true);
    try {
      let subscripResult = await CreateSubscription(subscriptionObject);
      if (subscripResult.data.success != true) {
        setPopUpResponse({
          type: allCommonText.popupEnums.error,
          detail:
            subscripResult?.data?.description ||
            subscripResult?.data?.exceptionMessage ||
            allCommonText.checkoutError,
        });
        setShowModal(true);
        setBtnLoader(false);
        return;
      }
      const {
        data: {
          content: {clientSecret, paymethodId, stripeSubsId},
          success,
        },
      } = subscripResult || {};
      if (subscripResult && success) {
        const {paymentIntent, error} = await stripe.confirmCardPayment(
          clientSecret,
          {
            [`payment_method`]: paymethodId,
          },
        );

        if (paymentIntent) {
          saveStripeID(stripeSubsId); // incase you have confirm Payment from stripe
        } else {
          setPopUpResponse({
            type: allCommonText.popupEnums.error,
            detail: error.message || allCommonText.checkoutError,
          });
          setShowModal(true);
          setBtnLoader(false);
          return;
        }
      }
    } catch (error) {
      setPopUpResponse({
        type: allCommonText.popupEnums.error,
        detail: error.message || allCommonText.checkoutError,
      });
      setShowModal(true);
      setBtnLoader(false);
      return;
    }
  };

  const checkMinimumDiscountAmount = selectedPlan => {
    const minimumDiscountAmount =
      selectedPlanRecords?.appliedDiscount?.minimumDiscountAmount;
    const planAmount = selectedPlan?.totalTaxedInvoiceAmount;
    if (planAmount < minimumDiscountAmount) {
      setShowModal(true);
      setPopUpResponse({
        type: allCommonText.popupEnums.error,
        detail: `Can not apply coupon minimum payable amount is $${minimumDiscountAmount}`,
      });
      return;
    }
  };

  const setPlanAmountsHandler = (result, firstTimeRender) => {
    var monthlyPlan;
    var biannualPlan;
    var annualPlan;
    const plans = result?.content?.plans || [];
    if (plans && plans.length > 0) {
      monthlyPlan = _.find(plans, {planType: MONTHLY_PLAN_ID});
      biannualPlan = _.find(plans, {planType: BIANUAL_PLAN_ID});
      annualPlan = _.find(plans, {planType: ANNUAL_PLAN_ID});
    }
    var selectedPlan;
    switch (selectedPlanId) {
      case MONTHLY_PLAN_ID:
        selectedPlan = monthlyPlan;
        break;
      case BIANUAL_PLAN_ID:
        selectedPlan = biannualPlan;
        break;
      case ANNUAL_PLAN_ID:
        selectedPlan = annualPlan;
        break;
    }
    if (result) {
      const {content} = result || {};
      const {processingFee, salesTax, cartItems, totals} = content || {};

      if (!firstTimeRender) {
        const minimumDiscountAmount =
          selectedPlan?.appliedDiscount?.minimumDiscountAmount;
        const planAmount = selectedPlanRecords?.totalTaxedInvoiceAmount;
        if (planAmount < minimumDiscountAmount) {
          setShowModal(true);
          setPopUpResponse({
            type: allCommonText.popupEnums.error,
            detail: `Can not apply coupon minimum payable amount is $${minimumDiscountAmount}`,
          });
          return;
        }
      }
      firstTimeRender && setCheckOutCompleteData(content);
      setTaxPercentage(salesTax);
      setProcessingFee(processingFee);
      totals && setTotalCost(totals);
      if (cartItems) {
        setCartItems(cartItems);
      }
      if (plans && plans?.length > 0) {
        setMonthlyPlanData(monthlyPlan);
        setBiAnnualPlanData(biannualPlan);
        setAnnualPlanData(annualPlan);
        // set Default Plan Monthly as selected
        if (firstTimeRender) {
          setSelectedPlanRecords(monthlyPlan);
        } else {
          setSelectedPlanRecords(selectedPlan);
        }
      }
    }
  };

  const fetchCheckoutObject = async () => {
    let result = await getCheckoutCompleteObject();
    // will remove ! operator in Future
    if (result && !result?.data?.success) {
      setPlanAmountsHandler(result, true);
    }
  };
  const getPaymentCards = async () => {
    let result = await getPaymentDefaultCards();
    const {
      data: {success, content},
    } = result || {};
    if (result && success) {
      setExistingCards(content);
    } else {
      setExistingCards([]);
    }
  };

  const getRecurringTotalByItem = (value, planId) => {
    if (selectedPlanId === plansId.monthly || planId === plansId.monthly) {
      return value * 1;
    } else if (
      selectedPlanId === plansId.biAnnual ||
      planId === plansId.biAnnual
    ) {
      return value * 6;
    } else {
      return value * 12;
    }
  };

  const getPlanType = () => {
    if (selectedPlanId == plansId.monthly) {
      return allCommonText.planTypes.monthly;
    } else if (selectedPlanId == plansId.biAnnual) {
      return allCommonText.planTypes.biannual;
    } else {
      return allCommonText.planTypes.annual;
    }
  };

  const IconSelector = id => {
    switch (id) {
      case serviceTypeEnums.vehicle:
        return <img src={images.vehicleV} alt={plans.vehicleIcon} />;
      case serviceTypeEnums.home:
        return <img src={images.homeV} alt={plans.homeIcon} />;
      case serviceTypeEnums.device:
        return <img src={images.devicesV} alt={plans.devicesIcon} />;
      case serviceTypeEnums.commercial:
        return <img src={images.commercialV} alt={plans.commercialIcon} />;
      case serviceTypeEnums.disaster:
        return <img src={images.diasasterR} alt={plans.commercialIcon} />;
    }
  };
  const DeductibleItem = ({IconId, deductible, recurring}) => {
    return (
      <div className="invoice-table-item">
        <span className="invoice-icon-container">{IconSelector(IconId)}</span>
        <span className="invoice-deductibles-container">{deductible}</span>
        <span>{recurring}</span>
      </div>
    );
  };
  const getDisplayCost = value => `$${parseFloat(value).toFixed(2)}`;

  const ItemInfo = ({text, price, text2, price2, couponType}) => {
    let discountType = '';
    switch (couponType) {
      case COUPON_TYPE_ONCE:
        discountType = allCommonText.onlyForFirstInvoice;
        break;
      case COUPON_TPE_FOREVER:
        discountType = allCommonText.forever;
        break;
      default:
        discountType = '(first month only)';
        break;
    }
    return (
      <div className="total-amount-container">
        <div className="total-amount-child">
          <span>{text}</span>
          <div>
            <span className="discount-by-coupon-type">{price ?? 'N/A'}</span>
            {showBadge && couponType && (
              <span className="discount-type">{discountType}</span>
            )}
          </div>
        </div>
        {text2 && (
          <div className="total-amount-child">
            <span>{text2}</span>
            <span className="discount-by-coupon-type">{price2} </span>
          </div>
        )}
      </div>
    );
  };

  const InvoiceCalculations = ({
    title1,
    price1,
    title2,
    price2,
    title3,
    price3,
    discTitle,
    discValue,
    couponType,
    title4,
    price4,
  }) => {
    let discountType = '';
    switch (couponType) {
      case COUPON_TYPE_ONCE:
        discountType = allCommonText.onlyForFirstInvoice;
        break;
      case COUPON_TPE_FOREVER:
        discountType = allCommonText.forever;
        break;
      default:
        discountType = '(first month only)';
        break;
    }
    return (
      <div className="total-amount-container">
        {title1 != '' && (
          <div className="total-amount-child">
            <span>{title1}</span>
            <span className="discount-by-coupon-type">{price1} </span>
          </div>
        )}
        {discTitle != '' && (
          <div className="total-amount-child">
            <span>{discTitle}</span>
            <span className="discount-by-coupon-type">{discValue} </span>
            {showBadge && couponType && (
              <span className="discount-type">{discountType}</span>
            )}
          </div>
        )}
        {title4 != '' && (
          <div className="total-amount-child">
            <span>{title4}</span>
            <span className="discount-by-coupon-type">{price4} </span>
          </div>
        )}
        {title2 != '' && (
          <div className="total-amount-child">
            <span>{title2}</span>
            <span className="discount-by-coupon-type">{price2} </span>
          </div>
        )}
        {title3 != '' && (
          <div className="total-amount-child">
            <span>{title3}</span>
            <span className="discount-by-coupon-type">{price3} </span>
          </div>
        )}
      </div>
    );
  };
  const checkPercentageOrDollarChange = isPercentageDiscount => {
    if (isPercentageDiscount) {
      return `${selectedPlanRecords?.appliedDiscount?.discountPercentage}%`;
    } else {
      return getDisplayCost(
        selectedPlanRecords?.appliedDiscount?.discountedAmount,
      );
    }
  };
  return (
    <div className="main-container container">
      <div className="plans-container ">
        <span className="caption-title">
          <span>NO DUK</span>
          {"has plans that won't break the bank!"}
        </span>
        <div className="cards-container mt-2">
          <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={4} xs={12}>
              <div className="card-container ">
                <PlanCard
                  title={plansType.monthly}
                  infoOne={
                    monthlyPlanData?.planDescription ||
                    allCommonText.labels.renews.monthly
                  }
                  planAmount={monthlyPlanData?.planAmount - processingFee}
                  processingFee={processingFee}
                  infoSecond={
                    monthlyPlanData?.appliedDiscount
                      ? `${monthlyPlanData?.appliedDiscount?.discountText}`
                      : discountsPlan.monthly
                  }
                  price={getDisplayCost(monthlyPlanData?.discountedPlanAmount)}
                  type={MONTHLY_PLAN_ID}
                  key={MONTHLY_PLAN_ID}
                  planType={MONTHLY_PLAN_ID}
                  showBadge={
                    monthlyPlanData?.appliedDiscount.discountType != 0
                      ? true
                      : false
                  }
                  onCardPress={() => {
                    setSelectedPlanId(plansId.monthly);
                    checkMinimumDiscountAmount(
                      checkOutCompleteData?.plans?.find(
                        i => i.planType === MONTHLY_PLAN_ID,
                      ),
                    );
                    // {
                    //   discountedContent?.length != 0 &&
                    //     setRecurringVal(
                    //       validateRecurring(discountedContent, plansId.monthly),
                    //     );
                    // }
                  }}
                  check={selectedPlanId === plansId.monthly}
                />
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} xs={12}>
              <div className="card-container ">
                <PlanCard
                  title={plansType?.biAnnual}
                  infoOne={
                    biAnnualPlanData?.planDescription ||
                    allCommonText.labels.renews.biannual
                  }
                  planAmount={biAnnualPlanData?.planAmount - processingFee}
                  processingFee={processingFee}
                  infoSecond={
                    biAnnualPlanData?.appliedDiscount
                      ? `${biAnnualPlanData?.appliedDiscount?.discountText}`
                      : `${discountsPlan.biannual} Discount`
                  }
                  price={getDisplayCost(biAnnualPlanData?.discountedPlanAmount)}
                  type={BIANUAL_PLAN_ID}
                  key={BIANUAL_PLAN_ID}
                  planType={BIANUAL_PLAN_ID}
                  showBadge={biAnnualPlanData?.appliedDiscount ? true : false}
                  check={selectedPlanId === plansId.biAnnual}
                  onCardPress={() => {
                    setSelectedPlanId(plansId.biAnnual);
                    checkMinimumDiscountAmount(
                      checkOutCompleteData?.plans?.find(
                        i => i.planType === BIANUAL_PLAN_ID,
                      ),
                    );
                    // {
                    //   discountedContent?.length != 0 &&
                    //     setRecurringVal(
                    //       validateRecurring(
                    //         discountedContent,
                    //         plansId.biAnnual,
                    //       ),
                    //     );
                    // }
                  }}
                />
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} xs={12}>
              <div className="card-container ">
                <PlanCard
                  title={plansType?.annual}
                  infoOne={
                    annualPlanData?.planDescription ||
                    allCommonText.labels.renews.yearly
                  }
                  planAmount={annualPlanData?.planAmount - processingFee}
                  processingFee={processingFee}
                  infoSecond={
                    annualPlanData?.appliedDiscount
                      ? `${annualPlanData?.appliedDiscount?.discountText}`
                      : discountsPlan.annualy
                  }
                  price={getDisplayCost(annualPlanData?.discountedPlanAmount)}
                  type={ANNUAL_PLAN_ID}
                  key={ANNUAL_PLAN_ID}
                  planType={ANNUAL_PLAN_ID}
                  showBadge={annualPlanData?.appliedDiscount ? true : false}
                  onCardPress={() => {
                    setSelectedPlanId(plansId.annual);
                    checkMinimumDiscountAmount(
                      checkOutCompleteData?.plans?.find(
                        i => i.planType === ANNUAL_PLAN_ID,
                      ),
                    );
                    // {
                    //   discountedContent?.length != 0 &&
                    //     setRecurringVal(
                    //       validateRecurring(discountedContent, plansId.annual),
                    //     );
                    // }
                  }}
                  check={selectedPlanId === plansId.annual}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <section className="invoice-container">
          <div className="invoice-top-wrapper w-100">
            <div className="invoice-title">
              <span>{allCommonText.popupEnums.subscriptionSummary} </span>
              <div
                className={`invoice-type ${
                  getPlanBadgColors(selectedPlanId).background
                } `}>
                <span>{getPlanType()} </span>
              </div>
            </div>
          </div>
          <div className="invoice-table-heading">
            <span>{subscriptionPlanHeadings.item}</span>
            <span>{subscriptionPlanHeadings.deductibles}</span>
            <span>{subscriptionPlanHeadings.recurring}</span>
          </div>
          {cartItems &&
            cartItems?.map((value, index) => (
              <DeductibleItem
                key={index}
                IconId={value.serviceTypeId}
                deductible={`$${value.deductibleAmount.toFixed(2)}`}
                recurring={`$${getRecurringTotalByItem(value.cost).toFixed(2)}`}
              />
            ))}
          <ItemInfo
            text={allCommonText.toalDeductibles}
            price={totalCost && totalCost.totalDeductibles}
          />
          <div className="checkout-cont">
            <span className="coupon-code">
              {allCommonText.buttonTexts.couponCode}
            </span>
            <br />
            <span className="promo-note">{allCommonText.couponNote}</span>
            <div className="checkout-inner">
              <div className="checkout-input-field">
                <InputField
                  disabled={showBadge}
                  value={couponCode}
                  placeholder={allCommonText.buttonTexts.enterCode}
                  handleChange={e => setCouponCode(e.target.value)}
                />
              </div>
              <div className="checkout-apply-btn">
                <GenericButton
                  loading={loading}
                  disable={couponCode?.length == 0 || showBadge}
                  onPress={() => discountUsingCoupen(couponCode)}
                  buttonText={allCommonText.buttonTexts.apply}
                />
              </div>
              {showBadge && (
                <div className="badge-style">
                  <InputField disabled={true} value={couponCode} />
                  <button
                    className="clear-button"
                    onClick={() => {
                      removeCouponHandler();
                      // setShowBadge(false);
                      // setCouponCode('');
                      // setDiscountedContent([]);
                      // setIsValidDiscount(false);
                      // setCouponDuration(null);
                    }}>
                    <span>X</span>
                  </button>
                </div>
              )}
            </div>
          </div>
          {/* <ItemInfo
            text={allCommonText.discount}
            price={showBadge ? actualDiscountByCoupon : getDiscountOnPlans()}
            couponType={couponDuration}
          /> */}

          <InvoiceCalculations
            title1={
              <span>
                {allCommonText.currentInvoice}
                <HiOutlineInformationCircle
                  data-tooltip-id={'tax-tooltip-1'}
                  data-tooltip-content={`Including $${processingFee} Processing Fee`}
                  style={{
                    color: 'black',
                    marginLeft: '10px',
                    fontWeight: 'bolder',
                    height: '24px',
                    width: '24px',
                  }}
                />
                <Tooltip id={'tax-tooltip-1'} />
              </span>
            }
            price1={getDisplayCost(selectedPlanRecords?.planAmount)}
            // price1={
            //   showBadge
            //     ? `$${recurringVal?.toFixed(2)}`
            //     : withProcessingFeeHandler(actualRecurring())
            // }
            discTitle={allCommonText.discount}
            discValue={
              selectedPlanRecords?.appliedDiscount ? (
                <span className="bold-black">
                  {/* {`${selectedPlanRecords?.appliedDiscount?.discountName}  `} */}
                  <span className="bold-red">
                    {`${checkPercentageOrDollarChange(
                      selectedPlanRecords?.appliedDiscount.isPercentageDiscount,
                    )}`}
                  </span>
                </span>
              ) : (
                discountsPlan.monthly
              )
            }
            // discValue={
            //   showBadge ? actualDiscountByCoupon : getDiscountOnPlans()
            // }
            title4={allCommonText.InvAfterDisc}
            price4={getDisplayCost(selectedPlanRecords?.discountedPlanAmount)}
            // couponType={couponDuration}
            title2={
              <span>
                {allCommonText.platformFee}
                <HiOutlineInformationCircle
                  data-tooltip-id={'tax-tooltip'}
                  data-tooltip-content={`${taxPercentage}% Sales Tax`}
                  style={{
                    color: 'black',
                    marginLeft: '10px',
                    fontWeight: 'bolder',
                    height: '24px',
                    width: '24px',
                  }}
                />
                <Tooltip id={'tax-tooltip'} />
              </span>
            }
            price2={getDisplayCost(selectedPlanRecords?.currentSalesTax)}
            // price2={
            //   showBadge
            //     ? `$${taxedValue(recurringVal)}`
            //     : `$${taxedValue(
            //         withProcessingFeeHandler(actualRecurring())?.replace(
            //           /\$|,/g,
            //           '',
            //         ),
            //       ).toFixed(2)}`
            // }
            title3={allCommonText.totalCurrentInv}
            price3={getDisplayCost(
              selectedPlanRecords?.totalTaxedInvoiceAmount,
            )}
            // price3={
            //   showBadge
            //     ? withTaxAndOtherProcessingFee(recurringVal)
            //     : `$${TOTAL_CURRENT_INV_WITHOUT_COUPON}`
            // }
          />

          {/* {showBadge && couponDuration == allCommonText.once && (
            <ItemInfo
              text={allCommonText.currentInvoice}
              text2={
                couponDuration == allCommonText.once &&
                allCommonText.futureInvoice
              }
              price={
                showBadge && recurringVal?.length != 0
                  ? `$${recurringVal?.toFixed(2)}`
                  : getDiscountOnPlans()
              }
              price2={showBadge ? actualRecurring() : getTotalRecurring()}
            />
          )} */}

          {/* For the time being commented */}
          <ItemInfo
            text={allCommonText.futureInvoice}
            price={getDisplayCost(
              selectedPlanRecords?.futureTaxedInvoiceAmount,
            )}

            // price={
            //   showBadge
            //     ? couponDuration === COUPON_TPE_FOREVER
            //       ? `${addTaxToAmountHandler(recurringVal?.toFixed(2))}`
            //       : addTaxToAmountHandler(actualRecurring())
            //     : `$${TOTAL_FUTURE_INV_WITHOUT_COUPON}`
            // }
          />

          <div className="bottom-note">
            <span>
              <span>{allCommonText.note}</span>
              {allCommonText.nodukInfo}
              <span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={allCommonText.urls.home}>
                  {allCommonText.learnMore}
                </a>
              </span>
            </span>
          </div>
        </section>
        <div className="add-payment-method">
          <span className="payment-card-type">
            {selectedCard && (
              <i
                class={`fa-brands fa-cc-${selectedCard.cardType}`}
                style={{color: colors.primary, fontSize: '50px'}}></i>
            )}
            <span className="card-payment-info">
              {selectedCard
                ? `*** *** *** ${selectedCard.last4}`
                : allCommonText.selectPayment}
            </span>
          </span>
          {selectedCard?.isCardSave && (
            <div className="default-card-check">
              <AiFillCheckCircle color={colors.green} size={20} />
              <span>{allCommonText.cardSavedAsDefault}</span>
            </div>
          )}
          <div
            onClick={() => {
              setAddPaymentMethodPopUp(true);
            }}
            className="add-payment-method">
            <span>
              {selectedCard
                ? allCommonText.buttonTexts.change
                : allCommonText.buttonTexts.add}
            </span>
          </div>
        </div>
        <div className="pay-now-container">
          <GenericButton
            id="checkout-pay-now"
            onPress={() => {
              PlaceOrderHandler();
            }}
            disable={selectedCard == null}
            buttonText={allCommonText.buttonTexts.payNow}
            loading={btnLoader}
          />
        </div>
      </div>
      <AddPaymentMethod
        handleClose={() => {
          setAddPaymentMethodPopUp(false);
        }}
        show={addPaymentMethodPopUp}
        existingCards={existingCards}
      />
      <GenericModal
        type={popUpResponse.type}
        show={showModal}
        btnText={allCommonText.ok}
        // title={allCommonText.successfullPayment.title}
        body={
          popUpResponse.type === allCommonText.popupEnums.success ? (
            <div className="payment-success-popup">
              {/* <div> {allCommonText.successfullPayment.paymentReceived}</div> */}
              {allCommonText.successfullPayment.description}
              <a href={`mailto:${allCommonText.urls.info}`}>
                {allCommonText.urls.info}
              </a>
            </div>
          ) : (
            popUpResponse.detail
          )
        }
        handleClose={() => {
          if (popUpResponse.type === allCommonText.popupEnums.success) {
            setShowModal(false);
            navigate({
              pathname: path.plans,
            });
          } else {
            setShowModal(false);
            removeCouponHandler();
          }
        }}
      />
    </div>
  );
}

export default Checkout;
