import React, {useState, useEffect, useContext} from 'react';
import {Grid} from '@mui/material';

import {GrEdit} from 'react-icons/gr';
import {colors, images} from '../../../common';
import {TextInputField} from '../../../components/input-others/index';
import {InputField, Loader, MobileVerificationPopup} from '../../../components';
import {GenericButton} from '../../../components/generic-button/index';
import {Formik} from 'formik';
import {profileScehma} from '../../../common/schemas';
import Autocomplete from 'react-google-autocomplete';
import {
  getProfileCustomer,
  updateCustomerProfile,
} from '../../../utils/rest-services';
import {allCommonText, signupText, header} from '../../../common/constants';
import ApplicationContext from '../../../utils/context-api/context';
import './styles.scss';

export default function Profile() {
  const {setPopUpResponse, setSowModal} = useContext(ApplicationContext);
  const [isloader, setIsloader] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [editMobilePopup, setEditMobilePopup] = useState(false);
  const [userDetailsInfo, setUserDetailsInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    birthDate: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  });

  useEffect(() => {
    getCustomerProfileData();
  }, []);

  const getDate = userObj => {
    const dateArr = userObj.birthDate.split(' ');
    dateArr[0] = dateArr[0].toLowerCase();
    var months = [
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec',
    ];
    dateArr[0] = months.indexOf(dateArr[0]) + 1;
    function getMonthNumber(n) {
      return (n < 10 ? '0' : '') + n;
    }
    const date = `${dateArr[2]}-${getMonthNumber(dateArr[0])}-${dateArr[1]}`;
    userObj.birthDate = date;
    return userObj;
  };
  function ValidatePhoneNumber(event) {
    return (event = event.replace(/^(\+1)/, '').replace(/^(1)/, ''));
  }
  const getCustomerProfileData = async () => {
    setIsloader(true);
    let response = await getProfileCustomer();
    const {content} = response || {};
    if (response && response.success) {
      getDate(content);
      const tempPhone = ValidatePhoneNumber(content.phone);
      content.phone = tempPhone;
      setUserDetailsInfo(content);
      setProfileImage(content.profileURL);
    }
    setIsloader(false);
  };

  // Edit Api
  const gererateFormData = (
    FirstName,
    LastName,
    BirthDate,
    Address,
    City,
    State,
    Zip,
    Phone,
    Email,
    Profile,
  ) => {
    let formData = new FormData();
    formData.append('FirstName', FirstName); //Hint: For the time Being its static
    formData.append('LastName', LastName); //Hint: For the time Being its static
    formData.append('BirthDate', BirthDate);
    formData.append('Address', Address);
    formData.append('City', City);
    formData.append('State', State);
    formData.append('Zip', Zip);
    formData.append('Phone', Phone);
    formData.append('Email', Email);
    formData.append('Profile', Profile);

    return formData;
  };
  const performUpdateUserProfile = async (value, formik) => {
    const customerDetails = gererateFormData(
      value.firstName,
      value.lastName,
      value.birthDate,
      value.address,
      value.city,
      value.state,
      value.zip,
      value.phone,
      value.email,
      profileImage,
    );
    let response = await updateCustomerProfile(customerDetails);
    formik.setSubmitting(false);
    if (response && response.success) {
      setPopUpResponse({
        type: allCommonText.popupEnums.success,
        title: '',
        details: response.description,
      });
      setSowModal(true);
    } else {
      setPopUpResponse({
        type: allCommonText.popupEnums.error,
        title: '',
        details: response.description,
      });
      setSowModal(true);
    }
  };
  const convertArrayToString = data => {
    const joined = data.join();
    let newAddress = joined.replace(/,/g, ' ');
    return newAddress;
  };
  const AddressSeprateHandler = (data, setFieldValue, values) => {
    let address = [];
    let city = [];
    let state = [];
    let postal = [];
    data.map(dataItem => {
      dataItem.types.map(type => {
        if (type === signupText.fieldTypeEnums.streetNumber) {
          address.push(dataItem.long_name);
        } else if (type === signupText.fieldTypeEnums.route) {
          address.push(dataItem.long_name);
        } else if (type === signupText.fieldTypeEnums.naibhour) {
          address.push(dataItem.long_name);
        } else if (type === signupText.fieldTypeEnums.locality) {
          city.push(dataItem.long_name);
        } else if (type === signupText.fieldTypeEnums.admin) {
          state.push(dataItem.long_name);
        } else if (type === signupText.fieldTypeEnums.code) {
          postal.push(dataItem.long_name);
        }
      });
    });
    //convert Address array to string
    setFieldValue(allCommonText.fieldsValue.address, values);
    setFieldValue(allCommonText.fieldsValue.city, convertArrayToString(city));
    setFieldValue(allCommonText.fieldsValue.state, convertArrayToString(state));
    setFieldValue(allCommonText.fieldsValue.zip, convertArrayToString(postal));
    // setUserDetailsInfo(data => ({
    //   ...data,
    //   address: values,
    //   city: convertArrayToString(city),
    //   state: convertArrayToString(state),
    //   zip: convertArrayToString(postal),
    // }));
  };
  const onImageChange = event => {
    return URL.createObjectURL(event);
  };

  const profileImageCheck = () => {
    if (profileImage == null || profileImage == '') {
      return `url(${images.profileDummyImage})`;
    } else if (typeof profileImage === 'string') {
      return `url(${profileImage})`;
    } else {
      return `url(${onImageChange(profileImage)})`;
    }
  };

  return isloader ? (
    <div className="loder-margin">
      <Loader size={50} />
    </div>
  ) : (
    <div className="profile-info">
      <div className="profile-box">
        <Formik
          initialValues={{
            firstName: userDetailsInfo.firstName || '',
            lastName: userDetailsInfo.lastName || '',
            email: userDetailsInfo.email || '',
            birthDate: '',
            phone: userDetailsInfo.phone || '',
            address: userDetailsInfo.address || '',
            city: userDetailsInfo.city || '',
            state: userDetailsInfo.state || '',
            zip: userDetailsInfo.zip || '',
          }}
          validateOnMount={true}
          onSubmit={(values, formik) => {
            performUpdateUserProfile(values, formik);
          }}
          validationSchema={profileScehma}
          enableReinitialize>
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            errors,
            values,
            isSubmitting,
            setFieldValue,
          }) => (
            <>
              <div className="profile-header">
                <h3>{header.profile}</h3>
                <div
                  className="profile-image-container"
                  style={{
                    backgroundImage: profileImageCheck(),
                  }}>
                  <div className="edit-input">
                    <img src={images.uploadNewImage} alt="Upload Image" />

                    <input
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onClick={e => (e.target.value = null)}
                      onChange={event => {
                        setProfileImage(event.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="profile-form-container mt-4">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <label htmlFor="/">
                      {allCommonText.placeholders.firstName}
                    </label>
                    <TextInputField
                      name={'firstName'}
                      id={'firstName'}
                      error={touched.firstName && errors.firstName}
                      placeHolder={allCommonText.placeholders.firstName}
                      value={values.firstName}
                      setValue={handleChange}
                      handleBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <label htmlFor="/">
                      {allCommonText.placeholders.lastName}
                    </label>
                    <TextInputField
                      name={'lastName'}
                      id={'lastName'}
                      error={touched.lastName && errors.lastName}
                      placeHolder={allCommonText.placeholders.lastName}
                      value={values.lastName}
                      setValue={handleChange}
                      handleBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label htmlFor="/">
                      {allCommonText.placeholders.email}
                    </label>
                    <span className="profileEmail">
                      <TextInputField
                        name={'email'}
                        id={'email'}
                        placeHolder={allCommonText.placeholders.email}
                        value={values.email}
                        setValue={handleChange}
                        handleBlur={handleBlur}
                        disable={true}
                        error={touched.email && errors.email}
                      />
                    </span>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="phone-edit-container">
                      <label htmlFor="/">
                        {allCommonText.placeholders.phone}
                      </label>
                      <div
                        onClick={() => {
                          setEditMobilePopup(true);
                        }}
                        className="edit-icon-profile">
                        <GrEdit color={colors.primary} size={20} />
                      </div>
                    </div>

                    <div className="profile-phone">
                      <InputField
                        name={'phone'}
                        id={'phone'}
                        error={errors.phone}
                        touched={touched.phone}
                        handleChange={e => {
                          const inputValue = e.target.value;
                          const numericValue = inputValue.replace(
                            /[^0-9+]/g,
                            '',
                          ); // remove non-numeric characters
                          const restrictedValue = numericValue.substring(0, 12); // restrict to 10 digits
                          setFieldValue('phone', restrictedValue);
                        }}
                        handleBlur={e => {
                          handleBlur('phone');
                          const value = ValidatePhoneNumber(e.target.value);
                          setFieldValue('phone', value);
                        }}
                        value={values.phone}
                        placeholder={allCommonText.placeholders.phone}
                        phone
                        disabled={true}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <label htmlFor="/">
                      {allCommonText.placeholders.address}
                    </label>
                    <Autocomplete
                      className="input-field-container p-2 drop-down-height"
                      apiKey={signupText.ADDRESSKEY}
                      // apiKey={process.env.REACT_APP_ADDRESSKEY}
                      defaultValue={values.address}
                      onChange={handleChange('address')}
                      onBlur={handleBlur}
                      options={{
                        componentRestrictions: {country: 'us'},
                      }}
                      onPlaceSelected={data => {
                        AddressSeprateHandler(
                          data.address_components,
                          setFieldValue,
                          data.formatted_address,
                        );
                      }}
                    />
                    <div className="error-input-container">
                      {errors.address && touched.address ? (
                        <p className="form-error">{errors.address}</p>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <label htmlFor="/">{allCommonText.placeholders.city}</label>
                    <TextInputField
                      id={'city'}
                      name={'city'}
                      error={touched.city && errors.city}
                      value={values.city}
                      setValue={handleChange}
                      handleBlur={handleBlur}
                      placeHolder={allCommonText.placeholders.city}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <label htmlFor="/">
                      {allCommonText.placeholders.state}
                    </label>
                    <TextInputField
                      id={'state'}
                      name={'state'}
                      error={touched.state && errors.state}
                      value={values.state}
                      setValue={handleChange}
                      handleBlur={handleBlur}
                      placeHolder={allCommonText.placeholders.state}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <label htmlFor="/">
                      {allCommonText.placeholders.zipCode}
                    </label>
                    <TextInputField
                      id={'zip'}
                      name={'zip'}
                      error={touched.zip && errors.zip}
                      value={values.zip}
                      setValue={handleChange}
                      handleBlur={handleBlur}
                      placeHolder={allCommonText.placeholders.zipCode}
                    />
                  </Grid>
                </Grid>
                <div className="profile-save-button">
                  <GenericButton
                    id="save-profile"
                    buttonText={allCommonText.profile.save}
                    loading={isSubmitting}
                    onPress={() => {
                      handleSubmit();
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
        {editMobilePopup && (
          <MobileVerificationPopup
            number={userDetailsInfo.phone}
            profileScreen
            show={editMobilePopup}
            handleClose={isVerified => {
              if (isVerified == true) {
                getCustomerProfileData();
              }
              setEditMobilePopup(false);
            }}
          />
        )}
      </div>
    </div>
  );
}
