import ErrorPage from '../pages/error-screen';
import UserProfileSections from '../pages/user-profile-section';
import {
  ForgotPassword,
  Login,
  Signup,
  Plans,
  Checkout,
  Profile,
  PolicyCliams,
  PlanClaimDetails,
  InvoicesPayments,
  InvoicePaymentDetail,
  Subscriptions,
  SubscriptionDetails,
  Refund,
  RefundDetail,
  BillingCards,
  AddNewPolicyScreen,
  ChangePassword,
  EmailConfirmation,
  ResetPassword,
  Dashboard,
  Notification,
  FeedBack,
  DeleteAccount,
  UpgradeSubscription,
} from './../pages';

export const path = {
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  signup: '/signup',
  plans: '/plans',
  planClaimDetails: '/planClaimDetails',
  checkout: '/checkout',
  profile: `/profile`,
  policyAndClaims: `/plans-and-claims`,
  invoicesAndPayments: `/invoices-and-payments`,
  invoicePaymentDetail: `/invoices-and-payments-details`,
  subscriptions: `/subscriptions`,
  subscriptionDetails: `/subscription-details`,
  refund: `/refund`,
  refundDetail: `/refund-detail`,
  billingCards: `/billingCards`,
  changePassword: `/change-password`,
  addNewPolicyScreen: `/add-new-policy`,
  emailConfirmation: `/email-confirmation`,
  errorPage: '/error',
  dashboard: '/dashboard',
  notifications: '/notifications',
  feedBack: '/feedBack',
  deleteAccount: `/deleteAccount`,
  upgradeSubscription: `/upgrade-subscription`,
};
export const routes = {
  login: 'login',
  signup: 'signup',
  forgotPassword: 'forgotPassword',
  plans: 'plans',
  planClaimDetails: 'planClaimDetails',
  checkout: 'checkout',
  profile: 'profile',
  policyAndClaims: `policyAndClaims`,
  invoicesAndPayments: `invoicesAndPayments`,
  invoicePaymentDetail: `invoicesAndPaymentsDetails`,
  subscriptions: `subscriptions`,
  subscriptionDetails: `subscriptionDetails`,
  refund: `refund`,
  refundDetail: `refundDetail`,
  billingCards: `billingCards`,
  addNewPolicyScreen: `addNewPolicyScreen`,
  changePassword: `changePassword`,
  emailConfirmation: `emailConfirmation`,
  resetPassword: 'ResetPassword',
  errorPage: 'error',
  dashboard: 'dashboard',
  notifications: 'notifications',
  feedBack: 'feedBack',
  deleteAccount: 'deleteAccount',
  upgradeSubscription: `upgrade-subscription`,
};

export const pagesRoutes = {
  [routes.plans]: {
    name: routes.plans,
    path: path.plans,
    component: Plans,
  },
  [routes.checkout]: {
    name: routes.checkout,
    path: path.checkout,
    component: Checkout,
  },
  [routes.dashboard]: {
    name: routes.dashboard,
    path: path.dashboard,
    component: UserProfileSections,
    subPage: Dashboard,
    selectedId: 0,
  },

  [routes.policyAndClaims]: {
    name: routes.policyAndClaims,
    path: path.policyAndClaims,
    component: UserProfileSections,
    subPage: PolicyCliams,
    selectedId: 1,
  },
  [routes.planClaimDetails]: {
    name: routes.planClaimDetails,
    path: path.planClaimDetails,
    component: UserProfileSections,
    subPage: PlanClaimDetails,
    selectedId: 1,
  },
  [routes.invoicesAndPayments]: {
    name: routes.invoicesAndPayments,
    path: path.invoicesAndPayments,
    component: UserProfileSections,
    subPage: InvoicesPayments,
    selectedId: 2,
  },
  [routes.invoicePaymentDetail]: {
    name: routes.invoicePaymentDetail,
    path: path.invoicePaymentDetail,
    component: UserProfileSections,
    subPage: InvoicePaymentDetail,
    selectedId: 2,
  },
  [routes.subscriptions]: {
    name: routes.subscriptions,
    path: path.subscriptions,
    component: UserProfileSections,
    subPage: Subscriptions,
    selectedId: 3,
  },
  [routes.refund]: {
    name: routes.refund,
    path: path.refund,
    component: UserProfileSections,
    subPage: Refund,
    selectedId: 4,
  },
  [routes.refundDetail]: {
    name: routes.refundDetail,
    path: path.refundDetail,
    component: UserProfileSections,
    subPage: RefundDetail,
    selectedId: 4,
  },
  [routes.billingCards]: {
    name: routes.billingCards,
    path: path.billingCards,
    component: UserProfileSections,
    subPage: BillingCards,
    selectedId: 5,
  },
  [routes.addNewPolicyScreen]: {
    name: routes.addNewPolicyScreen,
    path: path.addNewPolicyScreen,
    component: AddNewPolicyScreen,
  },
  [routes.profile]: {
    name: routes.profile,
    path: path.profile,
    component: UserProfileSections,
    subPage: Profile,
    selectedId: 6,
  },
  [routes.changePassword]: {
    name: routes.changePassword,
    path: path.changePassword,
    component: UserProfileSections,
    subPage: ChangePassword,
    selectedId: 8,
  },
  [routes.notifications]: {
    name: routes.notifications,
    path: path.notifications,
    component: UserProfileSections,
    subPage: Notification,
    selectedId: 7,
  },
  [routes.feedBack]: {
    name: routes.feedBack,
    path: path.feedBack,
    component: UserProfileSections,
    subPage: FeedBack,
    selectedId: 9,
  },
  [routes.deleteAccount]: {
    name: routes.deleteAccount,
    path: path.deleteAccount,
    component: UserProfileSections,
    subPage: DeleteAccount,
    selectedId: 10,
  },
  [routes.subscriptionDetails]: {
    name: routes.subscriptionDetails,
    path: path.subscriptionDetails,
    component: UserProfileSections,
    subPage: SubscriptionDetails,
    selectedId: 3,
  },
  [routes.upgradeSubscription]: {
    name: routes.upgradeSubscription,
    path: path.upgradeSubscription,
    component: UserProfileSections,
    subPage: UpgradeSubscription,
    selectedId: 3,
  },
};

export const publicPageRoutes = {
  [routes.login]: {
    name: routes.login,
    path: path.login,
    component: Login,
  },
  [routes.signup]: {
    name: routes.signup,
    path: path.signup,
    component: Signup,
  },
  [routes.forgotPassword]: {
    name: routes.forgotPassword,
    path: path.forgotPassword,
    component: ForgotPassword,
  },
  [routes.emailConfirmation]: {
    name: routes.emailConfirmation,
    path: path.emailConfirmation,
    component: EmailConfirmation,
  },
  [routes.resetPassword]: {
    name: routes.resetPassword,
    path: path.resetPassword,
    component: ResetPassword,
  },
};
export const errorPageRoute = {
  [routes.errorPage]: {
    name: routes.errorPage,
    path: path.errorPage,
    component: ErrorPage,
  },
};

export const privatePaths = [
  //All Private Routes Should be place here
  path.dashboard,
];
export const pagewithoutfouter = [
  path.plans,
  path.checkout,
  path.dashboard,
  path.policyAndClaims,
  path.planClaimDetails,
  path.invoicesAndPayments,
  path.subscriptions,
  path.refund,
  path.refundDetail,
  path.billingCards,
  path.addNewPolicyScreen,
  path.profile,
  path.changePassword,
  path.notifications,
  path.feedBack,
  path.deleteAccount,
  path.subscriptionDetails,
  path.upgradeSubscription,
];

export const publicPaths = [path.login, path.signup, path.forgotPassword];
export const userProfileSectionsPaths = [path.profile, path.policyAndClaims]; // id should start from 0
export const pagesWithoutHeader = [
  path.login,
  path.signup,
  path.forgotPassword,
  path.resetPassword,
  path.checkout,
];
export const pagesWithoutAccountButton = [
  path.login,
  path.signup,
  path.forgotPassword,
  path.resetPassword,
];
