import React, {useState, useContext, useEffect} from 'react';
import {FiTrash} from 'react-icons/fi';
import {FiEdit2} from 'react-icons/fi';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import {GoX} from 'react-icons/go';
import {images} from '../../common/images';
import {allCommonText, cart} from '../../common/constants';
import SlidingPane from 'react-sliding-pane';
import './styles.scss';
import {GenericButton} from './../generic-button/index';
import {GenericModal} from '../generic-modal';
import {useNavigate} from 'react-router';
import {
  getCartItemList,
  getCartCount,
  removeCartItemById,
  removeAllCartItems,
} from '../../utils/rest-services';
import {Loader} from '../loader';
import {path} from '../../common/routesNames';
import ApplicationContext from '../../utils/context-api/context';
import {EditCartPopup} from '../cart-edit-popup';

const CartItemDetails = ({
  carrier,
  maker,
  model,
  year,
  price,
  address,
  brand,
  onTrashPress,
  onEditPress,
  // isDeleteLoader,
}) => {
  const [isDeleteLoader, setIsDeleteLoader] = useState(false);
  return (
    <>
      <div className="cart-item-container">
        <div className="cart-items">
          <div className="cart-item-details">
            {carrier && (
              <div>
                <span>{carrier}</span>
              </div>
            )}
            {maker && (
              <div>
                <span>{maker}</span>
              </div>
            )}
            {model && (
              <div>
                <span>{model}</span>
              </div>
            )}
            {brand && (
              <div>
                <span>{brand}</span>
              </div>
            )}
            {year && (
              <div>
                <span>{year}</span>
              </div>
            )}
            {address && (
              <div>
                <span>{address}</span>
              </div>
            )}
          </div>
          <div className="cart-item-prices">
            {price && (
              <div>
                <span>{`$${price.toFixed(2)}`}</span>
              </div>
            )}
          </div>
        </div>
        <div className="cart-action-container">
          <div
            className="cart-action"
            onClick={() => onTrashPress(setIsDeleteLoader)}>
            <span>{isDeleteLoader ? <Loader size={15} /> : <FiTrash />}</span>
          </div>
          <div className="cart-action" onClick={onEditPress}>
            <span>
              <FiEdit2 />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const CartItem = ({
  selectedPLanId,
  cartItem,
  onTrashPress,
  onEditPress,
  isDeleteLoader,
}) => {
  let totalItemSum = 0;
  return (
    <>
      <div className="cartContent">
        <div className="planImage">
          <img src={planSelectorIcon(selectedPLanId)} alt="" />
        </div>

        {cartItem.map(item => {
          const {
            cartItemId,
            providerName,
            make,
            model,
            year,
            brand,
            address,
            deductibleAmount,
            cost,
          } = item;
          totalItemSum = totalItemSum + cost;
          return (
            <>
              <CartItemDetails
                carrier={providerName}
                maker={make}
                model={model}
                year={year}
                price={deductibleAmount}
                brand={brand}
                address={address}
                isDeleteLoader={isDeleteLoader}
                onTrashPress={setLoader => {
                  onTrashPress(cartItemId, setLoader);
                }}
                onEditPress={() => {
                  onEditPress(cartItemId);
                }}
              />
            </>
          );
        })}
        <EachCostDetail
          selectedPLanId={selectedPLanId}
          totalCost={totalItemSum}
        />
      </div>
    </>
  );
};

const EachCostDetail = ({totalCost, selectedPLanId}) => {
  return (
    <div className="costContainer">
      <span>{cart.cost}</span>
      {selectedPLanId === 5 && (
        <span className="disaster-icon">Disaster Plan</span>
      )}
      <span>{cart.monthly}</span>
      <span>{`$${parseFloat(totalCost).toFixed(2)}`}</span>
    </div>
  );
};
const planSelectorIcon = id => {
  switch (id) {
    case allCommonText.serviceTypeEnums.vehicle:
      return images.vehicleV;
    case allCommonText.serviceTypeEnums.home:
      return images.homeV;
    case allCommonText.serviceTypeEnums.device:
      return images.devicesV;
    case allCommonText.serviceTypeEnums.commercial:
      return images.commercialV;
    case allCommonText.serviceTypeEnums.disaster:
      return images.diasasterV;
  }
};
export const CartSlider = () => {
  const [cartList, setCartList] = useState([]);
  const [isloader, setIsloader] = useState(false);
  const [emptyCartConfirmation, setEmptyCartConfirmation] = useState(false);
  const [isEditCartPopup, setIsEditCartPopup] = useState(false);
  const [totalSubscriptionCost, setTotalSubscriptionCost] = useState(0);
  const [selectedId, setSelectedId] = useState({
    cartItemId: '',
    serviceTypeId: '',
  });
  const {cartCount, setCartCount, isOpenPane, setIsOpenPane} =
    useContext(ApplicationContext);
  let navigate = useNavigate();
  useEffect(() => {
    if (isOpenPane.isPaneOpen === true) {
      fetchCartList();
    }
  }, [isOpenPane]);

  const fetchCartCount = async () => {
    let response = await getCartCount();
    if (response && response.success) {
      const {content} = response;
      setCartCount(content);
    }
  };

  const fetchCartList = async () => {
    setIsloader(true); // Show the loader while fetching cart data
    setCartList([]);
    try {
      let response = await getCartItemList();
      if (response && response.success) {
        const {
          content: {items},
        } = response;
        totalCostCalculated(items);
        setCartList(items?.reverse() || []);
        fetchCartCount();
      } else {
        setCartCount(0);
        setCartList([]);
      }
    } catch (error) {
      console.error('Error fetching cart items:', error);
      setCartCount(0);
      setCartList([]);
    } finally {
      setIsloader(false); // Hide the loader after cart data is fetched or if there was an error
    }
  };

  const totalCostCalculated = cartList => {
    let totalSum = 0;
    let tempCart = [...cartList];
    tempCart.map(items => {
      const {cartItem} = items;
      cartItem.map(cartItem => {
        const {cost} = cartItem || {};
        totalSum = totalSum + cost;
      });
    });
    setTotalSubscriptionCost(totalSum);
  };

  const removeItemCartFromList = id => {
    let tempCartList = [...cartList];
    tempCartList.map((item, index) => {
      let findIndexOf = item.cartItem.findIndex(i => i.cartItemId == id);
      if (findIndexOf !== -1) {
        item.cartItem.splice(findIndexOf, 1);
      }
      if (item.cartItem.length === 0) {
        tempCartList.splice(index, 1);
      }
    });
    totalCostCalculated(tempCartList);
    setCartList(tempCartList);
    fetchCartCount();
  };

  const removeCartItem = async (cartItemId, setLoader) => {
    setLoader(true);
    let response = await removeCartItemById(cartItemId);
    setLoader(false);
    if (response && response.success) {
      removeItemCartFromList(cartItemId);
    }
    // setLoader(false);
  };

  const emptyAllCart = async () => {
    setIsloader(true);
    let response = await removeAllCartItems();
    if (response && response.success) {
      setIsloader(false);
      fetchCartList();
    }
  };

  const onEditPress = (cartItemId, serviceTypeId) => {
    setSelectedId({
      cartItemId: cartItemId,
      serviceTypeId: serviceTypeId,
    });
    setIsEditCartPopup(true);
  };

  const closeCart = () => {
    document.body.style.overflow = 'unset';
    setIsOpenPane({isPaneOpen: false});
  };
  return (
    <>
      <div>
        <div
          className="cartAddedIcon"
          onClick={() => {
            setIsOpenPane({isPaneOpen: true});
            if (typeof window != 'undefined' && window.document) {
              document.body.style.overflow = 'hidden';
            }
            // totalCostCalculated();
          }}>
          <span>
            <img src={images.headerCart} alt={cart.closeIcon} />
          </span>
          {cartCount > 0 && (
            <span className="cart-count-header">
              {cartCount == '' || cartCount == 0 ? `` : `${cartCount}`}
            </span>
          )}
        </div>

        <SlidingPane
          className="some-custom-class"
          overlayClassName="some-custom-overlay-class"
          isOpen={isOpenPane.isPaneOpen}
          onRequestClose={() => {
            closeCart();
          }}>
          {isloader ? (
            <div className="loder-margin">
              <Loader size={50} />
            </div>
          ) : (
            <div className="pane-box">
              <div className="cartCount">
                <h3>{cartCount == '' ? `Cart` : `Cart(${cartCount})`}</h3>
                <button
                  className="closePaneIcon"
                  onClick={() => {
                    closeCart();
                  }}>
                  {<GoX />}
                </button>
              </div>

              {cartList.length === 0 ? (
                <div className="cartEmptyContainer">
                  <img src={images.emptyCartIcon} alt={cart.emptyIcon} />
                  <h4>{cart.cartEmpty}</h4>
                  <p>{cart.emptyCartMessage}</p>
                </div>
              ) : (
                <>
                  {cartList.map(item => {
                    const {serviceTypeId, cartItem, totalCost} = item;

                    return (
                      <>
                        {
                          <CartItem
                            cartItem={cartItem}
                            selectedPLanId={serviceTypeId}
                            totalCost={totalCost}
                            onTrashPress={(id, setLoader) => {
                              removeCartItem(id, setLoader);
                            }}
                            onEditPress={id => {
                              onEditPress(id, serviceTypeId);
                            }}
                          />
                        }
                        <div className="grandTotal">
                          <div className="totalCost">
                            <span>
                              {cart.totalCost} <span>{cart.perMonth}</span>
                            </span>
                            <span>{`$${parseFloat(
                              totalSubscriptionCost,
                            ).toFixed(2)}`}</span>
                          </div>
                          <div className="payActionContainer">
                            <div className="payNowButton">
                              <GenericButton
                                id="pay-now-button"
                                onPress={() => {
                                  closeCart();
                                  navigate({
                                    pathname: path.checkout,
                                  });
                                }}
                                buttonText={cart.payNow}
                              />
                            </div>
                            <div className="emptyCartButton">
                              <GenericButton
                                id="empty-cart-button"
                                onPress={() => {
                                  setEmptyCartConfirmation(true);
                                }}
                                buttonText={
                                  <span>
                                    <span className="payTrashIcon">
                                      <FiTrash />
                                    </span>
                                    <span className="payEmptyCart">
                                      {cart.emptyCart}
                                    </span>
                                  </span>
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              )}

              <GenericModal
                show={emptyCartConfirmation}
                title={allCommonText.emptyCartTitle}
                type={allCommonText.popupEnums.delete}
                body={allCommonText.confirmataionMessage}
                btnText={allCommonText.no}
                grayButton={true}
                handleAction={() => {
                  emptyAllCart();
                  setEmptyCartConfirmation(false);
                }}
                button2
                btnText2={allCommonText.yes}
                handleClose={() => {
                  setEmptyCartConfirmation(false);
                }}
              />
              {isEditCartPopup && (
                <EditCartPopup
                  show={isEditCartPopup}
                  btnText={allCommonText.update}
                  selectedId={selectedId}
                  handleClose={isEdited => {
                    setIsEditCartPopup(false);
                    if (isEdited == true) {
                      fetchCartList();
                      fetchCartCount();
                    }
                  }}
                />
              )}
            </div>
          )}
        </SlidingPane>
      </div>
    </>
  );
};
