import React from 'react';
import './styles.scss';

export const TextAreaFeild = ({
  key,
  value,
  setValue,
  handleBlur,
  error,
  placeholder,
  reff,
  ...props
}) => {
  return (
    <>
      <textarea
        rows="4"
        cols="50"
        maxlength="200"
        id={key}
        key={`random-${key}`}
        value={value}
        onChange={setValue}
        onBlur={handleBlur}
        placeholder={placeholder}
        ref={reff}
        {...props}
      />
      {error && (
        <div className="error-input-container">
          {error ? <p className="form-error">{error}</p> : null}
        </div>
      )}
    </>
  );
};
